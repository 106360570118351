export const handleApiError = (error, openSnackbar, snackbarOptions) => {
  if (error.response) {
    // Request made and server responded
    console.log("Error Response", error.response)
    if (error.response.data) {
      if (error.response.data?.code) {
        openSnackbar({
          open: true,
          color: "error",
          message: <div class="fs-6 fw-bold">{"Error " + error.response.data.code + " : " + error.response.data.message}</div>,
          ...snackbarOptions,
        })
      } else if (error.response.data?.errors?.length) {
        openSnackbar({
          open: true,
          color: "error",
          message: error.response.data.errors[0],
          ...snackbarOptions,
        })
      } else {
        openSnackbar({
          open: true,
          color: "error",
          message: error.response.data,
          ...snackbarOptions,
        })
      }
    }
    // else if (error.response) {
    //   openSnackbar({
    //     open: true,
    //     color: "error",
    //     message: "Network Connection Lost",
    //   })
    // }
    else {
      openSnackbar({
        open: true,
        color: "error",
        message: "Error " + error.response.status,
        ...snackbarOptions,
      })
    }
  } else if (error.request) {
    // The request was made but no response was received
    // console.log('Req' + error.request)
    openSnackbar({ open: true, color: "error", message: "Error" })
  } else {
    // Something happened in setting up the request that triggered an Error
    // console.log('Error', error.message)
    openSnackbar({
      open: true,
      color: "error",
      message: error.message ? error.message : "Error",
    })
  }
}
