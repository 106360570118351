import React, { useState, useEffect, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { AuthContext } from '../../libs/contexts/AuthContext'
import { SnackbarContext } from '../../libs/contexts/SnackbarContext'
import { handleApiError } from '../../libs/apis/handleApiError'
import api from '../../libs/apis/api'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import bulkImg from '../../assets/images/bulk-images.png'
import Header from '../../components/Header'
import Switch from '../../components/FormFields/Switch'
import Textfield from '../../components/FormFields/Textfield'

const ChangePassword = () => {
  let navigate = useNavigate()
  let params = useParams()
  const { handleSetAuth,lang } = useContext(AuthContext)
  const { openSnackbar } = useContext(SnackbarContext)
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        email: Yup.string()
          .email('Please enter valid email')
          .required('Required!'),
        oldPassword: Yup.string()
          .min(5, 'Minimum 5 characters required!!')
          .max(50, 'Too Long!')
          .required('Required!'),
        newPassword: Yup.string()
          .min(5, 'Minimum 5 characters required!!')
          .max(50, 'Too Long!')
          .required('Required!')
        // confirmPassword: Yup.string()
        //   .oneOf([Yup.ref('password'), null], 'Passwords must match')
        //   .required('Required!')
      })
    ),
    defaultValues: {
      email: '',
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''
    }
  })

  // const handleSubmit = values => {
  //   console.log(values)
  //   navigate('/dashboard')
  // }

  const handleRequest = async values => {
    try {
      const { data } = await api.post(
        `v1/salvage/reset-password-oldpassword`,
        values
      )

      openSnackbar({
        color: 'success',
        message: 'Password Changed Successfully'
      })
      // navigate('/my-request')
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }
  return (
    <>
      <Header title={lang.change_password} />
      <div class='wrapper'>
        <form onSubmit={handleSubmit(handleRequest)}>
          <section class='create-request-section-one'>
            <p class='sub-title'>
              Change your current password with new password.
            </p>
            <div class='row'>
              <div class='col-md-8'>
                <div class='form-m-b-20'>
                  <Textfield
                    type='text'
                    class='form-control'
                    placeholder='Email'
                    name='email'
                    register={register}
                    errors={errors}
                  />
                </div>
                <div class='form-m-b-20'>
                  <Textfield
                    type='password'
                    class='form-control'
                    placeholder={lang.old_password}
                    name='oldPassword'
                    register={register}
                    errors={errors}
                  />
                </div>
                <div class='form-m-b-20'>
                  <Textfield
                    type='password'
                    class='form-control'
                    placeholder={lang.new_password}
                    name='newPassword'
                    register={register}
                    errors={errors}
                  />
                </div>
                {/* <div class='form-m-b-20'>
                  <Textfield
                    type='password'
                    class='form-control'
                    placeholder='Confirm Password'
                    name='confirmPassword'
                    register={register}
                    errors={errors}
                  />
                </div> */}
                <button type='submit' class='btn btn-01 btn-width-150 mt-30'>
                  Submit
                </button>
              </div>
            </div>
          </section>
        </form>
      </div>
    </>
  )
}

export default ChangePassword
