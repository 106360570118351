import React, { useState, useEffect, useContext, useMemo } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { AuthContext } from "../../libs/contexts/AuthContext"
import { SnackbarContext } from "../../libs/contexts/SnackbarContext"
import { handleApiError } from "../../libs/apis/handleApiError"
import api, { toFormData } from "../../libs/apis/api"
import Header from "../../components/Header"
import MakeModelSelect from "../../components/MakeModelSelect2"
import localforage from "localforage"
// import makeModel from "../../assets/data/ly_makemodelyear.json"

let makeModel = []
localforage
  .getItem("_makemodel")
  .then(function (value) {
    makeModel = value ?? []
  })
  .catch(function (err) {
    // This code runs if there were any errors
    console.log(err)
  })

const FineTune = () => {
  let navigate = useNavigate()
  let params = useParams()
  const { handleSetAuth, lang } = useContext(AuthContext)
  const { openSnackbar } = useContext(SnackbarContext)
  const [selectedMakeModel, setSelectedMakeModel] = useState([])
  const [tab, setTab] = useState("import")
  const [isSkip, setIsSkip] = useState(false)
  const [loading, setLoading] = useState(true)
  const [makeModelOptions, setMakeModelOptions] = useState([])

  const fetchData = async (allSelected, emptySelected) => {
    try {
      const { data } = await api.get(`v1/salvage`)
      let ssm = []
      if (data.isSkip) {
        ssm = allSelected
      } else {
        ssm = emptySelected.map((item) => {
          let ssModel = []
          data.preferredMakeModel?.forEach((pItem) => {
            if (pItem.make == item.make) {
              ssModel = pItem.model
            }
          })
          return { make: item.make, model: ssModel }
        })
      }
      setSelectedMakeModel(ssm)
      setIsSkip(data.isSkip)
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const handleFineTuneSubmit = async (values) => {
    try {
      let pfm = selectedMakeModel.filter((item) => item.model.length > 0)
      const { data } = await api.post(`v1/salvage/updateFineTune`, {
        preferredMakeModelList: isSkip ? [] : pfm,
        isSkip: isSkip,
      })
      openSnackbar({ color: "success", message: "Fine Tune Updated Successfully" })
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const filterMakeModel = (mm) => {
    let selectedMMO = []
    let importMakeOptions = mm
      .filter((item) => item.american == "N")
      .map((item) => item.make)
      .filter((x, i, a) => a.indexOf(x) == i)
      .sort((a, b) => a.localeCompare(b))
    let importMMOptions = []
    importMakeOptions.forEach((item) => {
      importMMOptions.push({
        make: item,
        model: mm
          .filter((moItem) => moItem.make == item)
          .map((moItem) => moItem.model)
          .filter((x, i, a) => a.indexOf(x) == i)
          .sort((a, b) => a.localeCompare(b)),
      })
      selectedMMO.push({ make: item, model: [] })
    })
    let domesticMakeOptions = mm
      .filter((item) => item.american == "Y")
      .map((item) => item.make)
      .filter((x, i, a) => a.indexOf(x) == i)
      .sort((a, b) => a.localeCompare(b))
    let domesticMMOptions = []
    domesticMakeOptions.forEach((item) => {
      domesticMMOptions.push({
        make: item,
        model: mm
          .filter((moItem) => moItem.make == item)
          .map((moItem) => moItem.model)
          .filter((x, i, a) => a.indexOf(x) == i)
          .sort((a, b) => a.localeCompare(b)),
      })
      selectedMMO.push({ make: item, model: [] })
    })

    fetchData([...importMMOptions, ...domesticMMOptions], selectedMMO)
    setSelectedMakeModel(selectedMMO)
    return { import: importMMOptions, domestic: domesticMMOptions, emptyModel: selectedMMO }
  }

  useMemo(() => {
    setLoading(true) // Show loading state
    setTimeout(() => {
      const options = filterMakeModel(makeModel)
      setMakeModelOptions(options)
      setLoading(false) // Hide loading state
    }, 100) // Simulating a 2-second delay
  }, [makeModel])

  if (loading) {
    return (
      <>
        <Header title={lang.fine_tune} />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ height: "2.5em", width: "2.5em", margin: "5em 0 5em 0" }}>
            <div className="spinner-border" style={{ color: "#D41404" }} role="status"></div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <Header title={lang.fine_tune} />
      <div class="wrapper">
        <div class="row">
          <div class="col-md-9 col-lg-6">
            <div class="row">
              <div class="col-md-6">
                <div
                  role="button"
                  onClick={() => {
                    setTab("import")
                  }}
                  className={`btn ${tab == "import" ? "btn-01" : "btn-02"} w-100 pe-auto`}>
                  {lang.import}
                </div>
              </div>
              <div class="col-md-6">
                <div
                  role="button"
                  onClick={() => {
                    setTab("domestic")
                  }}
                  className={`btn ${tab == "domestic" ? "btn-01" : "btn-02"} w-100 pe-auto`}>
                  {lang.domestic}
                </div>
              </div>
              <div class="text-center mt-2 mb-3 fs-6">
                <span class="red-font">*</span> {lang.choose_makes_models_live_feed}
              </div>
              <div class="text-center mb-4">
                <span>
                  <input
                    checked={isSkip}
                    onChange={() => {
                      if (!isSkip) {
                        setIsSkip(true)
                        setSelectedMakeModel([...makeModelOptions.import, ...makeModelOptions.domestic])
                      } else {
                        setIsSkip(false)
                        setSelectedMakeModel(makeModelOptions.emptyModel)
                      }
                    }}
                    type="checkbox"
                    class="form-check-input mt-1 fs-6"
                  />
                  <label>
                    <div class="h5 px-3">
                      <b>{lang.select_all}</b>
                    </div>
                  </label>
                </span>
              </div>
              <div className="shadow-sm" style={{ height: "60vh", overflowY: "scroll" }}>
                <MakeModelSelect
                  makeModelOptions={makeModelOptions[tab]}
                  selectedMM={selectedMakeModel}
                  setSelectedMM={(value) => {
                    setSelectedMakeModel(value)
                    setIsSkip(false)
                  }}
                />
              </div>
              <div class="d-grid mt-3">
                <button class="btn btn-01" onClick={handleFineTuneSubmit}>
                  {lang.save}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FineTune
