import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { AuthContext } from "../../libs/contexts/AuthContext";
import { SnackbarContext } from "../../libs/contexts/SnackbarContext";
import { handleApiError } from "../../libs/apis/handleApiError";
import api from "../../libs/apis/api";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import bulkImg from "../../assets/images/bulk-images.png";
import Header from "../../components/Header";
import Switch from "../../components/FormFields/Switch";
import Textfield from "../../components/FormFields/Textfield";
import ChartsEmbedSDK, {
  getRealmUserToken,
} from "@mongodb-js/charts-embed-dom";

// Needed only if you are using the Atlas App Services authentication provider
// const client = Stitch.initializeAppClient(
//   { Appid: "application-0-cckygik" } // ~REPLACE~ with your App ID
// );

// const sdk = new ChartsEmbedSDK({
//   baseUrl: "https://charts.mongodb.com/charts-project-0-lkkpzwl", // ~REPLACE~ with the Base URL from your Embed Dashboard dialog.
//   // getUserToken:() => {}
//   // getUserToken: () => getRealmUserToken(client),
//   // getUserToken is a callback to provide the auth token to the SDK.
//   // Use the getRealmUserToken helper when using the Atlas App Services auth provider.
//   // If using Custom JWT or Google providers, provide your own code to retrieve the JWT
// });

// const dashboard = sdk.createDashboard({
//   dashboardId: "735cfa75-15b8-483a-bc2e-7c6659511c7c", // ~REPLACE~ with the Dashboard ID from your Embed Dashboard dialog.
//   height: "700px",
//   widthMode: "scale",
//   heightMode: "fixed",
//   // Additional options go here
// });
// const chart = sdk.createDashboard({
//   // chartId: "48043c78-f1d9-42ab-a2e1-f2d3c088f864"
//   dashboardId: "6661932a-3489-4caf-8b7b-90bf11720a2d",
// });

const Report = () => {
  let navigate = useNavigate();
  let params = useParams();
  const { auth, handleSetAuth, lang } = useContext(AuthContext);
  const { openSnackbar } = useContext(SnackbarContext);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      feedback: "",
    },
  });

  const jwtToken = () => {
    return auth.authToken.replace("Bearer ", "");
  };

  const sdk = new ChartsEmbedSDK({
    baseUrl: process.env.REACT_APP_CHART_URL, // ~REPLACE~ with the Base URL from your Embed Dashboard dialog.
    getUserToken: () => jwtToken(),
    // getUserToken is a callback to provide the auth token to the SDK.
    // Use the getRealmUserToken helper when using the Atlas App Services auth provider.
    // If using Custom JWT or Google providers, provide your own code to retrieve the JWT
  });
  const chart = sdk.createDashboard({
    dashboardId: process.env.REACT_APP_DASHBOARD_ID,
    height: "80vh",
    widthMode: "scale",
    heightMode: "fixed",
  });

  const fetchData = async (values) => {
    try {
      const { data } = await api.get(`v1/salvage`);
      reset({ feedback: data.feedback });
    } catch (error) {
      handleApiError(error, openSnackbar);
    }
  };

  const handleFeedback = async (values) => {
    try {
      const { data } = await api.put(`v1/salvage/feedback`, values);
      openSnackbar({ color: "success", message: "Updated Successfully" });
      // navigate('/my-request')
    } catch (error) {
      handleApiError(error, openSnackbar);
    }
  };

  useEffect(() => {
    // fetchData();
    // dashboard.render(document.getElementById("dashboard-chart"));
    chart.render(document.getElementById("dashboard-chart"));
  }, []);

  return (
    <>
      <Header title={lang.reports} />
      <div class="wrapper">
        <form onSubmit={handleSubmit(handleFeedback)}>
          <section class="create-request-section-one">
            <div class="row">
              <div class="col-md-12">
                <div class="form-m-b-20">
                  {/* <label class="form-label mt-10 mb-4 fs-4 color-secondary">
                    {lang.coming_soon}...
                  </label> */}
                  <div id="dashboard-chart"></div>
                  {/* <textarea
                    class="form-control"
                    rows="10"
                    // cols='10'
                    placeholder={lang.type_feedback_here}
                    {...register("feedback")}
                    style={{ height: "7em" }}></textarea> */}
                </div>
                {/* <button type="submit" class="btn btn-01 btn-width-150 mt-30">
                  {lang.submit}
                </button> */}
              </div>
            </div>
          </section>
        </form>
      </div>
    </>
  );
};

export default Report;
