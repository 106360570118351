import React, { useContext } from "react"
import Tooltip from "./Tooltip"
import { AuthContext } from "../libs/contexts/AuthContext"

const InfoAction = (props) => {
  const { lang } = useContext(AuthContext)
  const { type, handleClick, flagColor, badge = true } = props

  if (type == "info") {
    return (
      <>
        <Tooltip
          text={lang.info_needed}
          data={
            <div class="px-3 pe-auto" role="button" onClick={handleClick}>
              <i class="fas fa-info "></i>
            </div>
          }
        />
      </>
    )
  } else if (type == "info-requested") {
    return (
      <>
        <Tooltip
          text={lang.info_requested}
          // class="bg-danger"
          style={{ backgroundColor: flagColor ?? "#d41404" }}
          data={
            <div
              class="rounded-circle bg-black mx-2 text-bg-light position-relative text-wrap"
              style={{ width: "1.5em", height: "1.5em", cursor: "pointer" }}
              onClick={handleClick}>
              <i class="fas fa-info fa-sm  position-absolute translate-middle top-50 start-50 text-white"> </i>
              {badge && (
                <span
                  class="position-absolute top-0 translate-middle p-1 rounded-circle"
                  style={{
                    left: "85%",
                    backgroundColor: flagColor ?? "#d41404",
                    borderColor: flagColor ?? "#d41404",
                    width: "13px",
                    height: "13px",
                  }}>
                  <span class="visually-hidden"></span>
                </span>
              )}
            </div>
          }
        />
      </>
    )
  } else if (type == "info-received") {
    return (
      <>
        <Tooltip
          text={lang.info_received}
          // class="bg-danger"
          style={{ backgroundColor: flagColor ?? "#d41404" }}
          data={
            <div
              class="rounded-circle bg-black mx-2 text-bg-light position-relative text-wrap"
              style={{ width: "1.5em", height: "1.5em", cursor: "pointer" }}
              onClick={handleClick}>
              <i class="fas fa-info fa-sm  position-absolute translate-middle top-50 start-50 text-white"></i>
              <span
                class="position-absolute top-0 translate-middle p-1 rounded-circle"
                style={{ left: "85%", backgroundColor: flagColor ?? "#d41404", borderColor: flagColor ?? "#d41404", width: "13px", height: "13px" }}>
                <span class="visually-hidden"></span>
              </span>
            </div>
          }
        />
      </>
    )
  } else {
    return <></>
  }
}

export default InfoAction
