import { initializeApp, deleteApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import { getMessaging, getToken, deleteToken, onMessage } from "firebase/messaging"
import { getMessaging as getMessagingSW } from "firebase/messaging/sw"
import { onBackgroundMessage } from "firebase/messaging/sw"

// Your web app’s Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDI-Pzl68zsJ1DtOulxEsQZ9OL51M55uS8",
  authDomain: "liveyards-dc965.firebaseapp.com",
  databaseURL: "https://liveyards-dc965.firebaseio.com",
  projectId: "liveyards-dc965",
  storageBucket: "liveyards-dc965.appspot.com",
  messagingSenderId: "385766079100",
  appId: "1:385766079100:web:08bec4511716ddd88167f9",
  measurementId: "G-EHDDQ89D84",
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig)
//   const analytics = getAnalytics(app);
export const messaging = getMessaging(app)

export async function requestPermission() {
  console.log("Requesting permission...")
  const permission = await Notification.requestPermission()
  if (permission === "granted") {
    const token = await getToken(messaging, { vapidKey: "BKfIEH5N-5VvPPa5vzBLbY8CtJFDVlSK5ZBVlgVCywhZWj66bOJCW5LlxeHwtSQLUxwrVgq7N4tZcKBOo4dOPd4" })
    return token
  } else if (permission === "denied") {
    // alert('You denied Notification')
  }
}

export async function handleLogoutNotification() {
  await deleteToken(messaging)
    .then(() => {
      console.log("Token deleted.")
      // ...
      deleteApp(app)
        .then(() => {
          // ...
        })
        .catch(function (error) {
          console.error("Error deleting FCM app:", error)
        })
    })
    .catch((err) => {
      console.log("Unable to delete token. ", err)
    })
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload)
    })
  })
