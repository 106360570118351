import React, { useState, useEffect, useContext } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { AuthContext } from "../../libs/contexts/AuthContext"
import { SnackbarContext } from "../../libs/contexts/SnackbarContext"
import api from "../../libs/apis/api"
import * as Yup from "yup"
import { handleApiError } from "../../libs/apis/handleApiError"
import { yupResolver } from "@hookform/resolvers/yup"
import bulkImg from "../../assets/images/bulk-images.png"
import { useForm } from "react-hook-form"
import Header from "../../components/Header"
import Textfield from "../../components/FormFields/Textfield"
import Tooltip from "../../components/Tooltip"
import DeleteDialog from "../../components/DeleteDialog"

const validationSchema = Yup.object().shape({
  firstname: Yup.string().required("Required*"),
  lastname: Yup.string().required("Required*"),
  emailaddress: Yup.string().email("Please enter valid email").required("Email is Required!").required("Required"),
  // mobile: Yup.number().typeError("Invalid Number").integer("Must be 10 digits"),
  mobile: Yup.string()
    .matches(/^\d{10}$|^$/, "Mobile number must be exactly 10 digits")
    .nullable(),
})

const ManageUser = () => {
  let navigate = useNavigate()
  let params = useParams()
  const { auth, lang } = useContext(AuthContext)
  const { openSnackbar } = useContext(SnackbarContext)
  const [users, setUsers] = useState([])
  const [userDialog, setUserDialog] = useState({ open: false })
  const [deleteDialog, setDeleteDialog] = useState({ open: false, message: "" })
  const userForm = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      firstname: "",
      lastname: "",
      emailaddress: "",
      mobile: null,
      type: "add",
    },
  })
  const fetchData = async (values) => {
    try {
      const { data } = await api.get(`v1/userflow/getUsers`)
      setUsers(data)
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleUserFormSubmit = async (values) => {
    try {
      if (values.type == "add") {
        const { data } = await api.post(`/v1/userflow/createUser`, { ...values })
        openSnackbar({ message: "User Added Successfully", color: "success" })
      } else {
        const { data } = await api.put(`/v1/userflow/updateUser`, { ...values, userId: values.id })
        openSnackbar({ message: "User Updated Successfully", color: "success" })
      }
      fetchData()
      setUserDialog((prev) => ({ ...prev, open: false }))
      userForm.reset()
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }
  const handleDelete = async (id) => {
    try {
      const { data } = await api.delete(`/v1/userflow/${id}`)
      setDeleteDialog({ ...deleteDialog, open: false })
      fetchData()
      openSnackbar({ color: "success", message: "Deleted Successfully" })
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }
  return (
    <>
      <DeleteDialog deleteDialog={deleteDialog} />
      <Header title={lang.manage_users} />
      <div class="wrapper">
        <div class="row">
          <div class="col-md-12">
            <div class="table-main-div">
              <h4 className="text-dark px-5 mb-4">{lang.users_list}</h4>
              <ul class="custome-table-head">
                <li class="h6">{lang.name}</li>
                <li class="h6">{lang.email}</li>
                <li class="h6">{lang.mobile_number}</li>
                <li></li>
              </ul>
              {!users?.length && (
                <div className="custome-tr-main" style={{ justifyContent: "center", padding: "2em 6em 2em 0", color: "gray", fontWeight: "bold" }}>
                  No Users{" "}
                </div>
              )}
              {users?.map((item) => (
                <div class="custome-tr-main">
                  <div class="td-1">{item.firstname + " " + item.lastname}</div>
                  <div class="td-2">{item.emailaddress}</div>
                  <div class="td-3">{item.mobile}</div>
                  <div class="td-4"></div>
                  <div class="td-5"></div>
                  <div class="td-6">
                    <Tooltip
                      text={lang.edit}
                      data={
                        <i
                          class="fas fa-edit mx-2"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            userForm.reset({ ...item, type: "edit" })
                            setUserDialog((prev) => ({ ...prev, open: true }))
                          }}></i>
                      }
                    />
                    <Tooltip
                      text={lang.delete}
                      data={
                        <i
                          class="fas fa-trash mx-2"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            setDeleteDialog({
                              open: true,
                              message: (
                                <>
                                  {lang.delete_user}{" "}
                                  <b>
                                    {item.firstname} {item.lastname}
                                  </b>
                                  ?
                                </>
                              ),
                              handleConfirm: () => handleDelete(item.id),
                              handleCancel: () => setDeleteDialog({ ...deleteDialog, open: false }),
                            })
                          }></i>
                      }
                    />
                  </div>
                </div>
              ))}
            </div>
            <button
              type="submit"
              className="btn btn-01 mt-4 ms-4 pe-4"
              onClick={() => {
                if (users.length >= 2) {
                  openSnackbar({ color: "error", message: lang.max_user_add })
                } else {
                  userForm.reset({ type: "add" })
                  setUserDialog((prev) => ({ ...prev, open: true }))
                }
              }}>
              <i class="fas fa-plus ms-2 me-3"></i>
              {lang.add_new_user}
            </button>
          </div>
        </div>
      </div>
      {userDialog.open && (
        <div class="modal" role="dialog" style={{ display: "block" }}>
          <div
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              backgroundColor: "#000",
              opacity: "0.5",
            }}></div>
          <div class="modal-dialog modal-md modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title text-dark" id="exampleModalLongTitle">
                  {userForm.getValues("type") == "add" ? lang.add_new_user : lang.edit_user}
                </h4>
                <button type="button" class="close" style={{ border: "0" }} onClick={() => setUserDialog({ ...userDialog, open: false })}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form onSubmit={userForm.handleSubmit(handleUserFormSubmit)}>
                <div class="modal-body">
                  <div class="form-m-b-20">
                    <Textfield
                      type="text"
                      class="form-control"
                      placeholder={lang.first_name}
                      name="firstname"
                      register={userForm.register}
                      errors={userForm.formState.errors}
                    />
                  </div>
                  <div class="form-m-b-20">
                    <Textfield
                      type="text"
                      class="form-control"
                      placeholder={lang.last_name}
                      name="lastname"
                      register={userForm.register}
                      errors={userForm.formState.errors}
                    />
                  </div>
                  <div class="form-m-b-20">
                    <Textfield
                      type="text"
                      class="form-control"
                      placeholder={lang.email}
                      name="emailaddress"
                      register={userForm.register}
                      errors={userForm.formState.errors}
                      {...(userForm.getValues("type") == "add" ? { readOnly: false } : { readOnly: true })}
                    />
                  </div>
                  <div class="form-m-b-20">
                    <Textfield
                      type="text"
                      class="form-control"
                      placeholder={lang.mobile_number + " (" + lang.optional + ")"}
                      name="mobile"
                      maxlength="10"
                      register={userForm.register}
                      errors={userForm.formState.errors}
                      {...(userForm.getValues("type") == "add" ? { readOnly: false } : { readOnly: true })}
                    />
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    // data-dismiss='modal'
                    onClick={() => setUserDialog((prev) => ({ ...prev, open: false }))}>
                    {lang.cancel}
                  </button>
                  <button
                    type="submit"
                    className="btn btn-01 ms-3 my-2 px-4 py-2"
                    // onClick={handleLanguageSubmit}
                    disabled={userForm.formState.isSubmitting}>
                    {lang.save}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ManageUser
