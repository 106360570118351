import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './assets/css/scrollbar.css'
import ReactGA from 'react-ga4';

const gaTrackingId = process.env.REACT_APP_GA_TRACKING; // Ensure the variable starts with REACT_APP_

if (gaTrackingId) {
  // Initialize Google Analytics if tracking ID is defined
  ReactGA.initialize(gaTrackingId);
  ReactGA.send("pageview");
} else {
  console.warn("Google Analytics tracking ID is not defined.");
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
