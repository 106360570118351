import React, { useState, useEffect, useContext } from "react"
import { useParams, useNavigate, useOutletContext } from "react-router-dom"
import { AuthContext } from "../../libs/contexts/AuthContext"
import { SnackbarContext } from "../../libs/contexts/SnackbarContext"
import { handleApiError } from "../../libs/apis/handleApiError"
import api from "../../libs/apis/api"
import * as Yup from "yup"
import bulkImg from "../../assets/images/bulk-images.png"
import Header from "../../components/Header"
import moment from "moment"
import { useStompClient, useSubscription } from "react-stomp-hooks"
import translateIcon from "../../assets/images/translate_icon1.png"
import Dialog from "../../components/Dialog"
import "../../assets/css/dropdown.css"
import sortIcon from "../../assets/images/sort.svg"
import Tooltip from "../../components/Tooltip"
import ProfileInfoDialog from "./ProfileInfoDialog"
import ReportBlockDialog from "../../components/ReportBlockDialog"
import fromNowLang from "../../utils/fromNowLang"

const Responses = () => {
  let navigate = useNavigate()
  let params = useParams()
  const { auth, lang } = useContext(AuthContext)
  const { openSnackbar } = useContext(SnackbarContext)
  const { requests, fetchMyRequests } = useOutletContext()
  const [request, setRequest] = useState(null)
  const [responses, setResponses] = useState([])
  const [activeChat, setActiveChat] = useState(null)
  const [room, setRoom] = useState()
  const [messages, setMessages] = useState([])
  const [inputMsg, setInputMsg] = useState("")
  const [imgDialog, setImgDialog] = useState()
  const [dialog, setDialog] = useState({ open: false })
  const [sortMenu, setSortMenu] = useState(false)
  const [sortBtn, setSortBtn] = useState({
    filter: "",
    label: "",
    sortIds: [],
  })
  const [profileInfoDialog, setProfileInfoDialog] = useState({
    open: false,
    data: null,
  })
  const [blockDialog, setBlockDialog] = useState({ open: false, message: "" })
  const stompClient = useStompClient()

  useSubscription(
    `/topic/${room?.id}`,
    (message) => {
      setMessages([...messages, JSON.parse(message.body)])
    },
    {
      headers: {
        "content-type": "application/json",
        Authorization: auth.authToken,
        deviceToken: "",
      },
    }
  )

  const sendMessage = (message) => {
    if (stompClient) {
      //Send Message
      stompClient.publish({
        destination: `/app/topic`,
        headers: {
          "content-type": "application/json",
          // 'Authorization': auth.authToken,
          // 'deviceToken': ''
        },
        body: JSON.stringify({
          roomId: room.id,
          receiverId: activeChat.bpd?.id,
          feedId: params.id,
          text: "",
          imageUrl: "",
          videoUrl: "",
          title: "",
          authorization: auth.authToken.replace("Bearer ", ""),
          ...message,
        }),
      })
    } else {
      //Handle error
    }
  }

  const fetchRequest = async (values) => {
    try {
      const { data } = await api.get(`requests`)
      setRequest(data.find((item) => item.id == params.id))
      fetchResponses()
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const fetchResponses = async (msgSwitch) => {
    try {
      const { data } = await api.get(`requests/pricelist?feedId=${params.id}`)
      let d = data.map((item) => ({ ...item, bpd: item.businessProfileData }))

      if (!sortBtn.sortIds.length) setResponses(d)
      else setResponses((prev) => sortBtn.sortIds.map((item) => prev.find((pItem) => pItem.pricePostId == item)))

      if (msgSwitch) {
        setActiveChat(msgSwitch)
        fetchRoom(msgSwitch)
      } else if (activeChat) {
        setActiveChat(activeChat)
        fetchRoom(activeChat)
      } else {
        setActiveChat(d[0])
        fetchRoom(d[0])
      }
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const fetchRoom = async (ac) => {
    try {
      const { data } = await api.post(`v1/rooms/ensure?receiverId=${ac.bpd?.id}&feedId=${params.id}`)
      fetchMessages(ac, data.id)
      setRoom(data)
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const fetchMessages = async (ac, roomId) => {
    try {
      const { data } = await api.get(`v1/messages?roomId=${roomId}&receiverId=${ac.bpd?.id}&feedId=${params.id}`)
      setMessages(data)
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const translate = (tid) => {
    let t = []
    responses.forEach((item) => {
      if (item.pricePostId == tid) {
        t.push({ ...item, translate: !item.translate })
      } else {
        t.push({ ...item, translate: false })
      }
    })
    setResponses(t)
  }

  const translateChat = (tid) => {
    let t = []
    messages.forEach((item) => {
      if (item.id == tid) {
        t.push({ ...item, translate: !item.translate })
      } else {
        t.push({ ...item, translate: false })
      }
    })
    setMessages(t)
  }

  // useEffect(() => {
  //   // fetchRequest()
  //   if (requests) setRequest(requests.find((item) => item.id == params.id))
  //   if (request) fetchResponses()
  // }, [requests, request])

  useEffect(() => {
    if (requests) setRequest(requests.find((item) => item.id == params.id))
  }, [requests])

  useEffect(() => {
    fetchResponses()
  }, [])

  const handleMarkAllRead = async (values) => {
    try {
      const { data } = await api.get(`v1/rooms/clearAllNotification?feedId=${params.id}`)
      fetchResponses()
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const handleSort = async (values) => {
    try {
      const { data } = await api.post(`requests/sortByFilter`, {
        liveFeedId: params.id,
        filter: values.filter,
      })
      let sIds = data.map((item) => item.pricePostId)
      setSortBtn({ ...values, sortIds: sIds })
      setSortMenu(false)
      setResponses((prev) => sIds.map((item) => prev.find((pItem) => pItem.pricePostId == item)))
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  // const handleLocationPermission = () => {
  //   navigator.permissions.query({ name: "geolocation" }).then((result) => {
  //     if (result.state === "granted") {
  //       // report(result.state);
  //       console.log("Report ", result)
  //       navigator.geolocation.getCurrentPosition(
  //         (res) => {
  //           console.log("Res ", res)
  //         },
  //         (err) => {
  //           console.log("Error ", err)
  //         }
  //       )
  //       // geoBtn.style.display = "none";
  //     } else if (result.state === "prompt") {
  //       // report(result.state);
  //       console.log("Report ", result)
  //       // geoBtn.style.display = "none";
  //       navigator.geolocation
  //         .getCurrentPosition
  //         // revealPosition,
  //         // positionDenied,
  //         // geoSettings,
  //         ()
  //     } else if (result.state === "denied") {
  //       // report(result.state);
  //       console.log("Report ", result)
  //       // geoBtn.style.display = "inline";
  //     }
  //     result.addEventListener("change", () => {
  //       // report(result.state);

  //       console.log("Report ", result)
  //     })
  //   })
  // }
  const handleLocation = async (values) => {
    try {
      await navigator.geolocation.getCurrentPosition(
        async (res) => {
          console.log("Success Location ", res)
          const { data } = await api.post(`v1/salvage/addlocation`, {
            address: "",
            postCode: "",
            radius: "5",
            latitude: res.coords.latitude,
            longitude: res.coords.longitude,
          })
          openSnackbar({
            color: "success",
            message: <> Location updated</>,
          })
        },
        (error) => {
          console.log("Error: ", error)
          openSnackbar({
            color: "info",
            message: (
              <>
                {" "}
                Allow Location Permission to <br /> update your Location{" "}
              </>
            ),
          })
        },
        { timeout: 1000, maximumAge: 0 }
      )
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const handleCloseRequest = async (values) => {
    try {
      const { data } = await api.put(`/requests/closeDeal`, {
        liveFeedId: params.id,
        chatRoomId: room.id,
        receiverId: activeChat.bpd?.id,
        closedByUser: request.business.id,
        flag: "close",
      })
      setDialog((prev) => ({ ...prev, open: false }))
      fetchMyRequests("refresh")
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }
  const handleImageUpload = async (values) => {
    try {
      // if (!values.file) {
      //   throw { message: 'Upload Photo is required' }
      // }
      const formData = new FormData()
      const jsonString = JSON.stringify({
        receiverId: activeChat.bpd?.id,
        roomId: room.id,
        feedId: params.id,
      })
      // const jsonBlob = new Blob([jsonString], { type: 'application/json' })
      // formData.append('body', jsonBlob)
      formData.append("receiverId", activeChat.bpd?.id)
      formData.append("roomId", room.id)
      formData.append("feedId", params.id)
      formData.append("uploadImage", values)

      const { data } = await api.post(`v1/messages/media`, formData)
      sendMessage({ imageUrl: data.uploadImage.pictureURL })
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const handleBlock = async (values) => {
    try {
      const { data } = await api.post(`/spam/blockUser`, {
        blockedUserId: values.data.userId,
        reportNotes: values.reportNotes,
        reportType: values.reportType,
      })
      fetchMyRequests("refresh")
      setBlockDialog({ open: false })
      openSnackbar({ color: "success", message: "User Blocked Successfully" })
      // fetchUpdateData()
      navigate(-1)
      // handleClose()
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  if (!request) {
    return (
      <>
        <Header title={lang.responses} />
        <div class="wrapper">Loading...</div>
      </>
    )
  }
  return (
    <>
      <Header title={lang.responses} />
      <Dialog dialog={dialog} />
      <ProfileInfoDialog dialog={profileInfoDialog} handleClose={() => setProfileInfoDialog({ open: false, data: null })} />
      {blockDialog.open && <ReportBlockDialog dialog={blockDialog} />}
      <div class="wrapper">
        <div class="container-fluid rounded-4" style={{ border: "solid 2px #770b0250" }}>
          <div class="row">
            <div class="col-md-2 d-flex justify-content-between">
              <div class="d-flex align-items-center">
                {" "}
                <i
                  class="fas fa-arrow-left mx-2"
                  style={{
                    cursor: "pointer",
                    marginRight: "1.2em",
                    fontSize: "2em",
                  }}
                  onClick={() => navigate(-1)}></i>
              </div>
              <div class="user-profile-img-main d-flex justify-content-center my-3">
                <div
                  class="left-img m-0"
                  style={{
                    // backgroundImage: `url('${request.business?.profileImage?.url}')`,
                    // backgroundSize: "cover",
                    // backgroundRepeat: "no-repeat",
                    backgroundColor: "lightgrey",
                    overflow: "hidden",
                  }}>
                  <img
                    src={request.business?.profileImage?.url}
                    style={{
                      height: "100%",
                      width: "100%",
                      display: request.business?.profileImage?.url ? "" : "none",
                    }}
                  />
                </div>
              </div>
              <div></div>
            </div>
            <div class="col-md-9">
              <div class="h5 mt-3 mb-0 text-capitalize">{request.business?.firstname}</div>
              <div class="fs-4 text-black fw-bold">
                {request.year} {request.make} {request.model}
              </div>
              <div class="fs-5 text-black ">{request.translate ? request.spanishDesc : request.desc}</div>
              <div class="mb-2">
                <span class="fs-6 pe-5">{fromNowLang(moment(request?.createdAt).fromNow(), lang)}</span>
                <span
                  class="badge text-bg-warning text-white ml-5 text-wrap"
                  role="button"
                  onClick={() =>
                    setRequest((prev) => ({
                      ...prev,
                      translate: !prev.translate,
                    }))
                  }>
                  {lang.translate}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid mt-4">
          <div class="responses-open-main w-100 ms-0">
            <div class="row">
              <div class="col-md-4">
                <div className="sort-chats">
                  <div>
                    <div class="dropdown" style={{ float: "left" }}>
                      <button class="dropbtn" onClick={() => setSortMenu((prev) => !prev)}>
                        <img src={sortIcon} style={{ marginTop: "4px", width: "27px" }} />
                        <span>{sortBtn?.label ?? ""}</span>
                      </button>
                      <div class={`dropdown-content ${sortMenu ? "active" : ""}`}>
                        <ul>
                          {[
                            { filter: "price", label: "By Price" },
                            { filter: "location", label: "By Location" },
                            { filter: "ratings", label: "By Ratings" },
                          ].map((item) => (
                            <li key={item.filter} role="button" onClick={() => handleSort(item)}>
                              {item.label}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    {/* ---- Update Location - a business user feature ---- */}
                    {/* {auth?.data?.profiletype != "business" && (
                      <Tooltip
                        text="Update Location"
                        data={
                          <i
                            class="fas fa-map-marker-alt"
                            role="button"
                            style={{
                              fontSize: "1.2rem",
                              color: "black",
                              margin: ".7em 1em 0 1em",
                            }}
                            onClick={handleLocation}
                          ></i>
                        }
                      />
                    )} */}
                  </div>
                  <div className="mark-ar mt-3" role="button" onClick={handleMarkAllRead}>
                    {lang.mark_all_read}
                  </div>
                </div>
                <div className="chat-thread-list mt-3 pe-2" style={{ height: "62vh", padding: 0 }}>
                  {responses?.map((item) => (
                    <div
                      key={item.pricePostId}
                      className={`chat-chips-main `}
                      style={
                        activeChat.pricePostId == item.pricePostId
                          ? {
                              backgroundColor: "#ffffff",
                              border: "solid 3px #d4140475",
                            }
                          : item.read
                          ? {
                              backgroundColor: "#ffffff",
                              border: "solid 3px #e5dcdc",
                            }
                          : {
                              backgroundColor: "#d3e5fd",
                              border: "solid 3px #e5dcdc",
                            }
                      }
                      onClick={() => {
                        if (activeChat.pricePostId != item.pricePostId && messages?.length != 0) {
                          // setActiveChat(item)
                          // fetchRoom(item)
                          fetchResponses(item)
                          setMessages([])
                          if (!item.read) fetchMyRequests("refresh")
                        }
                      }}>
                      <div
                        role="button"
                        className="user-img"
                        style={{ overflow: "hidden" }}
                        onClick={() => setProfileInfoDialog({ open: true, data: item })}>
                        <img
                          src={item.bpd?.profileImage?.url}
                          style={{
                            height: "100%",
                            width: "100%",
                            display: item.bpd?.profileImage?.url ? "" : "none",
                          }}
                        />
                      </div>
                      <div class="user-content">
                        <h4>
                          {item.bpd?.firstname} {item.bpd?.lastname}{" "}
                          {item.bpd?.isActive ? <></> : <span style={{ color: "#d41404", fontStyle: "italic" }}>({lang.inactive_user})</span>}
                          <small>{item.bpd?.companyname}</small>
                        </h4>
                        <p>
                          {item.translate ? item.spanishDesc : item.description} <br /> ${item.price}
                        </p>
                      </div>
                      <div class="time-box d-flex flex-column align-items-end">
                        <div>{fromNowLang(moment(item.createdTime).fromNow(), lang)}</div>
                        <div className="mt-1"> {item.miles > 0 ? Number(item.miles).toFixed(2) + " miles" : ""}</div>
                      </div>
                      <div class="translate-btn" onClick={() => translate(item.pricePostId)}>
                        Translate
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div class="col-md-8">
                <div class="chat-thread-main" style={{ height: "70vh" }}>
                  <div class="chat-name-box">
                    <div
                      role="button"
                      className="user-img"
                      style={{ overflow: "hidden" }}
                      onClick={() => setProfileInfoDialog({ open: true, data: activeChat })}>
                      <img
                        src={activeChat?.bpd?.profileImage?.url}
                        style={{
                          height: "100%",
                          width: "100%",
                          display: activeChat?.bpd?.profileImage?.url ? "" : "none",
                        }}
                      />
                    </div>
                    <div className="user-details">
                      <div style={{ fontSize: "1.3em" }}>
                        {activeChat?.bpd?.firstname} {activeChat?.bpd?.lastname}
                      </div>
                      <div style={{ color: "#D41404" }}>{activeChat?.bpd?.companyname}</div>
                    </div>
                    <div className="close-request">
                      <button
                        className="btn btn-01 px-2.5 py-2 me-2"
                        disabled={!activeChat?.bpd?.isActive}
                        onClick={() => {
                          window.open(`https://www.google.com/maps/search/?api=1&query=${activeChat?.bpd?.addressText}`, "_blank")
                        }}>
                        <i class="fas fa-map-marker-alt fs-4"></i>
                      </button>
                      <button
                        className="btn btn-01 px-2.5 pt-2 pb-2 me-2 "
                        disabled={!activeChat?.bpd?.isActive}
                        onClick={() => {
                          setBlockDialog({
                            open: lang.block,
                            data: activeChat,
                            step: "block",
                            handleBlock: handleBlock,
                            handleCancel: () => setBlockDialog({ ...blockDialog, open: false }),
                          })
                          // window.open(`https://www.google.com/maps/search/?api=1&query=${activeChat?.bpd?.addressText}`, "_blank")
                        }}>
                        <i className="fas fa-ban fs-5 mt-1" />
                      </button>
                      {request.flag == "open" && (
                        <button
                          className="btn btn-01 btn-width-150"
                          disabled={!activeChat?.bpd?.isActive}
                          onClick={() =>
                            setDialog({
                              open: lang.close_deal,
                              message: (
                                <div style={{ paddingLeft: "1em" }}>
                                  {lang.close_deal_message}
                                  <br />
                                  <b>
                                    {request.year} {request.make}, {request.model}
                                  </b>
                                  ?
                                </div>
                              ),
                              handleConfirm: () => handleCloseRequest(),
                              handleCancel: () => setDialog({ ...dialog, open: false }),
                            })
                          }>
                          {lang.close_deal}
                        </button>
                      )}
                    </div>
                  </div>
                  {messages?.length == 0 && (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <div
                        style={{
                          height: "2em",
                          width: "2em",
                          marginTop: "2em",
                        }}>
                        <div className="loader"></div>
                      </div>
                    </div>
                  )}
                  {messages && (
                    <div className="chat-chats">
                      {messages
                        ?.map((item, index) => {
                          if (item.senderId == request.business.id)
                            return (
                              <div key={index} className="sender mt-2">
                                <div>
                                  {item.imageUrl ? (
                                    <div role="button" onClick={() => setImgDialog(item.imageUrl)}>
                                      <div>
                                        <img
                                          src={item.imageUrl}
                                          style={{
                                            width: "6em",
                                            height: "6em",
                                            borderRadius: ".5em",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="message">{item.text}</div>
                                  )}
                                  <div className="time">{fromNowLang(moment(item.date).fromNow(), lang)}</div>
                                </div>
                              </div>
                            )
                          else
                            return (
                              <div key={index} className="receiver mt-2">
                                <div>
                                  {item.imageUrl ? (
                                    <div role="button" onClick={() => setImgDialog(item.imageUrl)}>
                                      <img
                                        src={item.imageUrl}
                                        style={{
                                          width: "6em",
                                          height: "6em",
                                          borderRadius: ".5em",
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <div className="message">{item.translate ? item.spanishText : item.text}</div>
                                  )}

                                  <div className="time">
                                    <div>{fromNowLang(moment(item.date).fromNow(), lang)}</div>
                                    <div style={{ width: "2em", marginLeft: "1em" }} role="button" onClick={() => translateChat(item.id)}>
                                      <img src={translateIcon} width="100%" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                        })
                        .reverse()}
                    </div>
                  )}
                  {request.flag == "open" && activeChat?.bpd?.isActive && (
                    <div class="chat-input-main">
                      <div className="upload-btn-wrapper" role="button" style={{ cursor: "pointer" }}>
                        <button className="btn ps-1">
                          {" "}
                          <i class="fas fa-camera"></i>
                        </button>
                        <input
                          type="file"
                          name="myfile"
                          style={{ width: "2em", cursor: "pointer" }}
                          accept="image/*"
                          onChange={(e) => {
                            // setValue('file', e.target.files[0])
                            handleImageUpload(e.target.files[0])
                          }}
                        />
                      </div>
                      {/* <a href='javascript:void(0);'>
                                            <i class='fas fa-camera'></i>
                                        </a> */}
                      <input
                        type="text"
                        className="form-control"
                        placeholder={lang.enter_your_message}
                        value={inputMsg}
                        onChange={(e) => setInputMsg(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && inputMsg && !/^\s+$/.test(inputMsg)) {
                            sendMessage({ text: inputMsg })
                            setInputMsg("")
                          }
                        }}
                      />
                      <button
                        type="button"
                        class="btn"
                        onClick={() => {
                          if (inputMsg && !/^\s+$/.test(inputMsg)) {
                            sendMessage({ text: inputMsg })
                            setInputMsg("")
                          }
                        }}>
                        <i class="fas fa-paper-plane"></i>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal" role="dialog" style={{ display: imgDialog ? "block" : "none" }}>
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            backgroundColor: "#000",
            opacity: "0.5",
          }}></div>
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header pb-5">
              <h4 class="modal-title text-dark" id="exampleModalLongTitle">
                Image View
              </h4>
              <button type="button" style={{ border: "0" }} onClick={() => setImgDialog()}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <a href={imgDialog} target="_blank">
                <img src={imgDialog} class="object-fit-none p-5" width="100%" />
              </a>
              {/* <Carousal images={imgDialog} /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Responses
