import React, { useState, useEffect, useContext } from "react"
import { useParams, useNavigate, useOutletContext } from "react-router-dom"
import { AuthContext } from "../../libs/contexts/AuthContext"
import { SnackbarContext } from "../../libs/contexts/SnackbarContext"
import { handleApiError } from "../../libs/apis/handleApiError"
import api from "../../libs/apis/api"
import * as Yup from "yup"
import bulkImg from "../../assets/images/bulk-images.png"
import Header from "../../components/Header"
import DeleteDialog from "../../components/DeleteDialog"
import Dialog from "../../components/Dialog"
import InfoDialog from "../../components/InfoDialog"
import PriceDialog from "../../components/PriceDialog"
import Tooltip from "../../components/Tooltip"
import InfoAction from "../../components/InfoAction"
import moment from "moment"
import ChatDeal from "./ChatDeal"
import ChatDealDialog from "./ChatDealDialog"
import noImg from "../../assets/images/noImg.png"
import Carousal from "../../components/Carousal"
import fromNowLang from "../../utils/fromNowLang"

const MyQuotes = () => {
  let navigate = useNavigate()
  let params = useParams()
  const { handleSetAuth, lang, auth } = useContext(AuthContext)
  const { openSnackbar } = useContext(SnackbarContext)
  const { quotes, fetchQuotes, checkValidity } = useOutletContext()
  // const [quotes, setQuotes] = useState([])
  const [deleteDialog, setDeleteDialog] = useState({ open: false, message: "" })
  const [archiveDialog, setArchiveDialog] = useState({ open: false, message: "" })
  const [infoDialog, setInfoDialog] = useState({ open: false })
  const [priceDialog, setPriceDialog] = useState({ open: false })
  const [imgDialog, setImgDialog] = useState()
  const [chatDeal, setChatDeal] = useState(false)
  const [translateId, setTranslateId] = useState("")
  const [lessMoreId, setLessMoreId] = useState(null)
  const [loading, setLoading] = useState(true)

  const fetchData = async (values) => {
    try {
      setLoading(true)
      const data = await fetchQuotes()
    } catch (error) {
      handleApiError(error, openSnackbar)
    } finally {
      setLoading(false)
    }
  }

  const handleDelete = async (id) => {
    try {
      const { data } = await api.delete(`requests/${id}`)
      setDeleteDialog({ ...deleteDialog, open: false })
      fetchData()
      openSnackbar({ color: "success", message: "Deleted Successfully" })
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const handleArchive = async (id) => {
    try {
      const { data } = await api.put(`requests/${id}/archived`, {
        isArchived: true,
      })
      setDeleteDialog({ ...deleteDialog, open: false })
      fetchData()
      openSnackbar({ color: "success", message: "Archived Successfully" })
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const handleInfoNeeded = async (values) => {
    try {
      const { data } = await api.put(`requests/infoneeded`, { liveFeedId: values.id, question: values.message })
      fetchData()
      setInfoDialog((prev) => ({ ...prev, open: false }))
      openSnackbar({ color: "success", message: "Sent Request Successfully" })
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const handlePriceIt = async (values) => {
    try {
      const { data } = await api.put(`requests/priceit`, { liveFeedId: values.id, price: values.price, description: values.message })
      fetchData()
      setPriceDialog((prev) => ({ ...prev, open: false }))
      openSnackbar({ color: "success", message: "Sent Price Quote Successfully" })
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const fetchInvalidity = async (values) => {
    try {
      await checkValidity()
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }
  const [counter, setCounter] = useState(0)
  const [item, setItem] = useState(/* your initial item state */)

  useEffect(() => {
    fetchData()
    let newCounter = 0
    if (item) {
      item.forEach((i) => {
        i.chatRoom.senderStatus.forEach((sItem) => {
          if (sItem.id === auth.data.id && sItem.flag !== "read") {
            newCounter += 1
          }
        })
      })
    }
    setCounter(newCounter)
  }, [])

  // data?.forEach((item) => {
  //   item.chatRoom.senderStatus.forEach((sItem) => {
  //     if (sItem.id == auth.data.id && sItem.flag != "read") {
  //       count += 1
  //     }
  //   })
  // })

  const handleInfoActionColor = (inl) => {
    // if (inl == null) return null
    // let flag = inl.every((item) =>item.id == auth.data.id && item.flag != "read")
    // let flag = inl.senderStatus.flag ==="read" ? true : false
    // let flag = true
    // console.log("inl.senderStatus.flag ", inl.senderStatus.map((data, index) => {
    //   return data.flag;
    // }));
    // inl.forEach((item) => {
    //   flag = item.requestRead ? true : flag
    // })
    // const flag = inl?.chatRoom?.receiverStatus[0]?.flag
    let flag = inl === "read" ? "#58c11c" : "#f9ad4a"
    return flag
    // data?.forEach((item) => {
    //     item.chatRoom.senderStatus.forEach((sItem) => {
    //       if (sItem.id == auth.data.id && sItem.flag != "read") {
    //         count += 1
    //       }
    //     })
    //   })
  }

  // TODO: check quotes dependency, calling fetchData on mount
  useEffect(() => {
    // fetchInvalidity()
    if (chatDeal) {
      let cid = chatDeal.partRequest.id
      let quo = quotes.find((item) => item.partRequest.id == cid)
      if (!quo) {
        setChatDeal(false)
      } else if (quo.partRequest.business.flag != "complete") {
        setChatDeal(quo)
      } else {
        setChatDeal(false)
      }
    }
  }, [quotes])

  return (
    <>
      <Header title={lang.my_quote} />
      <Dialog dialog={archiveDialog} />
      <DeleteDialog deleteDialog={deleteDialog} />
      <InfoDialog dialog={infoDialog} />
      <PriceDialog dialog={priceDialog} />
      {chatDeal && (
        <ChatDealDialog
          chatDeal={chatDeal}
          handleClose={() => {
            fetchData()
            setChatDeal(false)
          }}
        />
      )}
      <div class="wrapper">
        <div class="table-main-div">
          <ul class="custome-table-head">
            <li>
              {lang.year}/{lang.make}/{lang.model}
            </li>
            <li>{lang.requests}</li>
            <li>{lang.requested_on}</li>
            <li>{lang.chat}</li>
            <li>{lang.images}</li>
            <li></li>
          </ul>
          {loading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ height: "2.5em", width: "2.5em", margin: "3em 0 3em 0" }}>
                <div className="loader"></div>
              </div>
            </div>
          ) : !quotes?.length ? (
            <div className="custome-tr-main" style={{ justifyContent: "center", padding: "2em 0", color: "gray", fontWeight: "bold" }}>
              {lang.empty_quotes}
            </div>
          ) : (
            quotes?.map((item) => (
              <div key={item.partRequest.id} class="custome-tr-main">
                <div className="icon-img-main">
                  <div key={item.partRequest.id}>
                    <img
                      src={item.partRequest?.business.profileImage?.url}
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "50%",
                        display: !item.partRequest.business.profileImage?.url && "none",
                        backgroundColor: "lightgrey",
                      }}
                    />
                    {item.chatRoom.senderStatus.filter((sItem) => sItem.id === auth.data.id && sItem.flag !== "read").length != 0 && (
                      <span class="position-absolute bottom-0 start-100 translate-middle-x badge rounded-pill p-1 px-2  fs-6 bg-danger border border-light rounded-circle">
                        {item.chatRoom.senderStatus.filter((sItem) => sItem.id === auth.data.id && sItem.flag !== "read").length}
                        <span class="visually-hidden"></span>
                      </span>
                    )}
                  </div>
                </div>
                <div class="td-1">
                  <small class="text- black-50">{item.partRequest.business?.companyname}</small>
                  <div class="h5 m-0">
                    {item.partRequest.year} {item.partRequest.make}, {item.partRequest.model}
                    {item.partRequest.business?.isActive ? (
                      <></>
                    ) : (
                      <span className="ps-2 fs-6" style={{ color: "#d41404", fontStyle: "italic" }}>
                        ({item.partRequest.business?.userType == "sub-user" ? lang.inactive_subuser : lang.inactive_user})
                      </span>
                    )}
                  </div>
                  <div class="text-black-50">{item.partRequest.trim}</div>
                </div>
                <div class="td-2 d-flex flex-column align-items-start" style={{ minHeight: "4em" }}>
                  {/* <div class="mb-auto mt-2">{item.partRequest.translate ? item.partRequest.spanishDesc : item.partRequest.desc}</div> */}
                  {item.partRequest.id == translateId ? (
                    <div
                      role="button"
                      className={`mb-auto mt-2 ${lessMoreId != item.partRequest.id && "show-more"}`}
                      onClick={() => setLessMoreId((prev) => (prev ? null : item.partRequest.id))}>
                      {item.partRequest.spanishDesc}
                    </div>
                  ) : (
                    <div
                      role="button"
                      className={`mb-auto mt-2 ${lessMoreId != item.partRequest.id && "show-more"}`}
                      onClick={() => setLessMoreId((prev) => (prev ? null : item.partRequest.id))}>
                      {item.partRequest.desc}
                    </div>
                  )}
                  <div
                    class="d-inline-block badge text-bg-warning text-white ml-5 text-wrap"
                    role="button"
                    onClick={() => setTranslateId(item.partRequest.id == translateId ? "" : item.partRequest.id)}>
                    {lang.translate}
                  </div>
                </div>
                <div class="td-3">{fromNowLang(moment(item.lastMessageDate).fromNow(), lang)}</div>

                <div class="td-4">
                  {item.response && (
                    // (item.chatRoom?.senderStatus[0]?.flag === "unread") && (
                    <button type="button" className="btn-03 p-1 px-4" onClick={() => setChatDeal(item)}>
                      {lang.chat}
                    </button>
                  )}
                </div>

                <div class="td-5">
                  <div id="lightgallery">
                    <span style={{ cursor: "pointer" }} onClick={() => setImgDialog(item.partRequest.images)}>
                      <img
                        src={item.partRequest.images?.[0].url ?? noImg}
                        class="img-fluid shadow object-fit-contain border rounded"
                        style={{ width: "5em", height: "5em" }}
                      />
                    </span>
                  </div>
                </div>
                <div class="td-6">
                  <div class=" dot-link d-flex h6">
                    <InfoAction
                      type={item.infoNeeded ? "info-requested" : ""}
                      badge={false}
                      // flagColor={handleInfoActionColor(item.chatRoom?.senderStatus[0]?.flag)}
                      handleClick={() => {
                        setInfoDialog({
                          open: true,
                          data: { ...item, ...item.partRequest },
                          type: item.infoNeeded ? "info-view" : "",
                          // handleConfirm: () => handleInfoNeeded(item.id),
                          handleConfirm: handleInfoNeeded,
                          handleCancel: () => setInfoDialog({ ...infoDialog, open: false }),
                        })
                      }}
                    />
                    <Tooltip
                      text={lang.price_it}
                      data={
                        <i
                          class="fas fa-dollar-sign mx-2"
                          style={{ cursor: "pointer", marginRight: "1.2em", fontSize: "1.2em" }}
                          onClick={() =>
                            setPriceDialog({
                              open: true,
                              data: { ...item, ...item.partRequest },
                              type: "price-it-view",
                              // handleConfirm: handlePriceIt,
                              handleCancel: () => setPriceDialog({ ...priceDialog, open: false }),
                            })
                          }></i>
                      }
                    />
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      <div class="modal" role="dialog" style={{ display: imgDialog ? "block" : "none" }}>
        <div style={{ position: "absolute", height: "100%", width: "100%", backgroundColor: "#000", opacity: "0.5" }}></div>
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title text-dark" id="exampleModalLongTitle">
                Image View
              </h4>
              <button type="button" style={{ border: "0" }} onClick={() => setImgDialog()}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              {/* <img src={imgDialog} class="object-fit-none p-5" width="100%" /> */}
              <Carousal images={imgDialog} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MyQuotes
