import React, { useState, useEffect, useContext } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { AuthContext } from "../../libs/contexts/AuthContext"
import { SnackbarContext } from "../../libs/contexts/SnackbarContext"
import { handleApiError } from "../../libs/apis/handleApiError"
import api from "../../libs/apis/api"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import bulkImg from "../../assets/images/bulk-images.png"
import Header from "../../components/Header"

const Keywords = () => {
  let navigate = useNavigate()
  let params = useParams()
  const { handleSetAuth, lang } = useContext(AuthContext)
  const { openSnackbar } = useContext(SnackbarContext)
  const [tab, setTab] = useState(0)
  const [keywords, setKeywords] = useState({
    blockKeywords: [],
    preferredKeywords: [],
  })
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm(
    useForm({
      resolver: yupResolver(
        Yup.object().shape({
          // keyword: Yup.string().required("Required!"),
        })
      ),
      defaultValues: {
        keyword: "",
      },
    })
  )

  const fetchKeywords = async (values) => {
    try {
      const { data } = await api.get(`v1/salvage`)
      setKeywords(data)
      //   navigate('/dashboard')
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const handleAddKeywords = async (values) => {
    try {
      const { data } = await api.put(`v1/salvage/keyword-${tab ? "block-add" : "add"}`, { keyword: values.keyword.trim() })
      fetchKeywords()
      setValue("keyword", "")
      openSnackbar({
        open: true,
        color: "success",
        message: "Successfully Added",
      })
      //   navigate('/dashboard')
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const handleRemoveKeywords = async (values) => {
    try {
      const { data } = await api.put(`v1/salvage/keyword-${tab ? "block-remove" : "remove"}`, { keyword: values })
      fetchKeywords()
      openSnackbar({
        open: true,
        color: "success",
        message: "Successfully Deleted",
      })
      //   navigate('/dashboard')
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }
  const handleRemoveAll = async (values) => {
    try {
      const { data } = await api.put(`v1/salvage/keyword-${tab ? "block-removeall" : "removeall"}`)
      fetchKeywords()
      openSnackbar({
        open: true,
        color: "success",
        message: "Successfully All Deleted",
      })
      //   navigate('/dashboard')
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }
  useEffect(() => {
    fetchKeywords()
  }, [])

  return (
    <>
      <Header title={lang.keywords} />
      <div class="wrapper">
        <div class="row">
          {/* <div class="col-md-8">
            <div style={{ cursor: "pointer" }} onClick={() => setTab(0)} className={`btn ${tab == 0 ? "btn-01-success" : "btn-02-success"} w-100`}>
              {lang.add_keyword}
            </div>
          </div> */}
          {/* -- Block Keywords Tab functionality hidden -- */}
          {/* <div class="col-md-4">
            <div style={{ cursor: "pointer" }} onClick={() => setTab(1)} className={`btn ${tab == 1 ? "btn-01" : "btn-02"} w-100`}>
              {lang.block_keywords}
            </div>
          </div> */}
          <div class="col-md-8 col-lg-8">
            <form onSubmit={handleSubmit(handleAddKeywords)}>
              <div class="login-box pt-4">
                <h4 className="text-dark">
                  {tab ? <span className="text-color-ly">{lang.block}</span> : <span className="text-color-ly-success">{lang.add}</span>}{" "}
                  {/* {lang.keyword_phrase} */}
                  {lang.keyword}
                </h4>
                <div class="form-m-b">
                  <input type="text" class="form-control form-control-icon" placeholder={lang.enter_model} {...register("keyword")} />
                </div>
                <div class="d-grid">
                  <button
                    type="submit"
                    disabled={!watch("keyword")?.trim() || isSubmitting}
                    class={`btn  ${tab == 0 ? "btn-01-success" : "btn-01"} btn-width-150 mb-2`}>
                    {lang.save}
                  </button>
                  {/* {tab ? lang.keyword_desc_block : lang.keyword_desc} */}
                  {lang.keyword_model_desc}
                </div>
              </div>
            </form>
          </div>
        </div>
        {tab == 0 ? (
          <div class="table-main-div">
            <h4 className="text-dark px-5 mb-4">
              {lang.added} {lang.keywords}
            </h4>
            <ul class="custome-table-head">
              <li class="h6">{lang.add_keyword}</li>
              <li class="h6">
                <span style={{ cursor: "pointer" }} onClick={handleRemoveAll}>
                  {lang.remove_all}
                </span>
              </li>
            </ul>
            {(!keywords.preferredKeywords || keywords.preferredKeywords?.length == 0) && (
              <div className="row">
                <div className="col-lg-5 text-center">{lang.no_keyword_found}</div>
              </div>
            )}
            {keywords.preferredKeywords?.map((item) => (
              <div key={item.id} class="custome-tr-main">
                <div class="td-1">{item}</div>
                <div class="td-2">
                  <div className="mx-4">
                    <i class="fas fa-trash" style={{ cursor: "pointer" }} onClick={() => handleRemoveKeywords(item)}></i>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div class="table-main-div">
            <h4 className="text-dark px-5 mb-4">
              {lang.block}
              {lang.keywords}
            </h4>
            <ul class="custome-table-head">
              <li class="h6">{lang.block_keywords}</li>
              <li class="h6">
                <span style={{ cursor: "pointer" }} onClick={handleRemoveAll}>
                  {lang.remove_all}
                </span>
              </li>
            </ul>
            {(!keywords.blockKeywords || keywords.blockKeywords?.length == 0) && (
              <div className="row">
                <div className="col-lg-5 text-center">{lang.no_keyword_found}</div>
              </div>
            )}
            {keywords.blockKeywords?.map((item) => (
              <div id={item.id} class="custome-tr-main">
                <div class="td-1">{item}</div>
                <div class="td-2">
                  <div className="mx-4">
                    <i class="fas fa-trash" style={{ cursor: "pointer" }} onClick={() => handleRemoveKeywords(item)}></i>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  )
}

export default Keywords
