import React, { useState, useEffect, useContext } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { AuthContext } from "../../libs/contexts/AuthContext"
import { SnackbarContext } from "../../libs/contexts/SnackbarContext"
import { handleApiError } from "../../libs/apis/handleApiError"
import api from "../../libs/apis/api"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import bulkImg from "../../assets/images/bulk-images.png"
import Header from "../../components/Header"
import Switch from "../../components/FormFields/Switch"
import Textfield from "../../components/FormFields/Textfield"

const AboutUs = () => {
  let navigate = useNavigate()
  let params = useParams()
  const { handleSetAuth, lang } = useContext(AuthContext)
  const { openSnackbar } = useContext(SnackbarContext)
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      aboutUsText: "",
    },
  })

  const fetchData = async (values) => {
    try {
      const { data } = await api.get(`v1/salvage`)
      reset({ aboutUsText: data.aboutustext })
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleAboutUs = async (values) => {
    try {
      const { data } = await api.put(`v1/salvage/aboutus`, values)
      openSnackbar({ color: "success", message: "Updated Successfully" })
      // navigate('/my-request')
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  return (
    <>
      <Header title={lang.about_us} />
      <div class="wrapper">
        <form onSubmit={handleSubmit(handleAboutUs)}>
          <section class="create-request-section-one">
            <div class="row">
              <div class="col-md-8">
                <div class="form-m-b-20">
                  <label class="form-label mt-10 mb-4">{lang.about_us_description}</label>
                  <textarea
                    class="form-control"
                    rows="10"
                    // cols='10'
                    placeholder="Type Here"
                    {...register("aboutUsText")}
                    style={{ height: "7em" }}></textarea>
                </div>
                <button type="submit" class="btn btn-01 btn-width-150 mt-30">
                  {lang.save}
                </button>
              </div>
            </div>
          </section>
        </form>
      </div>
    </>
  )
}

export default AboutUs
