import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import Routes from './libs/routes/Routes'
import SnackbarContextProvider from './libs/contexts/SnackbarContext'
import AuthContextProvider from './libs/contexts/AuthContext'
import { StompSessionProvider } from "react-stomp-hooks";
import './assets/css/bootstrap.min.css'
import './assets/js/bootstrap.bundle.min.js.map'
import './assets/css/style.css'
import './assets/css/switch.css'
import './assets/css/dialog-modal.css'
import './assets/css/loader.css'

const App = () => {
  return (
    <>
      <BrowserRouter>
        <AuthContextProvider>
          <SnackbarContextProvider>
            <StompSessionProvider
              url={process.env.REACT_APP_WEBSOCKET}
              onStompError={(err) => { }} // console.log('Err ', err)
              onWebSocketError={err => { }} // console.log('Err ', err)
            >
              <Routes />
            </StompSessionProvider>
          </SnackbarContextProvider>
        </AuthContextProvider>
      </BrowserRouter>
    </>
  )
}

export default App
