import { useState, useEffect } from "react"

const Carousal = (props) => {
  const { images, ...rest } = props
  const [active, setActive] = useState(0)
  let allImages = images?.map((item,index) => (
      <img key={index} src={item.url} style={{ objectFit: "contain", maxHeight: "100%", maxWidth: "100%" }} role="button" />
  ))
  let activeUrl = images?.find((i, ind) => ind == active)
  let activeImage = allImages?.find((i, ind) => ind == active)

  useEffect(() => {
    if (!images || !images.length > 0) setActive(0)
  }, [images])

  return (
    <>
      <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
        <div className="carousel-indicators rounded-3">
          {images?.map((item, index) => (
            <button
              key={index}
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="0"
              className={active == index ? "active" : ""}
              aria-current="true"
              aria-label="Slide 1"></button>
          ))}
        </div>
        {/* <div
          class="carousel-inner"
          style={{
            backgroundColor: "grey",
            // backgroundImage: `url(${item.url}))`,
            textAlign: "center",
            height: "50vh",
            width: "100%",
          }}>
          {images?.map((item, index) => (
            <div key={index} className={`carousel-item ${active == index ? "active" : ""}`}>
              <img src={item.url} style={{ objectFit: "cover" }} />
            </div>
          ))}
        </div> */}
        <div class="carousel-inner rounded-3">
          <div
            // className={`carousel-item ${active == index ? "active" : ""}`}
            style={{ position: "relative", height: "80vh", display: "flex", justifyContent: "center", alignItems: "center" }}
            role="button"
            onClick={() => window.open(activeUrl?.url, "_blank")}>
            <div style={{ position: "absolute", top: "0", bottom: "0", left: "0", right: "0", backgroundColor: "rgba(88, 84, 84, 0.3)" }}> </div>
            {/* <img src={activeImage?.url} style={{ objectFit: "contain", maxHeight: "100%", maxWidth: "100%" }} role="button" /> */}
            {activeImage ?? <></>}
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="prev"
          onClick={() => {
            if (active == 0) setActive(images.length - 1)
            else setActive(active - 1)
          }}>
          <span class="carousel-control-prev-icon" aria-hidden="true" style={{ color: "#d41404" }}></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="next"
          style={{ color: "#d41404" }}
          onClick={() => {
            if (active == images.length - 1) setActive(0)
            else setActive(active + 1)
          }}>
          <span class="carousel-control-next-icon" aria-hidden="true" style={{ color: "#d41404" }}></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </>
  )
}

export default Carousal
