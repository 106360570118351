import React, { useState, useEffect, useContext } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { AuthContext } from "../libs/contexts/AuthContext"

const Dialog = ({ dialog }) => {
  let navigate = useNavigate()
  let params = useParams()
  const { handleSetAuth, lang } = useContext(AuthContext)
  const [disabledConfirm, setDisabledConfirm] = useState(false)
  const [reportBtn, setReportBtn] = useState("")

  useEffect(() => {
    if (!dialog.open) {
      setDisabledConfirm(false)
    }
  }, [dialog])

  if (!dialog.open) return <></>
  return (
    <>
      <div class={`modal`} role="dialog" style={{ display: "block" }}>
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            backgroundColor: "#000",
            opacity: "0.5",
          }}></div>
        <div class={`modal-dialog ${dialog?.size ?? 'modal-lg'} modal-dialog-centered`} role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title text-dark my-3 mt-1" id="exampleModalLongTitle">
                {dialog.message}
              </h5>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-danger px-3 fs-6"
                onClick={() => {
                  setDisabledConfirm(true)
                  dialog.handleConfirm()
                }}
                disabled={disabledConfirm}>
                {dialog.open}
              </button>
              <button type="button" class="btn btn-secondary mx-4 ms-3" onClick={dialog.handleCancel}>
                {lang.cancel}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Dialog
