import React, { useState, useEffect, useContext } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { AuthContext } from "../libs/contexts/AuthContext"
import Textfield from "./FormFields/Textfield"

const PriceDialog = ({ dialog }) => {
  let navigate = useNavigate()
  let params = useParams()
  const { handleSetAuth, lang } = useContext(AuthContext)
  const [price, setPrice] = useState("")
  const [message, setMessage] = useState("")
  const [disabled, setDisabled] = useState(false)
  const [lessMore, setLessMore] = useState(false)

  let priceItTypes = [
    {
      id: "price-it",
      header: lang.info_needed,
      body: (
        <div class="form-box mx-4">
          <div class="form-field mb-1">
            <input
              type="number"
              class="form-control mt-2 mb-4 form-control-icon"
              placeholder={"Type your Price"}
              onChange={(e) => setPrice(e.target.value)}
            />
            <i class="badge rounded-circle text-bg-dark">
              <i class="fas fa-dollar-sign" style={{ marginTop: "2px" }}></i>{" "}
            </i>
          </div>
          <textarea
            class="form-control"
            rows="10"
            placeholder="Type your message"
            style={{ height: "7em" }}
            onChange={(e) => setMessage(e.target.value)}></textarea>
          <div style={{ textAlign: "center" }}> Note: Only 1 message can be sent</div>
        </div>
      ),
      footer: true,
    },
    {
      id: "price-it-view",
      header: lang.info_received,
      body: (
        <div class="mx-4">
          <div class="h5 py-3">
            Price : <span class="text-black fw-bold">${dialog.data?.price}</span>
          </div>
          <div class="h5 pb-4">
            Comment : <span class="text-black fw-bold">{dialog.data?.priceDescription}</span>
          </div>
        </div>
      ),
      footer: false,
    },
  ]

  let priceIt = priceItTypes.find((item) => item.id == dialog.type)

  useEffect(() => {
    if (!dialog.open) {
      setPrice("")
      setMessage("")
      setLessMore(false)
    }
  }, [dialog])

  if (!dialog.open) return <></>
  return (
    <>
      {/* <!-- Notification Modal --> */}
      <div class={`modal`} role="dialog" style={{ display: "block" }}>
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            backgroundColor: "#000",
            opacity: "0.5",
          }}></div>
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title text-dark" id="exampleModalLongTitle">
                {lang.price_it}
              </h4>
              <button type="button" class="close" style={{ border: "0" }} onClick={dialog.handleCancel}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="d-flex">
                <div class="user-profile-img-main mb-4">
                  <div
                    class="left-img"
                    style={{
                      backgroundImage: `url('${dialog.data.business.profileImage?.url}')`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      width: "5em",
                      height: "5em",
                      marginLeft: "1.5em",
                    }}></div>
                </div>

                <div>
                  <div style={{ fontSize: "1.6em", color: "black" }}>
                    {" "}
                    {dialog.data.year} {dialog.data.make}, {dialog.data.model}
                  </div>
                  <div style={{ fontSize: "1.3em", color: "black" }}> {dialog.data.trim}</div>
                  <div style={{ fontSize: "1.4em", color: "black" }}>
                    <div role="button" className={!lessMore && "show-more"} onClick={() => setLessMore(!lessMore)}>
                      {dialog.data.desc}
                    </div>
                  </div>
                </div>
              </div>
              {priceIt.body}
            </div>

            {priceIt.footer && (
              <div class="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger"
                  disabled={disabled || !price}
                  onClick={async () => {
                    setDisabled(true)
                    await dialog.handleConfirm({ ...dialog.data, price: price, message: message })
                    setDisabled(false)
                    setPrice("")
                    setMessage("")
                  }}>
                  {lang.submit}
                </button>
                <button
                  type="button"
                  class="btn btn-secondary mx-4"
                  // data-dismiss='modal'
                  onClick={dialog.handleCancel}>
                  {lang.cancel}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default PriceDialog
