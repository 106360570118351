import React, { useState, useEffect, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { AuthContext } from '../../libs/contexts/AuthContext'
import { SnackbarContext } from '../../libs/contexts/SnackbarContext'
import { handleApiError } from '../../libs/apis/handleApiError'
import api, { toFormData } from '../../libs/apis/api'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import bulkImg from '../../assets/images/bulk-images.png'
import { useForm } from 'react-hook-form'
import { getValue } from '@testing-library/user-event/dist/utils'
import Textfield from '../../components/FormFields/Textfield'
import Select from '../../components/FormFields/Select'
import SpeechText from '../../components/SpeechText'

const validationSchema = Yup.object().shape({
  vinNumber: Yup.string().required('Required*'),
  year: Yup.string().required('Required*'),
  make: Yup.string().required('Required*'),
  model: Yup.string().required('Required*'),
  desc: Yup.string().required('Required*')
})

const CreateRequest = () => {
  let navigate = useNavigate()
  let params = useParams()
  const { handleSetAuth } = useContext(AuthContext)
  const { openSnackbar } = useContext(SnackbarContext)
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      vinNumber: '',
      year: '',
      make: '',
      model: '',
      desc: '',
      file: null
    }
  })

  // const handleSubmit = values => {
  //   console.log(values)
  //   navigate('/dashboard')
  // }
  const fetchData = async values => {
    try {
      const { data } = await api.get(`requests/livefeed`)
      console.log(
        'CC ',
        data.find(item => item.id == params.id)
      )
      reset(data.find(item => item.id == params.id))
      // setRequests(data.filter(item => params.id))
      // navigate("/dashboard");
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const handleRequest = async values => {
    try {
      if (!values.file) {
        throw { message: 'Upload Photo is required' }
      }
      const formData = new FormData()
      const jsonString = JSON.stringify(values)
      const jsonBlob = new Blob([jsonString], { type: 'application/json' })
      formData.append('body', jsonBlob)
      formData.append('file', values.file)

      const { data } = await api.put(
        `/requests`,
        formData
        // toFormData({ body: toFormData(values), file: values.file[0] })
      )
      navigate('/my-request')
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }
  useEffect(() => {
    fetchData()
  }, [])
  // if()
  return (
    <>
      <div class='top-bar-header'>
        <div class='left-side'>
          <h1>Edit Request</h1>
        </div>
        <div class='right-side'>
          <ul>
            <li class='notification-link'>
              <i class='fas fa-bell'></i>
            </li>
            <li class='user-name-img-li'>
              <label>Gabriel Garcia</label> <span class='user-img'></span>
              <i class='fas fa-plus-circle'></i>
            </li>
          </ul>
        </div>
      </div>
      <div class='wrapper'>
        <form onSubmit={handleSubmit(handleRequest)}>
          <section class='create-request-section-one'>
            <p class='sub-title'>
              Please give as much details as possible about the part you are
              requesting.
              <br />
              For example Year, Make, Model, Driver side front door etc.
            </p>
            <div class='row'>
              <div class='col-md-8'>
                <div class='form-m-b-20'>
                  <Textfield
                    type='text'
                    class='form-control'
                    placeholder='Vin Number'
                    name='vinNumber'
                    register={register}
                    errors={errors}
                  />
                </div>
                <div class='row'>
                  <div class='col-md-4'>
                    <div class='form-m-b-20'>
                      <Select
                        class='form-select'
                        // {...register('year')}
                        name='year'
                        register={register}
                        errors={errors}
                        placeholder='Select Year'
                        options={['2000', '2001', '2002', '2003', '2004'].map(
                          item => ({ value: item, label: item })
                        )}
                      />
                      {/* <select class='form-select' {...register('year')}>
                        <option selected value=''>
                          Year
                        </option>
                        {['2000', '2001', '2002', '2003', '2004'].map(item => (
                          <option value={item}>{item}</option>
                        ))}
                      </select> */}
                    </div>
                  </div>
                  <div class='col-md-4'>
                    <div class='form-m-b-20'>
                      <Select
                        class='form-select'
                        // {...register('year')}
                        name='make'
                        register={register}
                        errors={errors}
                        placeholder='Select Make'
                        options={['1', '2', '3', '4'].map(item => ({
                          value: item,
                          label: item
                        }))}
                      />
                      {/* <select class='form-select' {...register('make')}>
                        <option selected value=''>
                          Make
                        </option>
                        <option value='1'>1</option>
                        <option value='2'>2</option>
                        <option value='3'>3</option>
                        <option value='4'>4</option>
                      </select> */}
                    </div>
                  </div>
                  <div class='col-md-4'>
                    <div class='form-m-b-20'>
                      <Select
                        class='form-select'
                        // {...register('year')}
                        name='model'
                        register={register}
                        errors={errors}
                        placeholder='Select Model'
                        options={['1', '2', '3', '4'].map(item => ({
                          value: item,
                          label: item
                        }))}
                      />
                      {/* <select class='form-select' {...register('model')}>
                        <option selected value=''>
                          Model
                        </option>
                        <option value='1'>1</option>
                        <option value='2'>2</option>
                        <option value='3'>3</option>
                        <option value='4'>4</option>
                        <option value='5'>5</option>
                      </select> */}
                    </div>
                  </div>
                </div>
                <div class='form-m-b-20 mt-30'>
                  <label class='form-label'>
                    Upload photo of the part requesting
                  </label>
                  <div class='upload-btn-wrapper'>
                    <button class='btn'>
                      <span>
                        <i class='fas fa-images'></i>
                      </span>{' '}
                      Upload Photo{' '}
                      {watch('file') ? `- ${watch('file').name}` : ''}
                    </button>
                    <input
                      type='file'
                      name='myfile'
                      onChange={e => {
                        console.log('Fiile ', e.target.files[0])
                        setValue('file', e.target.files[0])
                      }}
                    />
                  </div>
                </div>
                <div class='form-m-b-20'>
                  <label class='form-label mt-30'>Type your part request</label>{' '}
                  <SpeechText setValue={setValue} />
                  {/* <input
                    type='text' */}
                  <textarea
                    class='form-control'
                    rows='10'
                    // cols='10'
                    placeholder='Example: Looking for a driver side front door...'
                    {...register('desc')}
                  ></textarea>
                  <p className='text-danger mx-3 mt-1'>
                    {errors['desc']?.message}
                  </p>
                </div>
                {/* <div class='form-m-b-20'>
                  <label class='form-label mt-30'>Speech Text</label>
                  <SpeechText />
                </div> */}
                <button type='submit' class='btn btn-01 btn-width-150 mt-30'>
                  Post
                </button>
              </div>
            </div>
          </section>
        </form>
      </div>
    </>
  )
}

export default CreateRequest
