import React, { useState, useEffect, useContext } from "react"
import { useParams, useNavigate, useOutletContext } from "react-router-dom"
import { AuthContext } from "../../libs/contexts/AuthContext"
import { SnackbarContext } from "../../libs/contexts/SnackbarContext"
import api from "../../libs/apis/api"
import * as Yup from "yup"
import { handleApiError } from "../../libs/apis/handleApiError"
import { yupResolver } from "@hookform/resolvers/yup"
import bulkImg from "../../assets/images/bulk-images.png"
import { useForm } from "react-hook-form"
import Header from "../../components/Header"
import Textfield from "../../components/FormFields/Textfield"
import Tooltip from "../../components/Tooltip"
import DeleteDialog from "../../components/DeleteDialog"
import Dialog from "../../components/Dialog"

const validationSchema = Yup.object().shape({
  firstname: Yup.string().required("Required*"),
  lastname: Yup.string().required("Required*"),
  emailaddress: Yup.string().email("Please enter valid email").required("Email is Required!"),
  mobile: Yup.number().typeError("Invalid Number").integer("Must be 10 digits").required("Required"),
})

const BlockedUser = () => {
  let navigate = useNavigate()
  let params = useParams()
  const { auth, lang } = useContext(AuthContext)
  const { openSnackbar } = useContext(SnackbarContext)
  const { fetchLivefeed, fetchQuotes, fetchMyRequests } = useOutletContext()
  const [users, setUsers] = useState()
  const [userDialog, setUserDialog] = useState({ open: false })
  const [dialog, setDialog] = useState({ open: false, message: "" })
  const userForm = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      firstname: "",
      lastname: "",
      emailaddress: "",
      mobile: "",
      type: "add",
    },
  })
  const fetchData = async (values) => {
    try {
      const { data } = await api.get(`/spam/blockUserList`)
      setUsers(data)
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleUserFormSubmit = async (values) => {
    try {
      if (values.type == "add") {
        const { data } = await api.post(`/v1/userflow/createUser`, { ...values })
        openSnackbar({ message: "User Added Successfully", color: "success" })
      } else {
        const { data } = await api.put(`/v1/userflow/updateUser`, { ...values, userId: values.id })
        openSnackbar({ message: "User Updated Successfully", color: "success" })
      }
      fetchData()
      setUserDialog((prev) => ({ ...prev, open: false }))
      userForm.reset()
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const handleUnblock = async (id) => {
    try {
      const { data } = await api.delete(`spam/unBlockUser?blockedUserId=${id}`)
      setDialog({ ...dialog, open: false })
      fetchData()
      fetchQuotes("refresh")
      fetchMyRequests("refresh")
      openSnackbar({ color: "success", message: "User Unblocked Successfully" })
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  return (
    <>
      <Dialog dialog={dialog} />
      {/* <DeleteDialog deleteDialog={deleteDialog} /> */}
      <Header title={lang.blocked_user} />
      <div class="wrapper">
        <div class="row">
          <div class="col-sm-9 col-md-6 col-lg-4">
            <div class="table-main-div">
              <h4 className="text-dark px-5 mb-4">{lang.blocked_users_list}</h4>
              <ul class="custome-table-head">
                <li class="h6">{lang.name}</li>
                {/* <li class="h6">{lang.email}</li>
                <li class="h6">{lang.mobile_number}</li> */}
                <li></li>
              </ul>
              {!users?.length && (
                <div className="custome-tr-main" style={{ justifyContent: "center", padding: "2em 6em 2em 0", color: "gray", fontWeight: "bold" }}>
                  No Blocked Users{" "}
                </div>
              )}
              {users?.map((item) => (
                <div class="custome-tr-main">
                  <div class="td-1">{item.firstName + " " + item.lastName}</div>
                  <div class="td-2"></div>
                  <div class="td-3"></div>
                  {/* <div class="td-4"></div> */}
                  <div class="td-5"></div>
                  <div class="td-6">
                    <Tooltip
                      text={lang.unblock}
                      data={
                        <i
                          class="fas fa-ban mx-2 fs-5"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            setDialog({
                              open: lang.unblock,
                              message: (
                                <>
                                  {lang.sure_unblock_user}{" "}
                                  <b>
                                    {item.firstName} {item.lastName}
                                  </b>
                                  ?
                                </>
                              ),
                              handleConfirm: () => handleUnblock(item.blockedUserId),
                              handleCancel: () => setDialog({ ...dialog, open: false }),
                            })
                          }></i>
                      }
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BlockedUser
