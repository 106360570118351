import React from 'react'

const Textfield = ({ name, errors, register, ...rest }) => {
  let err = errors[name]?.message
  return (
    <>
      <input
        type='text'
        {...register(name)}
        className={rest.class}
        placeholder={rest.placeholder}
        style={{ border: err ? '2px solid red' : '' }}
        {...rest}
      />
      <p className='text-danger mx-3 mt-1'>{errors[name]?.message}</p>
    </>
    
  )
}

export default Textfield
