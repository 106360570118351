import React, { useState, useEffect, useContext, memo, useMemo } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { AuthContext } from "../libs/contexts/AuthContext"

const MakeModelSelect = memo(function MakeModelSelect({ makeModelOptions, selectedMM, setSelectedMM }) {
  let navigate = useNavigate()
  let params = useParams()
  const { handleSetAuth, lang } = useContext(AuthContext)
  const [makeActive, setMakeActive] = useState("")
  const activeModelOptions = useMemo(() => makeModelOptions.find((item) => item.make == makeActive)?.model, [makeActive])

  const selectedMakeCount = (curr) => {
    let sc = selectedMM.find((f) => f.make == curr)?.model.length
    if (!sc) {
      return <></>
    }
    // else if (sc == modelOptions.length) { sc = 'All' }
    return (
      <span className="badge rounded-pill text-bg-danger border border-white">
        <small>{sc}</small>
      </span>
    )
  }

  const handleSelectMM = (m, mo) => {
    let updated = selectedMM.map((item) => {
      if (item.make == m) {
        if (item.model.includes(mo)) {
          return { make: m, model: item.model.filter((f) => f != mo) }
        } else {
          return { make: m, model: [...item.model, mo] }
        }
      } else {
        return item
      }
    })
    setSelectedMM(updated)
  }

  let isCheckAll = false
  selectedMM.forEach((item) => {
    if (item.make == makeActive) {
      isCheckAll = activeModelOptions.every((ev) => item.model.includes(ev))
    }
  })

  const handleCheckAll = (e) => {
    let updated = []
    if (!isCheckAll) {
      updated = selectedMM.map((item) => (item.make == makeActive ? { make: makeActive, model: activeModelOptions } : item))
    } else {
      updated = selectedMM.map((item) => (item.make == makeActive ? { make: makeActive, model: [] } : item))
    }
    setSelectedMM(updated)
  }
  return (
    <>
      {makeModelOptions.map((item) => (
        <div key={item.make} class="d-flex flex-column mb-3">
          <button
            type="button"
            className={`btn btn-outline-danger btn-lg btn-block text-left my-0 ${makeActive == item.make ? "active" : " "}`}
            onClick={() => setMakeActive(makeActive == item.make ? "" : item.make)}>
            <div className="d-flex justify-content-between">
              <div>{item.make}</div>
              <div>{selectedMakeCount(item.make)}</div>
            </div>
          </button>
          {makeActive == item.make && (
            <ul class="list-group list-group-flush border border-top-0 rounded">
              <li class="list-group-item h6" role="button" onClick={handleCheckAll}>
                <div class="">
                  <input checked={isCheckAll} onChange={handleCheckAll} type="checkbox" class="form-check-input mt-1" />
                  <label  role="button">
                    <div class="h5 px-2">
                      <b>{lang.check_all}</b>
                    </div>
                  </label>
                </div>
              </li>
              {item?.model.map((mItem) => (
                <li key={mItem} class="list-group-item h6" role="button" onClick={() => handleSelectMM(item.make, mItem)}>
                  <div class="">
                    <input
                      checked={selectedMM.find((f) => f.make == item.make)?.model.includes(mItem)}
                      onChange={() => handleSelectMM(item.make, mItem)}
                      id={`${mItem}-input`}
                      type="checkbox"
                      class="form-check-input mt-1"
                    />
                    <label role="button">
                      <div class="h5 px-2">{mItem}</div>
                    </label>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      ))}
    </>
  )
})

export default MakeModelSelect
