import React, { useState, useEffect, useContext } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { AuthContext } from "../../libs/contexts/AuthContext"
import { SnackbarContext } from "../../libs/contexts/SnackbarContext"
import { handleApiError } from "../../libs/apis/handleApiError"
import api from "../../libs/apis/api"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import logoImg from "../../assets/images/white-logo.png"
import Textfield from "../../components/FormFields/Textfield"
import { requestPermission } from "../../libs/services/firebase"

const LoginSchema = Yup.object().shape({
  authemail: Yup.string().email("Please enter valid email").required("Email is Required!"),
  authpassword: Yup.string()
    .min(5, "Minimum 5 characters required!!")
    // .max(50, 'Too Long!')
    .required("Password is Required"),
})

const Login = () => {
  let navigate = useNavigate()
  let params = useParams()
  const { handleSetAuth, lang, changeLanguage } = useContext(AuthContext)
  const { openSnackbar } = useContext(SnackbarContext)
  const [deviceToken, setDeviceToken] = useState(null)

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues: {
      authemail: "",
      authpassword: "",
      showPass: false,
    },
  })

  const handleLogin = async (values) => {
    try {
      api.defaults.headers.common["deviceToken"] = deviceToken
      const { data } = await api.post(`v1/auth/signup/authenticate`, values)
      if (data.businessProfileData.profiletype == "consumer") {
        await handleSetAuth({ ...data, isLoginEntry: true })
        navigate("/livefeed")
      } else {
        await handleSetAuth(data)
        navigate("/my-request")
      }
    } catch (error) {
      handleApiError(error, openSnackbar, { toastId: "login-api-error" })
    }
  }

  const getToken = async () => {
    // Fetching Device Token from Browser for firebase push notification
    let token = await requestPermission()
    setDeviceToken(token)
  }

  useEffect(() => {
    localStorage.clear()
    getToken()
  }, [])

  return (
    <>
      <section class="full-screen-body">
        <div class="container">
          <div class="logo-main">
            <img src={logoImg} class="img-fluid" />
          </div>
          <div class="row justify-content-center">
            <div class="col-md-9 col-lg-5">
              <form onSubmit={handleSubmit(handleLogin)}>
                <div class="login-box">
                  <h1>{lang.welcome}</h1>
                  {/* <p class='login-sub-title'>
                    <span class='red-font'>*</span> This service is for business
                    consumers ONLY
                  </p> */}
                  <div class="form-m-b mb-1">
                    <input type="text" class="form-control form-control-icon" placeholder={lang.email} {...register("authemail")} name="authemail" />
                    <i class="fas fa-user"></i>
                  </div>
                  <p className="text-danger mx-3 ">{errors["authemail"]?.message}</p>

                  <div class="form-m-b mb-1">
                    <input
                      type={watch("showPass") ? "text" : "password"}
                      class="form-control form-control-icon"
                      placeholder={lang.password}
                      {...register("authpassword")}
                      name="authpassword"
                    />
                    <i class="fas fa-lock"></i>
                    {watch("showPass") ? (
                      <i class="fas fa-eye" role="button" onClick={() => setValue("showPass", false)}></i>
                    ) : (
                      <i class="fas fa-eye-slash" role="button" onClick={() => setValue("showPass", true)}></i>
                    )}
                  </div>
                  <p className="text-danger mx-3 ">{errors["authpassword"]?.message}</p>
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-6">
                        <div class="cursor-pointer text-dark" onClick={changeLanguage}>
                          {lang.change_language} ({localStorage.getItem("language") == "English" ? "Español" : "English"})
                        </div>
                      </div>
                      <div class="col-6">
                        <a
                          role="button"
                          className="forgot-pass-link"
                          onClick={() => {
                            navigate("/forgot-password")
                          }}>
                          {lang.forgotPassword}
                        </a>
                      </div>
                    </div>
                  </div>

                  <div class="d-grid">
                    <button type="submit" class="btn btn-01">
                      {lang.login}
                    </button>
                  </div>
                </div>
              </form>

              <div class="sign-up-link-box">
                <p>{lang.dont_have_an_account}</p>
                <ul>
                  <li style={{ marginRight: "1em" }}>
                    <a onClick={() => window.open("/signup/business", "_self")} class="signup-btn cursor-pointer">
                      <span>{lang.salvage_yard}</span>
                      <br />
                      {lang.click_to_sign_up}
                      {/* {lang.activate_free_trial} */}
                    </a>
                  </li>
                  <li>
                    <a onClick={() => window.open("/signup/consumer", "_self")} class="signup-btn cursor-pointer">
                      <span>{lang.consumer} </span>
                      <br />
                      {lang.click_to_sign_up}
                      {lang.consumer == "Consumidor"}
                      {/* {lang.free_sign_up_now} */}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="login-footer">
          <p>
            {lang.have_questions}{" "}
            <a style={{ color: "white", textDecoration: "none" }} href="https://www.liveyards.com">
              {" "}
              {lang.contact_us}
            </a>
          </p>
          <p>
            © {new Date().getFullYear()} Liveyards. {lang.all_rights_reserved}. {lang.powered_by}.
          </p>
        </div>
      </section>
    </>
  )
}

export default Login
