import React, { useState, useEffect, useContext } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { AuthContext } from "../libs/contexts/AuthContext"

const InfoDialog = ({ dialog }) => {
  let navigate = useNavigate()
  let params = useParams()
  const { handleSetAuth, lang } = useContext(AuthContext)
  const [message, setMessage] = useState("")
  const [disabled, setDisabled] = useState(false)
  const [lessMore, setLessMore] = useState(false)

  // NeedInfo Dialog with 4 types of content
  let infos = [
    {
      id: "info",
      header: lang.info_needed,
      body: (
        <div class="mx-4">
          {/* <div class="pb-3" style={{ fontSize: "1.2em" }}>
            You have a message to your request
          </div> */}
          <textarea
            class="form-control"
            rows="10"
            placeholder="Type your message"
            onChange={(e) => {
              setMessage(e.target.value)
            }}
            style={{ height: "7em" }}></textarea>
          <div> NOTE: Use this space to request further information from the user. You can send only one message, so please be clear.</div>
        </div>
      ),
      footer: true,
    },
    {
      id: "info-requested",
      header: lang.info_requested,
      body: (
        <div class="mx-4">
          <div class="pb-3 fs-6 fw-bold text-black">Questions: {dialog.data?.question}</div>
          <textarea
            class="form-control"
            rows="10"
            placeholder="Type your Answer"
            // {...register('desc')}
            onChange={(e) => {
              setMessage(e.target.value)
            }}
            style={{ height: "7em" }}></textarea>
        </div>
      ),
      footer: true,
    },
    {
      id: "info-received",
      header: lang.info_received,
      body: (
        <div class="mx-4">
          <div class="h5 py-3">
            Question : <span class="text-black fw-bold">{dialog.data?.infoNeeded?.question}</span>
          </div>
          <div class="h5 pb-4">
            Answer : <span class="text-black fw-bold">{dialog.data?.infoNeeded?.infoAnswer}</span>
          </div>
        </div>
      ),
      footer: false,
    },
    {
      id: "info-view",
      header: lang.info_requested,
      body: (
        <div class="mx-4">
          <div class="h5 py-3">
            Question : <span class="text-black fw-bold">{dialog.data?.infoNeeded?.[0]?.question}</span>
          </div>
          <div class="h5 pb-4">
            Answer : <span class="text-black fw-bold">{dialog.data?.infoNeeded?.[0]?.infoAnswer}</span>
          </div>
        </div>
      ),
      footer: false,
    },
  ]

  let info = infos.find((item) => item.id == dialog.type)

  useEffect(() => {
    if (!dialog.open) {
      setMessage("")
      setLessMore(false)
    }
  }, [dialog])

  if (!dialog.open) return <></>
  return (
    <>
      {/* <!-- Notification Modal --> */}
      <div
        class={`modal`}
        // id='myModal'
        // tabindex='-1'
        role="dialog"
        // aria-labelledby='myModalTitle'
        // aria-hidden='true'
        style={{ display: "block" }}>
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            backgroundColor: "#000",
            opacity: "0.5",
          }}></div>
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title text-dark" id="exampleModalLongTitle">
                {info.header}
              </h4>
              <button
                type="button"
                class="close"
                // data-dismiss='modal'
                // aria-label='Close'

                style={{ border: "0" }}
                onClick={dialog.handleCancel}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="d-flex">
                <div class="user-profile-img-main mb-4">
                  <div
                    class="left-img"
                    style={{
                      backgroundImage: `url('${dialog.data.business.profileImage?.url}')`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      width: "5em",
                      height: "5em",
                      marginLeft: "1.5em",
                    }}></div>
                </div>

                <div>
                  <div style={{ fontSize: "1.6em", color: "black" }}>
                    {" "}
                    {dialog.data.year} {dialog.data.make}, {dialog.data.model}
                  </div>
                  <div style={{ fontSize: "1.1em", color: "black" }}> {dialog.data.trim}</div>
                  <div style={{ fontSize: "1.4em", color: "black" }}>
                    <div role="button" className={!lessMore && "show-more"} onClick={() => setLessMore(!lessMore)}>
                      {dialog.data.desc}
                    </div>
                  </div>
                </div>
              </div>
              {info.body}
            </div>
            {info.footer && (
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger"
                  disabled={disabled || !message}
                  onClick={async () => {
                    setDisabled(true)
                    await dialog.handleConfirm({ ...dialog.data, message: message })
                    setDisabled(false)
                  }}>
                  {lang.submit}
                </button>
                <button
                  type="button"
                  class="btn btn-secondary mx-4"
                  // data-dismiss='modal'
                  onClick={dialog.handleCancel}>
                  {lang.cancel}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default InfoDialog
