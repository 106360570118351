export default function fromNowLang(fromNowString, lang) {
  const langStrings = {
    "a few seconds ago": lang.a_few_seconds_ago,
    "seconds ago": lang.seconds_ago,
    "a minute ago": lang.a_minute_ago,
    "minutes ago": lang.minutes_ago,
    "an hour ago": lang.an_hour_ago,
    "hours ago": lang.hours_ago,
    "a day ago": lang.a_day_ago,
    "days ago": lang.days_ago,
    "a month ago": lang.a_month_ago,
    "months ago": lang.months_ago,
    "a year ago": lang.a_year_ago,
    "years ago": lang.years_ago,
  }
  let updatedString = ""
  Object.keys(langStrings).forEach((key) => {
    if (fromNowString.includes(key)) {
      updatedString = fromNowString.replace(key,langStrings[key])
    }
  })
  return updatedString
}
