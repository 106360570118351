import React, { useState, useEffect, useContext } from "react"
import { useParams, useNavigate, useOutletContext } from "react-router-dom"
import { AuthContext } from "../../libs/contexts/AuthContext"
import { SnackbarContext } from "../../libs/contexts/SnackbarContext"
import { handleApiError } from "../../libs/apis/handleApiError"
import api, { toFormData } from "../../libs/apis/api"
import * as Yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import bulkImg from "../../assets/images/bulk-images.png"
import { useForm } from "react-hook-form"
import { getValue } from "@testing-library/user-event/dist/utils"
import Textfield from "../../components/FormFields/Textfield"
import Select from "../../components/FormFields/Select"
import SpeechText from "../../components/SpeechText"
import Header from "../../components/Header"
import localforage from "localforage"
import axios from "axios"

// let makeModelOptions = []
// localforage
//   .getItem("_makemodel")
//   .then(function (value) {
//     makeModelOptions = value ?? []
//   })
//   .catch(function (err) {
//     // This code runs if there were any errors
//     console.log(err)
//   })

const validationSchema = Yup.object().shape({
  year: Yup.string().required("Required*"),
  make: Yup.string().required("Required*"),
  model: Yup.string().required("Required*"),
  desc: Yup.string().required("Required*"),
})

const CreateRequest = () => {
  let navigate = useNavigate()
  let params = useParams()
  const { handleSetAuth, lang } = useContext(AuthContext)
  const { requests, fetchMyRequests, checkValidity } = useOutletContext()
  const { openSnackbar } = useContext(SnackbarContext)
  const [imgDialog, setImgDialog] = useState(false)
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      vinNumber: "",
      year: "",
      make: "",
      model: "",
      trim: "",
      desc: "",
      file: [],
      devicetoken: "NoToken",
      makeOptions: [],
      modelOptions: [],
      yearOptions: [],
      trimOptions: [],
    },
  })

  const vinForm = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        vinNumber: Yup.string().length(17, "Must be 17 character").required("Required*"),
      })
    ),
    defaultValues: {
      vinNumber: "",
    },
  })

  const handleRequest = async (values) => {
    try {
      // if (!values.file) {
      //   throw { message: 'Upload Photo is required' }
      // }
      const formData = new FormData()
      const jsonString = JSON.stringify({
        vinNumber: values.vinNumber,
        year: values.year,
        make: values.make,
        model: values.model,
        trim: values.trim,
        desc: values.desc,
        devicetoken: values.devicetoken,
      })
      const jsonBlob = new Blob([jsonString], { type: "application/json" })
      formData.append("body", jsonBlob)
      values.file.forEach((item) => {
        formData.append("file", item)
      })
      const { data } = await api.post(`/requests`, formData)
      fetchMyRequests("refresh")
      openSnackbar({ color: "success", message: "Request Created Successfully" })
      navigate("/my-request")
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const handleVIN = async (values) => {
    try {
      const { data } = await api.get(`/v1/vin-search?vin=${values.vinNumber}`)
      await fetchOptionSelection({ year: data.year, make: "", model: "", trim: "" }, (opts) => setValue("makeOptions", opts))
      await fetchOptionSelection({ year: data.year, make: data.make, model: "", trim: "" }, (opts) => setValue("modelOptions", opts))
      await fetchOptionSelection({ year: data.year, make: data.make, model: data.model, trim: "" }, (opts) => setValue("trimOptions", opts))

      setValue("year", data.year)
      setValue("make", data.make)
      setValue("model", data.model)
      setValue("trim", data.trim)

      const trimOptionAvailable = getValues("trimOptions").find((item) => item == getValues("trim"))
      if (!trimOptionAvailable) {
        setValue("trimOptions", [...getValues("trimOptions"), data.trim ])
      }
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const fetchYears = async (values) => {
    try {
      const { data } = await api.get(`requests/findByType?type=year`)
      setValue("yearOptions", data)
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const fetchOptionSelection = async (values, setOptions) => {
    try {
      const { data } = await api.get(`requests/findByValue`, { params: values })
      setOptions(data)
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  useEffect(() => {
    fetchYears()
  }, [])

  // if (makeModelOptions?.length) {
  //   return (
  //     <>
  //       <Header title={lang.create_request} />
  //       <div class="wrapper">Loading...</div>
  //     </>
  //   )
  // }
  return (
    <>
      <Header title={lang.create_request} />
      <div class="wrapper">
        <section class="create-request-section-one">
          <p class="sub-title">
            {/* Please give as much details as possible about the part you are
              requesting.<br />For example Year, Make, Model, Driver side front door etc. */}
            {lang.detail_about_requesting}
          </p>
          <div class="row">
            <div class="col-md-8">
              <div class="form-m-b-20">
                <Textfield
                  type="text"
                  class="form-control"
                  placeholder={lang.vin_number}
                  name="vinNumber"
                  maxLength="17"
                  register={vinForm.register}
                  errors={vinForm.formState.errors}
                  // onKeyDown={(e) => (e.key === "Enter" ? vinForm.handleSubmit(handleVIN)() : "")}
                />
                <button
                  type="button"
                  class="btn btn-01 btn-width-10 mt-20"
                  style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                  onClick={() => {
                    vinForm.handleSubmit(handleVIN)()
                  }}>
                  <div>{lang.enter}</div>
                </button>
              </div>
              <form onSubmit={handleSubmit(handleRequest)}>
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-m-b-20">
                      <Select
                        class="form-select"
                        name="year"
                        register={register}
                        errors={errors}
                        placeholder={lang.select_year}
                        options={watch("yearOptions")
                          .map((item) => ({
                            value: item,
                            label: item,
                          }))
                          .reverse()}
                        onChange={(e) => {
                          setValue("year", e.target.value)
                          //   fetchMakes(`year=${e.target.value}&make=null&model=null&trim=null`)
                          fetchOptionSelection({ year: e.target.value, make: "", model: "", trim: "" }, (opts) => setValue("makeOptions", opts))
                          setValue("make", "")
                          setValue("model", "")
                          setValue("trim", "")
                          setValue("modelOptions", [])
                          setValue("trimOptions", [])
                        }}
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-m-b-20">
                      <Select
                        class="form-select"
                        name="make"
                        register={register}
                        errors={errors}
                        value={watch("make")}
                        placeholder={lang.select_make}
                        options={watch("makeOptions").map((item) => ({
                          value: item,
                          label: item,
                        }))}
                        onChange={(e) => {
                          setValue("make", e.target.value)
                          fetchOptionSelection({ year: getValues("year"), make: e.target.value, model: "", trim: "" }, (opts) =>
                            setValue("modelOptions", opts)
                          )
                          setValue("model", "")
                          setValue("trim", "")
                          setValue("trimOptions", [])
                        }}
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-m-b-20">
                      <Select
                        class="form-select"
                        name="model"
                        value={watch("model")}
                        register={register}
                        errors={errors}
                        placeholder={lang.select_model}
                        options={watch("modelOptions").map((item) => ({
                          value: item,
                          label: item,
                        }))}
                        onChange={(e) => {
                          setValue("model", e.target.value)
                          fetchOptionSelection({ year: getValues("year"), make: getValues("make"), model: e.target.value, trim: "" }, (opts) =>
                            setValue("trimOptions", opts)
                          )
                          setValue("trim", "")
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div class="form-m-b-20">
                  <Select
                    class="form-select"
                    name="trim"
                    register={register}
                    errors={errors}
                    value={watch("trim")}
                    placeholder={lang.select_trim_optional}
                    options={watch("trimOptions").map((item) => ({
                      value: item,
                      label: item,
                    }))}
                  />
                </div>
                <div class="form-m-b-20 mt-30">
                  <label class="form-label">{lang.take_upload_photo}</label>

                  <div class="upload-btn-wrapper">
                    {watch("file.length") === 15 ? (
                      <label style={{ fontSize: "20px", color: "#d41404", fontWeight: "bold" }} class="form-label">
                        Can not upload more than 15 images
                      </label>
                    ) : (
                      <>
                        <button class="btn fw-bold">
                          <span>
                            <i class="fas fa-images"></i>
                          </span>
                          <i className="fas fa-plus me-3"></i>
                          {lang.upload_photo}
                          {/* {watch('file') ? `- ${watch('file').name}` : ''} */}
                        </button>
                      </>
                    )}

                    <input
                      type="file"
                      name="myfile"
                      style={{ width: "15em" }}
                      accept="image/*"
                      onChange={(e) => {
                        setValue("file", [...getValues("file"), e.target.files[0]])
                      }}
                    />
                  </div>
                  <div className="row mt-3 ps-3">
                    {watch("file")?.map((item, index) => (
                      <div
                        role="button"
                        className="col-md-2 border rounded me-4 mb-4"
                        style={{ height: "13em", position: "relative" }}
                        onClick={() => setImgDialog(URL.createObjectURL(item))}>
                        <span
                          className="px-2 py-1 rounded"
                          style={{
                            backgroundColor: "white",
                            cursor: "pointer",
                            position: "absolute",
                            right: "0",
                            top: "0",
                            color: "#d41404",
                            fontSize: "1.5em",
                          }}
                          onClick={(e) => {
                            e.stopPropagation()
                            setValue(
                              "file",
                              getValues("file").filter((p, pInd) => pInd != index)
                            )
                          }}>
                          <i class="fas fa-trash"></i>
                        </span>
                        <img src={URL.createObjectURL(item)} style={{ width: "100%", height: "100%" }} />
                      </div>
                    ))}
                  </div>
                </div>
                <div class="form-m-b-20">
                  <label class="form-label mt-10 fw-bold mb-0">{lang.type_your_request}</label>
                  {/* <div className="text-secondary-emphasis mb-3  ">{lang.year_make_model_request}</div> */}
                  {/* <SpeechText setValue={setValue} /> */}
                  {/* <input
                    type='text' */}
                  <textarea
                    class="form-control"
                    rows="10"
                    // cols='10'
                    placeholder={lang.eg_door}
                    {...register("desc")}
                    style={{ height: "7em" }}></textarea>
                  <p className="text-danger mx-3 mt-1">{errors["desc"]?.message}</p>
                </div>
                {/* <div class='form-m-b-20'>
                  <label class='form-label mt-30'>Speech Text</label>
                  <SpeechText />
                </div> */}
                <button
                  type="submit"
                  class="btn btn-01 btn-width-150 mt-30"
                  disabled={isSubmitting}
                  style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  {isSubmitting && (
                    <div style={{ height: "1.5em", width: "1.5em", marginRight: "30px" }}>
                      {" "}
                      <div className="loader"></div>
                    </div>
                  )}
                  <div>{lang.post}</div>
                </button>
              </form>
            </div>
          </div>
        </section>
      </div>
      <div class="modal" role="dialog" style={{ display: imgDialog ? "block" : "none" }}>
        <div style={{ position: "absolute", height: "100%", width: "100%", backgroundColor: "#000", opacity: "0.5" }}></div>
        <div class="modal-dialog modal-md modal-dialog-centered" role="document">
          <div class="modal-content" style={{ overflow: "hidden" }}>
            <div class="modal-header">
              <h4 class="modal-title text-dark" id="exampleModalLongTitle">
                {lang.image_view}
              </h4>
              <button type="button" style={{ border: "0" }} onClick={() => setImgDialog()}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body p-0" style={{ maxHeight: "80vh" }}>
              <img src={imgDialog} class="object-fit-none" width="100%" height="100%" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CreateRequest
