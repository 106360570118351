import React, { useState, useEffect, useContext } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { AuthContext } from "../../libs/contexts/AuthContext"
import { SnackbarContext } from "../../libs/contexts/SnackbarContext"
import { handleApiError } from "../../libs/apis/handleApiError"
import api from "../../libs/apis/api"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import bulkImg from "../../assets/images/bulk-images.png"
import Header from "../../components/Header"
import Switch from "../../components/FormFields/Switch"
import Select from "../../components/FormFields/Select"
import moment from "moment"

const Settings = () => {
  let navigate = useNavigate()
  let params = useParams()
  const { handleSetAuth, lang } = useContext(AuthContext)
  const { openSnackbar } = useContext(SnackbarContext)
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        // firstname: Yup.string().required('Required*'),
      })
    ),
    defaultValues: {
      busiHours: [],
    },
  })

  const bh = [
    {
      dayOfWeek: "Monday",
      isActive: false,
      fromMinutes: "0100",
      toMinutes: "0100",
      fromTime: "08:30",
      toTime: "08:30",
      fromAmPm: "AM",
      toAmPm: "PM",
    },
    {
      dayOfWeek: "Tuesday",
      isActive: false,
      fromMinutes: "0100",
      toMinutes: "0100",
      fromTime: "08:30",
      toTime: "08:30",
      fromAmPm: "AM",
      toAmPm: "PM",
    },
    {
      dayOfWeek: "Wednesday",
      isActive: false,
      fromMinutes: "0100",
      toMinutes: "0100",
      fromTime: "08:30",
      toTime: "08:30",
      fromAmPm: "AM",
      toAmPm: "PM",
    },
    {
      dayOfWeek: "Thursday",
      isActive: false,
      fromMinutes: "0100",
      toMinutes: "0100",
      fromTime: "08:30",
      toTime: "08:30",
      fromAmPm: "AM",
      toAmPm: "PM",
    },
    {
      dayOfWeek: "Friday",
      isActive: false,
      fromMinutes: "0100",
      toMinutes: "0100",
      fromTime: "08:30",
      toTime: "08:30",
      fromAmPm: "AM",
      toAmPm: "PM",
    },
    {
      dayOfWeek: "Saturday",
      isActive: false,
      fromMinutes: "0100",
      toMinutes: "0100",
      fromTime: "08:30",
      toTime: "08:30",
      fromAmPm: "AM",
      toAmPm: "PM",
    },
    {
      dayOfWeek: "Sunday",
      isActive: false,
      fromMinutes: "0100",
      toMinutes: "0100",
      fromTime: "08:30",
      toTime: "08:30",
      fromAmPm: "AM",
      toAmPm: "PM",
    },
  ]
  let timeArr = []
  for (let i = 1; i <= 12; i++) {
    timeArr.push(`${i.toString().padStart(2, "0")}:00`)
    timeArr.push(`${i.toString().padStart(2, "0")}:30`)
  }
  const convert12 = (time, fromTo) => {
    let ct = time.slice(0, 2) + ":" + time.slice(2, 5)
    let min = moment(`${ct}`, "HH:mm").format("hh:mm A")
    if (fromTo == "from") {
      return { fromTime: min.slice(0, 5), fromAmPm: min.slice(6, 8) }
    } else {
      return { toTime: min.slice(0, 5), toAmPm: min.slice(6, 8) }
    }
  }

  const convertArrTo12 = (arr12) => {
    let arr24 = []
    arr12.forEach((item) => {
      arr24.push({
        dayOfWeek: item.dayOfWeek,
        isActive: item.active,
        ...convert12(item.fromMinutes.toString().padStart(4, "0"), "from"),
        ...convert12(item.toMinutes.toString().padStart(4, "0"), "to"),
      })
    })
    return arr24
  }

  const convert24 = (time, ampm) => {
    let min = moment(`${time} ${ampm}`, "hh:mm A").format("HH:mm")
    return min.replace(":", "")
  }

  const convertArrTo24 = (arr12) => {
    let arr24 = []
    arr12.forEach((item) => {
      console.log("Item", item)
      arr24.push({
        dayOfWeek: item.dayOfWeek,
        isActive: item.isActive,
        fromMinutes: convert24(item.fromTime, item.fromAmPm),
        toMinutes: convert24(item.toTime, item.toAmPm),
        fromTime: moment(`${item.fromTime} ${item.fromAmPm}`, "hh:mm A").format("HH:mm"),
        toTime: moment(`${item.toTime} ${item.toAmPm}`, "hh:mm A").format("HH:mm"),
      })
    })
    return arr24
  }

  const fetchProfile = async (values) => {
    try {
      const { data } = await api.get(`v1/salvage`)
      // /v1/salvage/business-hours
      data.businessHours ? reset({ busiHours: convertArrTo12(data.businessHours) }) : reset({ busiHours: bh })
      //   navigate('/dashboard')
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }
  const handleBusinessHoursSubmit = async (values) => {
    try {
      console.log("Date 12 ", values.busiHours)
      let arr24 = convertArrTo24(values.busiHours)
      console.log("Date 24 ", arr24)
      const { data } = await api.put(`v1/salvage/business-hours`, arr24)
      fetchProfile()
      openSnackbar({
        color: "success",
        message: "Successfully Updated Business Hours",
      })
      //   navigate('/dashboard')
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  useEffect(() => {
    fetchProfile()
  }, [])

  return (
    <>
      <Header title={lang.business_hours} />
      <div class="wrapper">
        <div className="mb-4 fs-6">{lang.business_hours_desc}</div>
        <form onSubmit={handleSubmit(handleBusinessHoursSubmit)}>
          <div class="row">
            <div class="col-md-12 col-lg-8">
              <div class="list-group">
                {watch("busiHours").map((item, index) => (
                  <li class="list-group-item">
                    <div class="d-flex align-items-center">
                      <div class="px-3">
                        <Controller
                          name={`busiHours.${index}.isActive`}
                          control={control}
                          render={({ field }) => (
                            <Switch
                              checked={field.value}
                              onChange={(e) => {
                                setValue(`busiHours.${index}.isActive`, e.target.checked)
                              }}
                            />
                          )}
                        />{" "}
                      </div>
                      <div class="h5 px-5 w-25">{lang[item.dayOfWeek.toLowerCase()]}</div>
                      <div class="mx-auto">
                        <div class="text-black-50  font-size-sm">{lang.from}</div>
                        <div class="d-flex">
                          <div>
                            <Controller
                              name={`busiHours.${index}.fromTime`}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  class="form-select"
                                  // name='year'
                                  //   register={register}
                                  //   errors={errors}
                                  placeholder="Select Time"
                                  options={timeArr.map((item) => ({
                                    value: item,
                                    label: item,
                                  }))}
                                  name={`busiHours.${index}.fromTime`}
                                  value={field.value}
                                  register={register}
                                  errors={errors}
                                />
                              )}
                            />
                          </div>
                          <div>
                            <Controller
                              name={`busiHours.${index}.fromAmPm`}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  class="form-select"
                                  options={["AM", "PM"].map((item) => ({
                                    value: item,
                                    label: item,
                                  }))}
                                  name={`busiHours.${index}.fromAmPm`}
                                  value={field.value}
                                  register={register}
                                  errors={errors}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div class="mx-3 mx-auto">
                        <div class="text-black-50  font-size-sm">{lang.to}</div>
                        <div class="d-flex">
                          <div>
                            <Controller
                              name={`busiHours.${index}.toTime`}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  class="form-select"
                                  placeholder="Select Time"
                                  options={timeArr.map((item) => ({
                                    value: item,
                                    label: item,
                                  }))}
                                  name={`busiHours.${index}.toTime`}
                                  value={field.value}
                                  register={register}
                                  errors={errors}
                                />
                              )}
                            />
                          </div>
                          <div>
                            <Controller
                              name={`busiHours.${index}.toAmPm`}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  class="form-select"
                                  options={["AM", "PM"].map((item) => ({
                                    value: item,
                                    label: item,
                                  }))}
                                  name={`busiHours.${index}.toAmPm`}
                                  value={field.value}
                                  register={register}
                                  errors={errors}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </div>
            </div>
          </div>
          {watch("busiHours").length > 0 ? (
            <button type="submit" class="btn btn-01 btn-width-150 mt-30">
              {lang.save}
            </button>
          ) : (
            <div>Loading...</div>
          )}
        </form>
      </div>
    </>
  )
}

export default Settings
