import React from "react"
import { Routes, Route, Link, Navigate, useNavigate } from "react-router-dom"
import { AuthContext } from "../contexts/AuthContext"

import Layout from "../../layouts/Layout"
import Login from "../../pages/auth/Login"
import Signup from "../../pages/auth/Signup"
import MyRequest from "../../pages/my-request/MyRequest"
import Response from "../../pages/response/Responses"
// import CreateRequest from "../../pages/my-request/CreateRequest"
import CreateRequest from "../../pages/my-request/CreateRequest2"
import EditRequest from "../../pages/my-request/EditRequest"
import Archive from "../../pages/archive/Archive"
import Profile from "../../pages/profile/Profile"
import Keywords from "../../pages/settings/Keywords"
import LiveFeedDistance from "../../pages/settings/LiveFeedDistance"
import Settings from "../../pages/settings/Settings"
import BusinessHours from "../../pages/settings/BusinessHours"
import ChangePassword from "../../pages/settings/ChangePassword"
import Livefeed from "../../pages/livefeed/Livefeed"
import InfoRequested from "../../pages/my-request/InfoRequested"
import MyQuotes from "../../pages/quotes/Quotes"
import FineTune from "../../pages/settings/FineTune2"
import ManageUser from "../../pages/settings/ManageUser"
import ForgotPassword from "../../pages/auth/ForgotPassword"
import AboutUs from "../../pages/settings/AboutUs"
import Feedback from "../../pages/settings/Feedback"
import Report from "../../pages/settings/Report"
import BlockedUser from "../../pages/settings/BlockedUser"
import Subscription from "../../pages/settings/Subscription"

const Routings = () => {
  const { auth, isAuth } = React.useContext(AuthContext)
  const navigate = useNavigate()
  const routeAccess = {
    consumer: (
      <>
        <Route path="/livefeed" element={<Livefeed />} />
        <Route path="/my-request" element={<MyRequest />} />
        <Route path="/my-request/info-requested/:id" element={<InfoRequested />} />
        <Route path="/create-request" element={<CreateRequest />} />
        <Route path="/edit-request/:id" element={<EditRequest />} />
        <Route path="/my-quotes" element={<MyQuotes />} />
        <Route path="/responses/:id" element={<Response />} />
        <Route path="/archive" element={<Archive />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/settings/profile" element={<Profile />} />
        <Route path="/settings/keywords" element={<Keywords />} />
        <Route path="/settings/distance" element={<LiveFeedDistance />} />
        <Route path="/settings/manage-users" element={<ManageUser />} />
        <Route path="/settings/business-hours" element={<BusinessHours />} />
        <Route path="/settings/change-password" element={<ChangePassword />} />
        <Route path="/settings/fine-tune" element={<FineTune />} />
        <Route path="/settings/reports" element={<Report />} />
        <Route path="/settings/feedback" element={<Feedback />} />
        <Route path="/settings/about-us" element={<AboutUs />} />
        <Route path="/settings/subscription" element={<Subscription />} />
        <Route path="/settings/blocked-user" element={<BlockedUser />} />
      </>
    ),
    business: (
      <>
        <Route path="/my-request" element={<MyRequest />} />
        <Route path="/my-request/info-requested/:id" element={<InfoRequested />} />
        <Route path="/create-request" element={<CreateRequest />} />
        <Route path="/edit-request/:id" element={<EditRequest />} />
        <Route path="/my-quotes" element={<MyQuotes />} />
        <Route path="/responses/:id" element={<Response />} />
        <Route path="/archive" element={<Archive />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/settings/profile" element={<Profile />} />
        <Route path="/settings/change-password" element={<ChangePassword />} />
        <Route path="/settings/reports" element={<Report />} />
        <Route path="/settings/feedback" element={<Feedback />} />
        <Route path="/settings/about-us" element={<AboutUs />} />
        <Route path="/settings/subscription" element={<Subscription />} />
        <Route path="/settings/blocked-user" element={<BlockedUser />} />
      </>
    ),
  }

  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route exact path="/login" element={auth?.authToken ? <Navigate to="/my-request" /> : <Login />} />
        <Route path="/signup/:id" element={<Signup />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route element={auth?.authToken ? <Layout /> : <Navigate to="/login"/>}>
          {auth ? routeAccess[auth?.data?.profiletype] : <Route path="/settings" element={<Settings />} />}
        </Route>
        <Route
          path="*"
          element={
            <>
              <h1>Oops!</h1>
              <h3>This page does not exist</h3>
            </>
          }
        />
      </Routes>
    </>
  )
}

export default Routings
