import React, { useState, useEffect, useContext, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { AuthContext } from "../../libs/contexts/AuthContext";
import { SnackbarContext } from "../../libs/contexts/SnackbarContext";
import { handleApiError } from "../../libs/apis/handleApiError";
import api from "../../libs/apis/api";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import logoImg from "../../assets/images/white-logo.png";
import Textfield from "../../components/FormFields/Textfield";
import Select from "../../components/FormFields/Select";
import localforage from "localforage";
import MakeModelSelect from "../../components/MakeModelSelect2";
import { requestPermission } from "../../libs/services/firebase";

function formatPhoneNumber(phoneNumberString) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
}

const Signup = () => {
  let navigate = useNavigate();
  let params = useParams();
  const { handleSetAuth, lang } = useContext(AuthContext);
  const { openSnackbar } = useContext(SnackbarContext);
  const [outOfTexasValidationNeeded, setOutOfTexasValidationNeeded] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addressText, setAddressText] = useState("");

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(
        Yup.object().shape({
          emailaddress: Yup.string().email("Please enter valid email").required("Required"),
          authpassword: Yup.string().min(5, "Minimum 5 characters required").max(21, "Too Long").required("Required"),
          firstname: Yup.string()
              .matches(/^[a-zA-Z]+([\s][a-zA-Z]+)*$/, "Must be Characters only")
              .required("Required"),
          lastname: Yup.string()
              .matches(/^[a-zA-Z]+([\s][a-zA-Z]+)*$/, "Must be Characters only")
              .required("Required"),
          companyname: Yup.string().required("Required"),
          mobile: Yup.string().matches(/^(?:[0-9]{10})?$/, "Not valid Phone number"),
          companyphone: Yup.string()
              .matches(/^[0-9]{10}$/, "Not valid Phone number")
              .required("Required"),
          addressText: Yup.string().required("Required"),
          postcode: params.id == "consumer" ? Yup.string() : Yup.string().required("Required"),
          businessType: params.id == "consumer" ? Yup.string().required("Required") : Yup.string(),
        })
    ),
    defaultValues: {
      emailaddress: "",
      authpassword: "",
      showPass: false,
      firstname: "",
      lastname: "",
      mobile: "",
      companyphone: "",
      companyname: "",
      addressText: "",
      license: "",
      licenseProvideLater: false,
      postcode: "",
      makeModelList: [],
      terms: false,
      step2btn: false,
      profiletype: params.id,
      outOfState: false,
    },
  });

  const notifyForm = useForm({
    resolver: yupResolver(
        Yup.object().shape({
          notifyEmail: Yup.string().email("Please enter valid email").required("Required"),
        })
    ),
    defaultValues: {
      notifyEmail: "",
    },
  });

  const fetchAutocompleteSuggestions = async (input) => {
    if (!input) {
      setSuggestions([]);
      return;
    }

    setLoading(true);
    try {
      const { data } = await api.get(`v1/auth/signup/autocomplete-address?input=${encodeURIComponent(input)}`);
      setSuggestions(data.predictions || []); // Handle autocomplete suggestions
    } catch (error) {
      console.error("Error fetching autocomplete suggestions:", error);
      setSuggestions([]);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectSuggestion = async (suggestion) => {
    const placeId = suggestion.place_id;

    try {
      const { data } = await api.get(`v1/auth/signup/place-details?placeId=${encodeURIComponent(placeId)}`)
      const place = data.result

      setAddressText(place.formatted_address);
      setValue("addressText", place.formatted_address, { shouldValidate: true });
      setValue("state", place.address_components.find((p) => p.types[0] === "administrative_area_level_1")?.long_name);
      setValue("city", place.address_components.find((p) => p.types[0] === "locality")?.long_name);
      setValue("postcode", place.address_components.find((p) => p.types[0] === "postal_code")?.long_name, { shouldValidate: true });
      setValue("longitude", place.geometry.location.lng);
      setValue("latitude", place.geometry.location.lat);

      setSuggestions([]);
    } catch (error) {
      console.error("Error fetching place details:", error);
    }
  };

  const [signupStep, setSignupStep] = useState(1);
  const [signupVal, setSignupVal] = useState();
  const [currMake, setCurrMake] = useState();
  const [makeModel, setMakeModel] = useState([]);
  const [selectedMakeModel, setSelectedMakeModel] = useState([]);
  const [tab, setTab] = useState("import");
  const [isSkip, setIsSkip] = useState(false);
  const [openLicense, setOpenLicense] = useState(false);
  const [openNotRecc, setOpenNotRecc] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [email, setEmail] = useState("");

  // Function to toggle dialog visibility
  const toggleDialog = () => {
    notifyForm.setValue("notifyEmail", "");
    setShowDialog(!showDialog);
  };

  // Storing MakeModel list in localstorage
  const fetchMakeModel = async (authToken) => {
    try {
      let mmo = null;
      await localforage
          .getItem("_makemodel")
          .then(function (value) {
            mmo = value ?? null;
          })
          .catch(function (err) {
            console.log(err);
          });
      if (!mmo) {
        const { data } = await api.get(`make-models`, { headers:{ 'Authorization': `Bearer ${authToken}` }});
        localforage
            .setItem("_makemodel", data)
            .then(function (value) {})
            .catch(function (err) {
              console.log(err);
            });
        setMakeModel(data ?? []);
      } else {
        setMakeModel(mmo);
      }
    } catch (error) {
      handleApiError(error, openSnackbar);
    }
  };

  let OutoftexasEmail = notifyForm.getValues("notifyEmail")

  const handleOutOfTaxes = async (values) => {
    try {
      const { data } = await api.get(`/v1/auth/signup/subscribe-out-of-texas?email=${OutoftexasEmail}`)
      toggleDialog()
      openSnackbar({ open: true, color: "success", message: "Email notified" })
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }
  
  const handleSignup1 = async (values) => {
    try {
      if (values.profiletype == "business" && values.license == "" && !values.licenseProvideLater) {
        setOpenLicense(true)
        setValue("licenseProvideLater", true)
      } else {
        let apiProfiletype = params.id == "business" ? "consumer" : "business"
        const { data } = await api.post(`/v1/auth/signup/signup-step1`, { ...values, profiletype: apiProfiletype })
        setSignupVal({ ...values, authToken: data.authToken })
        fetchMakeModel(data.authToken)
        setSignupStep(2)
        openSnackbar({ open: true, color: "success", message: "OTP Sent" })
      }
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const handleSignup2 = async (values) => {
    try {
      setValue("step2btn", true)
      let deviceToken = await requestPermission()
      api.defaults.headers.common["Authorization"] = ``
      api.defaults.headers.common["deviceToken"] = deviceToken
      let apiProfiletype = params.id == "business" ? "consumer" : "business"
      const { data } = await api.post(`v1/auth/signup/signup-step2`, {
        ...signupVal,
        ...values,
        profiletype: apiProfiletype,
        locationData: {
          address: signupVal.addressText,
          postCode: signupVal.postcode,
          latitude: signupVal.latitude,
          longitude: signupVal.longitude,
        },
        langCode: localStorage.getItem("language") ? (localStorage.getItem("language") === "English" ? "en" : "es") : "en",
        jwttoken: signupVal.authToken,
      })
      handleSetAuth(data)
      setSignupVal({ ...values, authToken2: "Bearer " + data.authToken })
      setValue("step2btn", false)
      if (params.id === "consumer") {
        navigate("/my-request")
      } else {
        setSignupStep(3)
      }
    } catch (error) {
      handleApiError(error, openSnackbar, { toastId: "otp-api-error" })
      setValue("step2btn", false)
    }
  }

  const handleSignup3 = async (values) => {
    try {
      if (values.isSkip) {
        api.defaults.headers.common["Authorization"] = signupVal.authToken2
        const { data } = await api.post(`v1/auth/signup/signup-step3`, {
          preferredMakeModelList: [],
          isSkip: true,
        })
      } else {
        let pfm = selectedMakeModel.filter((item) => item.model.length > 0)
        api.defaults.headers.common["Authorization"] = signupVal.authToken2
        const { data } = await api.post(`v1/auth/signup/signup-step3`, {
          preferredMakeModelList: isSkip ? [] : pfm,
          isSkip: isSkip,
        })
      }
      navigate("/livefeed")
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  // Categorizing Make Model in Domestic and Import types
  const filterMakeModel = (mm) => {
    let selectedMMO = [];
    let importMakeOptions = mm
        .filter((item) => item.american == "N")
        .map((item) => item.make)
        .filter((x, i, a) => a.indexOf(x) == i)
        .sort((a, b) => a.localeCompare(b));
    let importMMOptions = [];
    importMakeOptions.forEach((item) => {
      importMMOptions.push({
        make: item,
        model: mm
            .filter((moItem) => moItem.make == item)
            .map((moItem) => moItem.model)
            .filter((x, i, a) => a.indexOf(x) == i)
            .sort((a, b) => a.localeCompare(b)),
      });
      selectedMMO.push({ make: item, model: [] });
    });
    let domesticMakeOptions = mm
        .filter((item) => item.american == "Y")
        .map((item) => item.make)
        .filter((x, i, a) => a.indexOf(x) == i)
        .sort((a, b) => a.localeCompare(b));
    let domesticMMOptions = [];
    domesticMakeOptions.forEach((item) => {
      domesticMMOptions.push({
        make: item,
        model: mm
            .filter((moItem) => moItem.make == item)
            .map((moItem) => moItem.model)
            .filter((x, i, a) => a.indexOf(x) == i)
            .sort((a, b) => a.localeCompare(b)),
      });
      selectedMMO.push({ make: item, model: [] });
    });

    setSelectedMakeModel(selectedMMO);
    return { import: importMMOptions, domestic: domesticMMOptions, emptyModel: selectedMMO };
  };
  const makeModelOptions = useMemo(() => filterMakeModel(makeModel), [makeModel]);

  return (
      <>
        <section class="full-screen-body">
          <div class="container">
            <div class="logo-main">
              <img src={logoImg} class="img-fluid" />
            </div>
            {signupStep == 1 ? (
                <div class="row justify-content-center">
                  <div class="col-md-9 col-lg-7">
                    <form onSubmit={handleSubmit(handleSignup1)}>
                      <div class="login-box">
                        {params.id == "business" ? (
                            <>
                              <h1>{lang.activate_free_trial}</h1>
                              <p class="login-sub-title">
                                <span class="red-font">*</span> {lang.licensed_salvage_yards_only}
                              </p>
                            </>
                        ) : (
                            <>
                              <h1>{lang.free_sign_up_now}</h1>
                              <p class="login-sub-title">
                                <span class="red-font">*</span> {lang.business_consumers_only}
                              </p>
                            </>
                        )}
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-m-b">
                              <Textfield
                                  type="text"
                                  class="form-control"
                                  placeholder={lang.first_name}
                                  name="firstname"
                                  register={register}
                                  errors={errors}
                              />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-m-b">
                              <Textfield
                                  type="text"
                                  class="form-control"
                                  placeholder={lang.last_name}
                                  name="lastname"
                                  register={register}
                                  errors={errors}
                              />
                            </div>
                          </div>
                        </div>
                        <div class="form-m-b">
                          <Textfield type="text" class="form-control" placeholder={lang.email} name="emailaddress" register={register} errors={errors} />
                        </div>
                        <div class="form-m-b">
                          <Textfield
                              type={watch("showPass") ? "text" : "password"}
                              class="form-control"
                              placeholder={lang.password}
                              name="authpassword"
                              register={register}
                              errors={errors}
                          />
                          {watch("showPass") ? (
                              <i class="fas fa-eye me-2" role="button" onClick={() => setValue("showPass", false)}></i>
                          ) : (
                              <i class="fas fa-eye-slash me-2" role="button" onClick={() => setValue("showPass", true)}></i>
                          )}
                        </div>
                        <div class="form-m-b">
                          <Textfield
                              type="text"
                              class="form-control"
                              placeholder={lang.mobile_number + " (" + lang.optional + ")"}
                              name="mobile"
                              register={register}
                              errors={errors}
                              maxlength="10"
                          />
                        </div>
                        <div class="form-m-b">
                          <Textfield
                              type="text"
                              class="form-control"
                              placeholder={lang.company_name}
                              name="companyname"
                              register={register}
                              errors={errors}
                          />
                        </div>
                        <div class="form-m-b">
                          <Textfield
                              type="text"
                              class="form-control"
                              placeholder={lang.company_number}
                              name="companyphone"
                              register={register}
                              errors={errors}
                              maxlength="10"
                          />
                        </div>
                        <div class="form-m-b">
                          <input
                              type="text"
                              className="form-control"
                              placeholder={lang.address} // + `${params.id == "consumer" ? ` (${lang.optional})` : ``}`
                              value={addressText}
                              onChange={(e) => {
                                setAddressText(e.target.value);
                                fetchAutocompleteSuggestions(e.target.value);
                              }}
                              style={{border: errors["addressText"] ? "2px solid red" : ""}}
                          />
                          <p className="text-danger mx-3 mt-1">{errors["addressText"]?.message}</p>
                        </div>

                        {/* Autocomplete Suggestions */}
                        {loading && <div>Loading...</div>}
                        {suggestions.length > 0 && (
                            <ul className="suggestions-list">
                              {suggestions.map((suggestion) => (
                                  <li key={suggestion.place_id} onClick={() => handleSelectSuggestion(suggestion)}>
                                    {suggestion.description}
                                  </li>
                              ))}
                            </ul>
                        )}

                        {params.id == "business" && (
                            <>
                              <div class="form-m-b form-check mt-2 ms-3">
                                <Controller
                                    name="outOfState"
                                    control={control}
                                    render={({ field }) => (
                                        <input
                                            type="checkbox"
                                            class="form-check-input"
                                            id="exampleCheck1"
                                            style={{ fontSize: "1.3em" }}
                                            checked={watch("outOfState")}
                                            onChange={() => {
                                              field.onChange(!watch("outOfState"));
                                              if (watch("outOfState")) {
                                                toggleDialog();
                                              }
                                            }}
                                        />
                                    )}
                                />

                                <label class="form-check-label text-capitalize pt-1" style={{ fontSize: "1em" }}>
                                  {lang.out_of_texas}
                                </label>
                              </div>
                            </>
                        )}

                        {watch("outOfState") && showDialog && (
                            <div class="modal" role="dialog" style={{ display: showDialog ? "block" : "none" }}>
                              {/* Dialog content */}
                              <div class="modal-dialog modal-md modal-dialog-centered" role="document">
                                <div
                                    class="modal-content"
                                    style={{ boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.9) 0px 1px 3px -1px" }}>
                                  <div class="modal-header" style={{ backgroundColor: "rgba(0, 0, 0, 0.9)" }}>
                                    <h5 class="modal-title" style={{ color: "white" }}>
                                      {lang.out_of_texas}
                                    </h5>
                                    <button
                                        type="button"
                                        class="close"
                                        style={{ border: "none", color: "white", backgroundColor: "transparent", fontSize: "25px" }}
                                        onClick={() => {
                                          setValue("outOfState", false);
                                          toggleDialog();
                                        }}>
                                      <span>&times;</span>
                                    </button>
                                  </div>
                                  <div class="modal-body" style={{ color: "black" }}>
                                    {/* Dialog body content */}
                                    <b style={{ fontSize: "16px" }}>{lang.We_currently_do_not_provide_services_outside_of_Texas}</b> <br />
                                    Would you like us to notify you when we expand our services to other states?
                                  </div>
                                  <div className="d-flex justify-content-between mx-3 mt-3 mb-1">
                                    <Textfield
                                        type="text"
                                        class="form-control"
                                        placeholder="Email"
                                        name="notifyEmail"
                                        register={notifyForm.register}
                                        errors={notifyForm.formState.errors}
                                        onChange={(e) => {
                                          notifyForm.setValue("notifyEmail", e.target.value);
                                        }}
                                    />

                                    <button
                                        style={{ backgroundColor: "#d41404" }}
                                        type="button"
                                        class="btn btn-secondary"
                                        onClick={() => {
                                          setValue("outOfState", true);
                                          notifyForm.handleSubmit(handleOutOfTaxes)();
                                        }}
                                        disabled={!notifyForm.watch("notifyEmail").trim()}>
                                      Notify
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                        )}
                        <div class="form-m-b">
                          {params.id == "consumer" ? (
                              <Select
                                  class="form-select"
                                  name="businessType"
                                  register={register}
                                  errors={errors}
                                  placeholder={lang.business_type}
                                  options={["Mechanic Shops", "Collision Shops", "Dealerships", "Insurance Adjusters", "Individual"].map((item) => ({
                                    value: item,
                                    label: item,
                                  }))}
                              />
                          ) : (
                              <>
                                <div class="form-m-b">
                                  <Textfield
                                      type="text"
                                      class="form-control"
                                      placeholder={lang.retype_zip}
                                      maxLength="5"
                                      name="postcode"
                                      register={register}
                                      errors={errors}
                                  />
                                </div>
                                <div className="d-flex">
                                  <div className="w-50">
                                    <Textfield
                                        type="text"
                                        class="form-control"
                                        placeholder={lang.license}
                                        name="license"
                                        register={register}
                                        errors={errors}
                                    />
                                  </div>
                                  <div class="form-m-b form-check mt-2 ms-5">
                                    <Controller
                                        name="licenseProvideLater"
                                        control={control}
                                        render={({ field }) => (
                                            <input
                                                type="checkbox"
                                                class="form-check-input"
                                                id="exampleCheck1"
                                                style={{ fontSize: "1.3em" }}
                                                checked={!watch("license")}
                                            />
                                        )}
                                    />
                                    <label class="form-check-label text-capitalize pt-1" style={{ fontSize: "1em" }}>
                                      {lang.provide_later}
                                    </label>
                                  </div>
                                </div>
                              </>
                          )}
                        </div>

                        <div class="form-m-b form-check">
                          <Controller
                              name="terms"
                              control={control}
                              render={({ field }) => (
                                  <input
                                      type="checkbox"
                                      class="form-check-input"
                                      id="exampleCheck1"
                                      checked={field.value}
                                      onChange={(e) => {
                                        setValue("terms", e.target.checked);
                                      }}
                                  />
                              )}
                          />
                          <label class="form-check-label fs-6">
                            {lang.agree_t_c_1}
                            <label
                                role="button"
                                style={{ textDecoration: "underline" }}
                                onClick={() => window.open("https://liveyards.com/terms-conditions", "_blank")}>
                              {lang.agree_t_c_2}
                            </label>{" "}
                            {lang.agree_t_c_3}
                            <label
                                role="button"
                                style={{ textDecoration: "underline" }}
                                onClick={() => window.open("https://liveyards.com/privacy-policy", "_blank")}>
                              {lang.agree_t_c_4}
                            </label>
                          </label>
                        </div>
                        <div class="d-grid">
                          <button
                              type="submit"
                              class="btn btn-01"
                              disabled={!watch("terms") || isSubmitting || watch("outOfState")}
                              style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            {isSubmitting && (
                                <div style={{ height: "1.5em", width: "1.5em", marginRight: "30px" }}>
                                  {" "}
                                  <div className="loader"></div>
                                </div>
                            )}
                            {lang.sign_up}
                          </button>
                        </div>
                      </div>

                      <div class="sign-up-link-box">
                        <p class="m-0">
                          {lang.already_have_an_account}{" "}
                          <a class="cursor-pointer" onClick={() => navigate("/login")}>
                            {lang.login}
                          </a>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
            ) : signupStep == 2 ? (
                <div class="row justify-content-center">
                  <div class="col-md-9 col-lg-5">
                    <form onSubmit={handleSubmit(handleSignup2)}>
                      <div class="login-box">
                        <h1>{lang.verification_code}</h1>
                        <p class="login-sub-title h6">
                          <span class="red-font">*</span> {lang.enter_otp} {getValues("emailaddress")}
                        </p>
                        <div class="row">
                          <div class="form-m-b" style={{ display: "flex", justifyContent: "center" }}>
                            <input
                                type="text"
                                class="form-control"
                                placeholder=""
                                style={{ width: "5em", textAlign: "center", fontWeight: "bold", fontSize: "2em" }}
                                maxLength="4"
                                {...register("verificationcode")}
                            />
                          </div>
                          <div class="d-grid">
                            <button
                                type="submit"
                                class="btn btn-01"
                                disabled={watch("step2btn") || isSubmitting}
                                style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                              {isSubmitting && (
                                  <div style={{ height: "1.5em", width: "1.5em", marginRight: "30px" }}>
                                    {" "}
                                    <div className="loader"></div>
                                  </div>
                              )}

                              {lang.verify}
                            </button>
                          </div>
                          <p class="login-sub-title mt-4 h6">
                            <span class="red-font">*</span> {lang.didnt_get_code}{" "}
                            <a
                                role="button"
                                style={{ color: "#f9ad4a" }}
                                onClick={() => {
                                  if (!isSubmitting) {
                                    handleSubmit(handleSignup1)()
                                    setValue("verificationcode", "")
                                  }
                                }}>
                              {lang.resent_otp}
                            </a>
                          </p>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
            ) : (
                <>
                  <div class="row justify-content-center">
                    <div class="col-md-9 col-lg-7">
                      <form onSubmit={handleSubmit(handleSignup3)}>
                        <div class="login-box">
                          <div class="row">
                            <div class="col-xs-12">
                              <div class="row">
                                <div class="col-md-6">
                                  <div
                                      onClick={() => {
                                        setTab("import");
                                      }}
                                      className={`btn ${tab == "import" ? "btn-01" : "btn-02"} w-100 pe-auto`}>
                                    {lang.import}
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div
                                      onClick={() => {
                                        setTab("domestic");
                                      }}
                                      className={`btn ${tab == "domestic" ? "btn-01" : "btn-02"} w-100 pe-auto`}>
                                    {lang.domestic}
                                  </div>
                                </div>
                                <div class="text-center mt-2 mb-3 fs-6">
                                  <span class="red-font">*</span> {lang.choose_makes_models_live_feed}
                                </div>
                                <div class="text-center mb-4">
                              <span>
                                <input
                                    checked={isSkip}
                                    onChange={() => {
                                      if (!isSkip) {
                                        setIsSkip(true);
                                        setSelectedMakeModel([...makeModelOptions.import, ...makeModelOptions.domestic]);
                                      } else {
                                        setIsSkip(false);
                                        setSelectedMakeModel(makeModelOptions.emptyModel);
                                      }
                                    }}
                                    type="checkbox"
                                    class="form-check-input mt-1 fs-6"
                                />
                                <label>
                                  <div class="h5 px-3">
                                    <b>{lang.select_all}</b>
                                  </div>
                                </label>
                              </span>
                                </div>
                                <div className="shadow-sm" style={{ height: "60vh", overflowY: "scroll" }}>
                                  <MakeModelSelect
                                      makeModelOptions={makeModelOptions[tab]}
                                      selectedMM={selectedMakeModel}
                                      setSelectedMM={(value) => {
                                        setSelectedMakeModel(value);
                                        setIsSkip(false);
                                      }}
                                  />
                                </div>
                                <div class="d-grid mt-3">
                                  {selectedMakeModel.find((item) => item.model.length > 0) || isSkip ? (
                                      <>
                                        <div class="d-grid mt-3">
                                          <button
                                              type="submit"
                                              class="btn btn-01"
                                              disabled={isSubmitting}
                                              style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            {isSubmitting && (
                                                <div style={{ height: "1.5em", width: "1.5em", marginRight: "30px" }}>
                                                  {" "}
                                                  <div className="loader"></div>
                                                </div>
                                            )}
                                            {lang.save_continue}
                                          </button>
                                        </div>
                                        <div class="sign-up-link-box">
                                          <p class="m-0">
                                            {lang.reset_listing + " "}
                                            <a
                                                role="button"
                                                onClick={() => {
                                                  setSelectedMakeModel(makeModelOptions.emptyModel);
                                                  setIsSkip(false);
                                                }}>
                                              {lang.reset}
                                            </a>
                                          </p>
                                        </div>
                                      </>
                                  ) : (
                                      <div class="sign-up-link-box mt-4">
                                        <p class="m-0">
                                          {lang.setup_later + " "}
                                          <a
                                              role="button"
                                              onClick={() => {
                                                setOpenNotRecc(true);
                                                setIsSkip(true);
                                              }}>
                                            {lang.skip_to_livefeed}
                                          </a>
                                        </p>
                                      </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </>
            )}
          </div>
          <div class="login-footer">
            <p>
              {lang.have_questions}
              <a style={{ color: "white", textDecoration: "none" }} href="https://www.liveyards.com">
                {" "}
                {lang.contact_us}
              </a>
            </p>
            <p>
              © {new Date().getFullYear()} Liveyards. {lang.all_rights_reserved}. {lang.powered_by}.
            </p>
          </div>
        </section>

        {/* <!-- License Modal --> */}
        <div class={`modal`} role="dialog" style={{ display: openLicense ? "block" : "none" }}>
          <div
              style={{
                position: "absolute",
                height: "100%",
                width: "100%",
                backgroundColor: "#000",
                opacity: "0.5",
              }}></div>
          <div class="modal-dialog modal-md modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header d-flex justify-content-spacebetween">
                <div className="mx-2"></div>
                <h4 class="text-dark font-weight-bold">{lang.notice}</h4>
                <button
                    type="button"
                    class="close"
                    style={{ border: "0", fontSize: "1.5em" }}
                    onClick={() => {
                      setOpenLicense(false);
                      setValue("licenseProvideLater", false);
                    }}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h6 class="text-center text-dark mx-4 my-3">{lang.account_locked_7days}</h6>
              </div>
              <div class="modal-footer d-flex justify-content-center">
                <button
                    type="button"
                    class="btn btn-danger"
                    onClick={() => {
                      setOpenLicense(false);
                      handleSubmit(handleSignup1)();
                    }}>
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Fine Tune Modal --> */}
        <div class={`modal`} role="dialog" style={{ display: openNotRecc ? "block" : "none" }}>
          <div
              style={{
                position: "absolute",
                height: "100%",
                width: "100%",
                backgroundColor: "#000",
                opacity: "0.5",
              }}></div>
          <div class="modal-dialog modal-md modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header d-flex justify-content-center">
                <h4 class="text-dark font-weight-bold">{lang.not_recommended}</h4>
              </div>
              <div class="modal-body">
                <h6 class="text-center text-dark mx-4 my-3">{lang.by_not_fine_tuning_your_live_feed}</h6>
              </div>
              <div class="modal-footer d-flex justify-content-center">
                <div>
                  <button type="button" class="btn btn-secondary me-3" onClick={() => setOpenNotRecc(false)}>
                    {lang.back}
                  </button>
                </div>
                <button
                    type="button"
                    class="btn btn-danger"
                    onClick={() => {
                      setOpenNotRecc(false);
                      handleSubmit(handleSignup3)();
                    }}>
                  {lang.proceed}
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
  );
};

export default Signup;
