import React, { useState, useEffect, useContext } from "react"
import { useParams, useNavigate, useOutletContext } from "react-router-dom"
import { AuthContext } from "../../libs/contexts/AuthContext"
import { SnackbarContext } from "../../libs/contexts/SnackbarContext"
import { handleApiError } from "../../libs/apis/handleApiError"
import api from "../../libs/apis/api"
import Header from "../../components/Header"
import DeleteDialog from "../../components/DeleteDialog"
import Dialog from "../../components/Dialog"
import Tooltip from "../../components/Tooltip"
import moment from "moment"
import noImg from "../../assets/images/noImg.png"
import profileLarge from "../../assets/images/profileLarge.png"
import fromNowLang from "../../utils/fromNowLang"

const Archive = () => {
  let navigate = useNavigate()
  let params = useParams()
  const { handleSetAuth, lang } = useContext(AuthContext)
  const { openSnackbar } = useContext(SnackbarContext)
  const { checkValidity } = useOutletContext()
  const [requests, setRequests] = useState(null)
  const [deleteDialog, setDeleteDialog] = useState({ open: false, message: "" })
  const [archiveDialog, setArchiveDialog] = useState({ open: false, message: "" })
  const [imgDialog, setImgDialog] = useState()
  const [lessMoreId, setLessMoreId] = useState(null)

  const fetchData = async (values) => {
    try {
      // await checkValidity()
      const { data } = await api.get(`requests/archived?value=true`)
      setRequests(data)
    } catch (error) {
      handleApiError(error, openSnackbar)
      setRequests([])
    }
  }

  const handleDelete = async (id) => {
    try {
      const { data } = await api.delete(`requests/${id}`)
      setDeleteDialog({ ...deleteDialog, open: false })
      fetchData()
      openSnackbar({ color: "success", message: "Deleted Successfully" })
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }
  const handleUnarchive = async (id) => {
    try {
      const { data } = await api.put(`requests/${id}/archived`, { isArchived: false })
      setArchiveDialog({ ...archiveDialog, open: false })
      fetchData()
      openSnackbar({ color: "success", message: "Unarchived Successfully" })
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const translate = (tid) => {
    let t = []
    requests.forEach((item) => {
      if (item.id == tid) {
        t.push({ ...item, translate: !item.translate })
      } else {
        t.push({ ...item, translate: false })
      }
    })
    setRequests(t)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <Header title={lang.archive} />
      <Dialog dialog={archiveDialog} />
      <DeleteDialog deleteDialog={deleteDialog} />
      <div class="wrapper">
        {/* <div class='row'>
          <div class='col-md-4'>
            <div class='row align-items-center'>
              <label class='col-sm-2 col-form-label'>
                Sort by
              </label>
              <div class='col-sm-10'>
                <select class='form-select'>
                  <option>Date: Newest on Top</option>
                  <option>0</option>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                </select>
              </div>
            </div>
          </div>
        </div> */}
        <div class="table-main-div">
          <ul class="custome-table-head">
            <li>
              {lang.year}/{lang.make}/{lang.model}
            </li>
            <li>{lang.requests}</li>
            <li>{lang.requested_on}</li>
            <li>{lang.responses}</li>
            <li>{lang.images}</li>
            <li></li>
          </ul>
          {!requests ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ height: "2.5em", width: "2.5em", margin: "2em 0 2em 0" }}>
                <div className="loader"></div>
              </div>
            </div>
          ) : !requests?.length ? (
            <div className="custome-tr-main" style={{ justifyContent: "center", padding: "2em 0", color: "gray", fontWeight: "bold" }}>
              {lang.no_archived_request}
            </div>
          ) : (
            requests?.map((item) => (
              <div key={item.id} class="custome-tr-main">
                <div class="icon-img-main">
                  <div>
                    <img
                      src={item.business?.profileImage ? item.business?.profileImage?.url : profileLarge}
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "50%",
                        // display: item.business.profileImage?.url ? "flex":"none",
                        // backgroundColor: "lightgrey",
                      }}
                    />
                  </div>
                </div>
                <div class="td-1">
                  <small class="text-black-50">{item.business?.companyname}</small>
                  <div class="h5 m-0">
                    {item.year} {item.make}, {item.model}
                  </div>
                  <div class="text-black-50">{item.trim}</div>
                </div>
                <div class="td-2 d-flex flex-column align-items-start" style={{ minHeight: "4em" }}>
                  <div class="mb-auto mt-2">
                    {" "}
                    <div className="row">
                      {lessMoreId == item.id ? (
                        <div role="button" className="col-11" onClick={() => setLessMoreId(null)}>
                          {item.translate ? item.spanishDesc : item.desc}
                        </div>
                      ) : (
                        <div role="button" className="col-11 show-more" onClick={() => setLessMoreId(item.id)}>
                          {item.translate ? item.spanishDesc : item.desc}
                        </div>
                      )}
                    </div>
                  </div>
                  <div class="d-inline-block badge text-bg-warning text-white ml-5 text-wrap" role="button" onClick={() => translate(item.id)}>
                    {lang.translate}
                  </div>
                </div>
                <div class="td-3">{fromNowLang(moment(item.createdAt).fromNow(), lang)}</div>
                <div class="td-4">
                  <i class="fas fa-comment-alt"></i> {item.responseCount}
                </div>
                <div class="td-5">
                  <div id="lightgallery">
                    <span style={{ cursor: "pointer" }} onClick={() => setImgDialog(item.images?.[0].url)}>
                      <img
                        src={item.images?.[0].url ?? noImg}
                        class="img-fluid shadow object-fit-contain border rounded"
                        style={{ width: "5em", height: "5em" }}
                      />
                    </span>
                  </div>
                </div>
                <div class="td-6">
                  <div class=" dot-link d-flex h6">
                    <Tooltip
                      text={lang.unarchive}
                      data={
                        <i
                          class="fas fa-archive mx-2"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            setArchiveDialog({
                              open: lang.unarchive,
                              message: (
                                <>
                                  {lang.unarchive_request} <br />
                                  <b>
                                    {item.year} {item.make}, {item.model}
                                  </b>
                                  ?
                                </>
                              ),
                              handleConfirm: () => handleUnarchive(item.id),
                              handleCancel: () => setArchiveDialog({ ...archiveDialog, open: false }),
                            })
                          }></i>
                      }
                    />
                    <Tooltip
                      text={lang.delete}
                      data={
                        <i
                          class="fas fa-trash mx-2"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            setDeleteDialog({
                              open: true,
                              message: (
                                <>
                                  {lang.delete_request}{" "}
                                  <b>
                                    {item.year} {item.make}, {item.model}
                                  </b>
                                  ?
                                </>
                              ),
                              handleConfirm: () => handleDelete(item.id),
                              handleCancel: () => setDeleteDialog({ ...deleteDialog, open: false }),
                            })
                          }></i>
                      }
                    />
                  </div>
                </div>
              </div>
            ))
          )}
          {/* -- Pagination Button Example -- 
           <nav aria-label="Page navigation example">
                        <ul class="pagination justify-content-center">
                            <li class="page-item disabled">
                                <a class="page-link">Previous</a>
                            </li>
                            <li class="page-item"><a class="page-link" href="#">1</a></li>
                            <li class="page-item"><a class="page-link" href="#">2</a></li>
                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                            <li class="page-item">
                                <a class="page-link" href="#">Next</a>
                            </li>
                        </ul>
                    </nav> */}
        </div>
      </div>
    </>
  )
}

export default Archive
