import React, { useState, useEffect, useContext } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { AuthContext } from "../../libs/contexts/AuthContext"
import * as Yup from "yup"
import { useForm } from "react-hook-form"

const validationSchema = Yup.object().shape({
  firstname: Yup.string().required("Required*"),
  emailaddress: Yup.string().email("Please enter valid email").required("Email is Required!"),
  // password: Yup.string()
  //   .min(5, 'Minimum 5 characters required!!')
  //   .max(50, 'Too Long!')
  //   .required('Password is Required')
})

const ProfileInfoDialog = ({ dialog, handleClose }) => {
  let navigate = useNavigate()
  let params = useParams()
  const { auth, lang } = useContext(AuthContext)
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    // resolver: yupResolver(validationSchema),
    defaultValues: {
      review: "",
      stars: 4,
    },
  })
  const handleStars = (active) => {
    let stars = []
    let arr = [1, 2, 3, 4, 5]
    arr.forEach((item) => {
      if (item <= active) {
        stars.push(
          <i
            role="button"
            class="fas fa-star mx-2 fs-6"
            style={{ color: "#ffc800" }}
            onClick={() => {
              //   setValue("stars", item)
            }}></i>
        )
      } else {
        stars.push(
          <i
            role="button"
            class="fas fa-star mx-2 fs-6"
            style={{ color: "lightgrey" }}
            onClick={() => {
              //   setValue("stars", item)
            }}></i>
        )
      }
    })
    return stars
  }

  if (!dialog.open) return <></>
  return (
    <>
      <div class="modal" role="dialog" style={{ display: "block" }}>
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            backgroundColor: "#000",
            opacity: "0.5",
          }}></div>
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title text-dark" id="exampleModalLongTitle">
                {lang.profile}
              </h4>
              <button type="button" class="close" style={{ border: "0" }} onClick={handleClose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form onSubmit={handleSubmit(dialog.handleConfirm)}>
              <div class="modal-body mb-4 ms-4" style={{ overflowY: "auto", height: "80vh" }}>
                <div className="d-flex">
                  <div class="user-profile-img-main">
                    <div
                      className="left-img border-0"
                      style={{
                        background: dialog.data?.bpd.profileImage ? `url('${dialog.data?.bpd.profileImage?.url}')` : "lightgrey",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                      }}></div>
                  </div>
                  <div className="d-flex flex-column align-items-center">
                    <div className="fw-bold text-black fs-4 my-1">
                      {dialog.data?.bpd.firstname} {dialog.data?.bpd.lastname}
                    </div>
                    <div className="bg-color-ly border rounded-3 px-3 py-1" style={{ borderRadius: "1em" }}>
                      {handleStars(
                        Math.round(
                          dialog.data?.bpd.rateAndReviews?.reduce((sum, item) => sum + item.rate, 0) / dialog.data?.bpd.rateAndReviews?.length
                        )
                      )}
                    </div>
                  </div>
                </div>
                <div class="form-m-b-20">
                  <div className="fs-5 fw-bold text-black">{lang.about_us}</div>
                  <label class="form-label mt-1 fs-5">{dialog.data?.bpd.aboutustext ?? <span className="fs-5 fw-bold ms-5">_</span>}</label>
                </div>
                <div class="form-m-b-20">
                  <div className="fs-5 fw-bold text-black">{lang.contact_details}</div>
                  <div className="form-label mt-3 ">
                    <i className="fas fa-map-marker-alt mx-2 fs-5"></i>
                    {lang.address}
                    <div className="fs-5 fw-bold ms-3">{dialog.data?.bpd.addressText}</div>
                  </div>
                  <div className="form-label mt-3 ">
                    <i className="fas fa-envelope mx-2 fs-5"></i>
                    {lang.email}
                    <div className="fs-5 fw-bold ms-3">{dialog.data?.bpd.emailaddress}</div>
                  </div>
                  <div className="form-label mt-3 ">
                    <i className="fas fa-industry mx-2 fs-5"></i>
                    {lang.company_name}
                    <div className="fs-5 fw-bold ms-3">{dialog.data?.bpd.companyname}</div>
                  </div>
                  <div className="form-label mt-3 ">
                    <i className="fas fa-phone-alt mx-2 fs-5"></i>
                    {lang.phone_number}
                    <div className="fs-5 fw-bold ms-3">{dialog.data?.bpd.companyphone}</div>
                  </div>
                </div>
                <div class="form-m-b-20">
                  <div className="fs-5 fw-bold text-black mt-5">{lang.reviews}</div>
                  {dialog.data?.bpd.rateAndReviews?.map((item, index) => (
                    <div key={index} className="form-label mt-1 px-3 py-2 shadow-sm rounded">
                      <div className="text-info">{item.name}</div>
                      <div className="fs-5 fw-bold">{item.review}</div>
                      <div>{handleStars(item.rate)}</div>
                    </div>
                  ))}
                </div>
              </div>
              {/* <div class="modal-footer mt-3 pt-4">
                <button
                  type="button"
                  class="btn btn-secondary"
                  // data-dismiss='modal'
                  onClick={dialog.handleCancel}>
                  {lang.cancel}
                </button>
                <button
                  type="submit"
                  class="btn btn-01 py-2"
                  // onClick={handleLanguageSubmit}
                  disabled={isSubmitting}>
                  {lang.complete_deal}
                </button>
              </div> */}
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProfileInfoDialog
