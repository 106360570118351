import React from 'react'

const Switch = ({ name, success, register, ...rest }) => {
  return (
    <>
      <label class='switch'>
        <input type='checkbox' {...rest} />
        <span
          class={`${success ? 'slider slider-success' : 'slider'} round`}
        ></span>
      </label>
    </>
  )
}

export default Switch
