import axios from "axios"

const api = axios.create({ baseURL: process.env.REACT_APP_API_URL })

api.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    if (
      window.location.pathname == "/login" ||
      window.location.pathname.includes("/signup") ||
      window.location.pathname.includes("/forgot-password")
    ) {
    } else {
      config.headers["Authorization"] = localStorage.getItem("authToken")
    }
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.status == 401) {
      localStorage.clear()
      window.location.href = "/"
    }
    if (error.response.status == 500) {
      localStorage.clear()
    }
    return Promise.reject(error)
  }
)

export const toFormData = (object) => {
  const formData = new FormData()
  Object.keys(object).forEach((key) => {
    formData.append(key, object[key])
  })
  return formData
}

export default api
