import React, { useState, useEffect, useContext } from "react"
import { useParams, useNavigate, useOutletContext } from "react-router-dom"
import { AuthContext } from "../../libs/contexts/AuthContext"
import { SnackbarContext } from "../../libs/contexts/SnackbarContext"
import { handleApiError } from "../../libs/apis/handleApiError"
import api from "../../libs/apis/api"
import * as Yup from "yup"
import bulkImg from "../../assets/images/bulk-images.png"
import Header from "../../components/Header"
import Tooltip from "../../components/Tooltip"
import DeleteDialog from "../../components/DeleteDialog"
import Dialog from "../../components/Dialog"
import InfoAction from "../../components/InfoAction"
import InfoDialog from "../../components/InfoDialog"
import PriceDialog from "../../components/PriceDialog"
import moment from "moment"
import CompleteRequestDialog from "./CompleteRequestDialog"
import noImg from "../../assets/images/noImg.png"
import Carousal from "../../components/Carousal"
import fromNowLang from "../../utils/fromNowLang"

const MyRequest = () => {
  let navigate = useNavigate()
  let params = useParams()
  const { auth, lang } = useContext(AuthContext)
  const { openSnackbar } = useContext(SnackbarContext)
  const { requests, fetchMyRequests, checkValidity, notificationRefresh } = useOutletContext()
  // const [requests, setRequests] = useState([])
  const [deleteDialog, setDeleteDialog] = useState({ open: false, message: "" })
  const [archiveDialog, setArchiveDialog] = useState({ open: false, message: "" })
  const [infoDialog, setInfoDialog] = useState({ open: false })
  const [priceDialog, setPriceDialog] = useState({ open: false })
  const [imgDialog, setImgDialog] = useState()
  const [reqCompDialog, setReqCompDialog] = useState({ open: false })
  const [translateId, setTranslateId] = useState("")
  const [lessMoreId, setLessMoreId] = useState(null)

  const fetchData = async (values) => {
    try {
      // const { data } = await api.get(`requests`)
      // setRequests(data.filter((item) => item.year && !item.archived))
      const data = await fetchMyRequests()
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const handleDelete = async (id) => {
    try {
      const { data } = await api.delete(`requests/${id}`)
      setDeleteDialog({ ...deleteDialog, open: false })
      fetchData()
      openSnackbar({ color: "success", message: "Deleted Successfully" })
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const handleArchive = async (id) => {
    try {
      const { data } = await api.put(`requests/${id}/archived`, {
        isArchived: true,
      })
      setArchiveDialog({ ...archiveDialog, open: false })
      fetchData()
      openSnackbar({ color: "success", message: "Archived Successfully" })
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const handleReopenDeal = async (id) => {
    try {
      const { data } = await api.put(`requests/reopenDeal`, {
        liveFeedId: id,
        flag: "open",
      })
      fetchMyRequests("refresh")
      navigate(`/responses/${id}`)
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const handleRequestComplete = async (id, values) => {
    try {
      const { data } = await api.put(`requests/completeDeal`, {
        liveFeedId: id,
        completedByUser: auth.data.id,
        flag: "completed",
      })
      const rr = await api.post(`v1/salvage/rateAndReview`, {
        businessId: data.closeByUser,
        rate: values.stars,
        review: values.review,
      })
      fetchData()
      setReqCompDialog((prev) => ({ ...prev, open: false }))
      openSnackbar({ color: "success", message: "Request Complete Successfully" })
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const handleInfoActionColor = (inl) => {
    if (inl == null) return null
    let flag = inl.every((item) => item.requestRead)
    // inl.forEach((item) => {
    //   flag = item.requestRead ? true : flag
    // })
    return flag ? "#58c11c" : "#d41404"
  }

  const fetchInvalidity = async (values) => {
    try {
      await checkValidity()
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  // useEffect(() => {
  //   // fetchInvalidity()
  //   notificationRefresh(() => fetchData())
  // }, [])

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     fetchData()
  //   }, 20000)
  //   return () => clearInterval(interval)
  // }, [])

  useEffect(() => {
    fetchData()
  }, [])

  // const translate = (tid) => {
  //   let t = []
  //   requests.forEach((item) => {
  //     if (item.id == tid) {
  //       t.push({ ...item, translate: !item.translate })
  //     } else {
  //       t.push({ ...item, translate: false })
  //     }
  //   })
  //   setRequests(t)
  // }

  return (
    <>
      <Header title={lang.my_requests} />
      <Dialog dialog={archiveDialog} />
      <DeleteDialog deleteDialog={deleteDialog} />
      <InfoDialog dialog={infoDialog} />
      <PriceDialog dialog={priceDialog} />
      <CompleteRequestDialog dialog={reqCompDialog} />
      <div class="wrapper">
        <div class="table-main-div">
          <ul class="custome-table-head">
            <li>
              {lang.year}/{lang.make}/{lang.model}
            </li>
            <li>{lang.requests}</li>
            <li>{lang.requested_on}</li>
            <li>{lang.images}</li>
            <li>{lang.responses}</li>
            <li></li>
          </ul>
          {!requests ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ height: "2.5em", width: "2.5em", margin: "2em 0 2em 0" }}>
                <div className="loader"></div>
              </div>
            </div>
          ) : !requests?.length ? (
            <div className="custome-tr-main" style={{ justifyContent: "center", padding: "2em 0", color: "gray", fontWeight: "bold" }}>
              {lang.empty_requests}
            </div>
          ) : (
            requests?.map((item) => (
              <div key={item.id} class="custome-tr-main">
                <div class="icon-img-main">
                  {/* <img src={item.business.profileImage?.url} /> */}
                  <div
                    {...(item.business.profileImage?.url && {
                      style: {
                        position: "relative",
                      },
                    })}>
                    <img
                      src={item.business.profileImage?.url}
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "50%",
                        display: !item.business.profileImage?.url && "none",
                        backgroundColor: "lightgrey",
                      }}
                    />
                    {item.responseCount ? (
                      <span class="position-absolute bottom-0 start-100 translate-middle-x badge rounded-pill p-1 px-2  fs-6 bg-danger border border-light rounded-circle">
                        {item.responseCount}
                        <span class="visually-hidden"></span>
                      </span>
                    ) : null}
                  </div>
                </div>
                <div class="td-1">
                  <small class="text-black-50">{item.business?.companyname}</small>
                  <div class="h5 m-0">
                    {item.year} {item.make}, {item.model}
                  </div>
                  <div class="text-black-50">{item.trim}</div>
                </div>
                <div class="td-2 d-flex flex-column align-items-start" style={{ minHeight: "4em" }}>
                  {/* <div class="mb-auto mt-2">{item.translate ? item.spanishDesc : item.desc}</div> */}
                  {item.id == translateId ? (
                    <div
                      role="button"
                      className={`mb-auto mt-2 ${lessMoreId != item.id && "show-more"}`}
                      onClick={() => setLessMoreId((prev) => (prev ? null : item.id))}>
                      {item.spanishDesc}
                    </div>
                  ) : (
                    <div
                      role="button"
                      className={`mb-auto mt-2 ${lessMoreId != item.id && "show-more"}`}
                      onClick={() => setLessMoreId((prev) => (prev ? null : item.id))}>
                      {item.desc}
                    </div>
                  )}
                  <div
                    class="d-inline-block badge text-bg-warning text-white ml-5 text-wrap"
                    role="button"
                    onClick={() => setTranslateId(item.id == translateId ? "" : item.id)}>
                    {lang.translate}
                  </div>
                </div>
                <div class="td-3">{fromNowLang(moment(item.createdAt).fromNow(), lang)}</div>
                <div class="td-4">
                  <div id="lightgallery">
                    <span style={{ cursor: "pointer" }} onClick={() => setImgDialog(item.images)}>
                      <img
                        src={item.images?.[0].url ?? noImg}
                        class="img-fluid shadow object-fit-contain border rounded"
                        style={{ width: "5em", height: "5em" }}
                      />
                    </span>
                  </div>
                </div>
                <div class="td-5">
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div className="text-center">
                      <button
                        type="button"
                        className="btn-03 p-1 px-4"
                        onClick={() => (item.totalResponseCount ? navigate(`/responses/${item.id}`) : null)}>
                        {item.totalResponseCount}
                      </button>
                    </div>
                    {item.flag == "close" ? (
                      <div>
                        <button
                          type="button"
                          className="btn-03 p-1 w-100"
                          onClick={() =>
                            setArchiveDialog({
                              open: lang.re_open_deal,
                              message: lang.re_open_deal_message,
                              handleConfirm: () => handleReopenDeal(item.id),
                              handleCancel: () => setArchiveDialog({ ...archiveDialog, open: false }),
                            })
                          }>
                          {lang.re_open_deal}
                        </button>
                        <button
                          type="button"
                          className="btn-03 p-1 px-2 mt-2 "
                          onClick={() =>
                            setReqCompDialog({
                              open: true,
                              handleConfirm: (val) => handleRequestComplete(item.id, val),
                              handleCancel: () => setReqCompDialog((prev) => ({ ...prev, open: false })),
                            })
                          }>
                          {lang.complete_deal}
                        </button>
                      </div>
                    ) : item.flag == "completed" ? (
                      <div className="text-color-ly">
                        <b>{lang.completed_deal}</b>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div class="td-6">
                  <div class=" dot-link d-flex h6">
                    <InfoAction
                      type={item.infoNeededList ? "info-requested" : ""}
                      flagColor={handleInfoActionColor(item.infoNeededList)}
                      handleClick={() => navigate(`info-requested/${item.id}`)}
                    />
                    <Tooltip
                      text={lang.archive}
                      data={
                        <i
                          class="fas fa-archive mx-2"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            setArchiveDialog({
                              open: lang.archive,
                              message: (
                                <>
                                  {lang.archive_request}{" "}
                                  <b>
                                    {item.year} {item.make}, {item.model}
                                  </b>
                                  ?
                                </>
                              ),
                              handleConfirm: () => handleArchive(item.id),
                              handleCancel: () => setArchiveDialog({ ...archiveDialog, open: false }),
                            })
                          }></i>
                      }
                    />
                    <Tooltip
                      text={lang.delete}
                      data={
                        <i
                          class="fas fa-trash mx-2"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            setDeleteDialog({
                              open: true,
                              message: (
                                <>
                                  {lang.delete_request}{" "}
                                  <b>
                                    {item.year} {item.make}, {item.model}
                                  </b>
                                  ?
                                </>
                              ),
                              handleConfirm: () => handleDelete(item.id),
                              handleCancel: () => setDeleteDialog({ ...deleteDialog, open: false }),
                            })
                          }></i>
                      }
                    />
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      <div class="modal" role="dialog" style={{ display: imgDialog ? "block" : "none" }}>
        <div style={{ position: "absolute", height: "100%", width: "100%", backgroundColor: "#000", opacity: "0.5" }}></div>
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title text-dark" id="exampleModalLongTitle">
                Image View
              </h4>
              <button type="button" style={{ border: "0" }} onClick={() => setImgDialog()}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <Carousal images={imgDialog} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MyRequest
