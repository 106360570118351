import React, { useState, useEffect, useContext } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { AuthContext } from "../libs/contexts/AuthContext"

const DeleteDialog = ({ deleteDialog }) => {
  let navigate = useNavigate()
  let params = useParams()
  const { lang } = useContext(AuthContext)
  if (!deleteDialog.open) return <></>
  return (
    <>
      {/* <!-- Notification Modal --> */}
      <div
        class={`modal`}
        // id='myModal'
        // tabindex='-1'
        role="dialog"
        // aria-labelledby='myModalTitle'
        // aria-hidden='true'
        style={{ display: "block" }}>
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            backgroundColor: "#000",
            opacity: "0.5",
          }}></div>
        <div class="modal-dialog modal-md modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title text-dark my-3" id="exampleModalLongTitle">
                {/* <div class=''>
                  <span class='badge badge-danger'>
                    {' '}asdas
                    <i class='fas fa-trash ' style={{ fontSize: '3em' }}></i>
                  </span>
                </div> */}
                {deleteDialog.message}
              </h5>
              {/* <button
                type='button'
                class='close'
                // data-dismiss='modal'
                // aria-label='Close'

                style={{ border: '0' }}
                onClick={deleteDialog.handleCancel}
              >
                <span aria-hidden='true'>&times;</span>
              </button> */}
            </div>
            {/* <div class='modal-body'>
              <h5>{deleteDialog.message}</h5>
            </div> */}
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" onClick={deleteDialog.handleConfirm}>
                {lang.delete}
              </button>
              <button
                type="button"
                class="btn btn-secondary mx-4"
                // data-dismiss='modal'
                onClick={deleteDialog.handleCancel}>
                {lang.cancel}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DeleteDialog
