import React, { useState, useEffect, useContext } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { AuthContext } from "../../libs/contexts/AuthContext"
import { SnackbarContext } from "../../libs/contexts/SnackbarContext"
import { handleApiError } from "../../libs/apis/handleApiError"
import api from "../../libs/apis/api"
import * as Yup from "yup"
import bulkImg from "../../assets/images/bulk-images.png"
import Header from "../../components/Header"
import moment from "moment"
import { useStompClient, useSubscription } from "react-stomp-hooks"
import translateIcon from "../../assets/images/translate_icon1.png"
import Dialog from "../../components/Dialog"
import ReportBlockDialog from "../../components/ReportBlockDialog"
import fromNowLang from "../../utils/fromNowLang"

const ChatDealDialog = ({ chatDeal, handleClose }) => {
  let navigate = useNavigate()
  let params = useParams()
  const { auth, lang } = useContext(AuthContext)
  const { openSnackbar } = useContext(SnackbarContext)
  const [request, setRequest] = useState(chatDeal.partRequest)
  const [responses, setResponses] = useState([])
  const [activeChat, setActiveChat] = useState()
  const [imgDialog, setImgDialog] = useState()
  const [blockDialog, setBlockDialog] = useState({ open: false, message: "" })
  const [room, setRoom] = useState()
  const [messages, setMessages] = useState()
  const [inputMsg, setInputMsg] = useState("")
  let chatRoom = chatDeal.chatRoom
  let prView = chatDeal.partRequest

  const stompClient = useStompClient()

  useSubscription(
    `/topic/${chatRoom?.id}`,
    (message) => {
      let msg = JSON.parse(message.body)
      setMessages([...messages, msg])
      if (msg.senderId != auth.data.id) {
        sendMessageRead()
      }
    },
    {
      headers: {
        "content-type": "application/json",
        Authorization: auth.authToken,
        deviceToken: "",
      },
    }
  )

  const sendMessage = (message) => {
    if (stompClient) {
      //Send Message
      stompClient.publish({
        destination: `/app/topic`,
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          roomId: chatRoom.id,
          receiverId: activeChat.bpd.id,
          feedId: chatRoom.feedId,
          text: "",
          imageUrl: "",
          videoUrl: "",
          title: "",
          authorization: auth.authToken.replace("Bearer ", ""),
          ...message,
        }),
      })
    } else {
      //Handle error
      console.log("Chat Error")
    }
  }

  const sendMessageRead = () => {
    if (stompClient) {
      //Send Message
      stompClient.publish({
        destination: `/app/topic/read`,
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          // roomId: chatRoom.id,
          receiverId: activeChat.bpd.id,
          feedId: chatRoom.feedId,
          authorization: auth.authToken.replace("Bearer ", ""),
        }),
      })
    } else {
      //Handle error
    }
  }
  const fetchRequest = async (values) => {
    try {
      const { data } = await api.get(`requests`)
      setRequest(data.find((item) => item.id == params.id))
      fetchResponses()
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const fetchResponses = async (values) => {
    try {
      // const { data } = await api.get(`requests/pricelist?feedId=${prView.id}`)
      // let d = data.map(item => ({ ...item, bpd: item.businessProfileData }))
      let d = { ...chatDeal.partRequest, bpd: chatDeal.partRequest.business }
      fetchRoom(d)
      setResponses([d])
      setActiveChat(d)
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const fetchRoom = async (ac) => {
    try {
      const { data } = await api.post(`v1/rooms/ensure?receiverId=${chatRoom.memberIds[1]}&feedId=${prView.id}`)
      fetchMessages(ac, data.id)
      setRoom(data)
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const fetchMessages = async (ac, roomId) => {
    try {
      const { data } = await api.get(`v1/messages?roomId=${roomId}&receiverId=${chatRoom.memberIds[1]}&feedId=${prView.id}`)
      setMessages(data)
      // sendMessageRead()
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const translate = (tid) => {
    let t = []
    responses.forEach((item) => {
      if (item.pricePostId == tid) {
        t.push({ ...item, translate: !item.translate })
      } else {
        t.push({ ...item, translate: false })
      }
    })
    setResponses(t)
  }
  const translateChat = (tid) => {
    let t = []
    messages.forEach((item) => {
      if (item.id == tid) {
        t.push({ ...item, translate: !item.translate })
      } else {
        t.push({ ...item, translate: false })
      }
    })
    setMessages(t)
  }

  useEffect(() => {
    // fetchRequest()
    fetchResponses()
  }, [])

  const handleImageUpload = async (values) => {
    try {
      const formData = new FormData()
      formData.append("receiverId", activeChat.bpd.id)
      formData.append("roomId", chatRoom.id)
      formData.append("feedId", chatRoom.feedId)
      formData.append("uploadImage", values)
      const { data } = await api.post(`v1/messages/media`, formData)
      sendMessage({ imageUrl: data.uploadImage.pictureURL })
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const handleBlock = async (values) => {
    try {
      const { data } = await api.post(`/spam/blockUser`, {
        blockedUserId: values.data.userId,
        reportNotes: values.reportNotes,
        reportType: values.reportType,
      })
      setBlockDialog({ open: false })
      openSnackbar({ color: "success", message: "User Blocked Successfully" })
      // fetchUpdateData()
      handleClose()
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  return (
    <>
      <div class="modal" role="dialog" style={{ display: "block" }}>
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            backgroundColor: "#000",
            opacity: "0.5",
          }}></div>
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div class="modal-content p-0" style={{ overflow: "hidden" }}>
            <div class="modal-header">
              <h4 class="modal-title text-dark" id="exampleModalLongTitle">
                {lang.make_deal}
              </h4>
              <button
                type="button"
                class="close"
                style={{ border: "0" }}
                onClick={() => {
                  sendMessageRead()
                  handleClose()
                }}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body p-0">
              <div class="container-fluid rounded-4">
                <div class="row">
                  <div class="col-md-2 d-flex justify-content-between">
                    <div class="user-profile-img-main d-flex justify-content-center my-3">
                      <div
                        class="left-img m-0 ms-4"
                        style={{
                          backgroundImage: `url('${request?.business?.profileImage?.url}')`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          backgroundColor: "lightgrey",
                          width: "5em",
                          height: "5em",
                        }}></div>
                    </div>
                    <div></div>
                  </div>
                  <div class="col-md-9">
                    <div class="h6 mt-3 mb-0 text-capitalize">{request.business?.companyname}</div>
                    <div class="fs-5 text-black fw-bold">
                      {request.year} {request.make} {request.model}
                    </div>
                    <div class="fs-6 text-black ">{request.translate ? request.spanishDesc : request.desc}</div>
                    <div class="mb-2">
                      <span class="fs-6 pe-5">{fromNowLang(moment(request?.createdAt).fromNow(), lang)}</span>
                      <span
                        class="badge text-bg-warning text-white ml-5 text-wrap"
                        role="button"
                        onClick={() => setRequest((prev) => ({ ...prev, translate: !prev.translate }))}>
                        {lang.translate}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="container-fluid p-0">
                <div class="chat-thread-main" style={{ height: "65vh" }}>
                  <div class="chat-name-box">
                    <div
                      className="user-img"
                      style={{
                        backgroundImage: `url('${activeChat?.bpd?.profileImage?.url}')`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                      }}></div>
                    <div className="container">
                      <div className="user-details row justify-content-end">
                        <div className="mt-2 fs-5 fw-bold col">
                          {activeChat?.bpd?.firstname} {activeChat?.bpd?.lastname}
                        </div>
                        {activeChat?.bpd?.isActive ? (
                          <></>
                        ) : (
                          <span className="ps-3 fs-6" style={{ color: "#d41404", fontStyle: "italic", fontWeight: "none" }}>
                            ({lang.inactive_user})
                          </span>
                        )}
                        <div className="col-1">
                          <button
                            className="btn btn-01 px-2.5 pt-2 pb-2 me-2 "
                            disabled={!activeChat?.bpd?.isActive}
                            onClick={() => {
                              setBlockDialog({
                                open: lang.block,
                                data: activeChat,
                                step: "block",
                                handleBlock: handleBlock,
                                handleCancel: () => setBlockDialog({ ...blockDialog, open: false }),
                              })
                              // window.open(`https://www.google.com/maps/search/?api=1&query=${activeChat?.bpd?.addressText}`, "_blank")
                            }}>
                            <i className="fas fa-ban fs-5 mt-1" />
                          </button>
                        </div>
                        {/* <div style={{ color: "#D41404" }}>{activeChat?.bpd?.companyname}</div> */}
                      </div>
                    </div>
                  </div>
                  {messages && (
                    <div className="chat-chats">
                      {messages
                        ?.map((item) => {
                          if (item.senderId == auth.data.id)
                            return (
                              <div key={item.id} className="sender">
                                <div>
                                  {item.imageUrl ? (
                                    <div role="button" onClick={() => setImgDialog(item.imageUrl)}>
                                      <img src={item.imageUrl} style={{ width: "6em", height: "6em", borderRadius: ".5em" }} />
                                    </div>
                                  ) : (
                                    <div className="message">{item.text}</div>
                                  )}
                                  <div className="time">{fromNowLang(moment(item.date).fromNow(), lang)}</div>
                                </div>
                              </div>
                            )
                          else
                            return (
                              <div key={item.id} className="receiver">
                                <div>
                                  {item.imageUrl ? (
                                    <div role="button" onClick={() => setImgDialog(item.imageUrl)}>
                                      <img src={item.imageUrl} style={{ width: "6em", height: "6em", borderRadius: ".5em" }} />
                                    </div>
                                  ) : (
                                    <div className="message">{item.translate ? item.spanishText : item.text}</div>
                                  )}
                                  <div className="time">
                                    <div>{fromNowLang(moment(item.date).fromNow(), lang)}</div>
                                    <div role="button" style={{ width: "2em", marginLeft: "3em" }} onClick={() => translateChat(item.id)}>
                                      <img src={translateIcon} width="100%" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                        })
                        .reverse()}
                    </div>
                  )}
                  {prView.flag == "open" && activeChat?.bpd?.isActive && (
                    <div className="chat-input-main">
                      <div className="upload-btn-wrapper" role="button" style={{ cursor: "pointer" }}>
                        <button className="btn ps-1">
                          {" "}
                          <i class="fas fa-camera"></i>
                        </button>
                        <input
                          type="file"
                          name="myfile"
                          style={{ width: "2em", cursor: "pointer" }}
                          accept="image/*"
                          onChange={(e) => {
                            if (e?.target.files?.length != 0) handleImageUpload(e.target.files[0])
                          }}
                        />
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter your message..."
                        value={inputMsg}
                        onChange={(e) => setInputMsg(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && inputMsg && !/^\s+$/.test(inputMsg)) {
                            sendMessage({ text: inputMsg })
                            setInputMsg("")
                          }
                        }}
                      />
                      <button
                        type="button"
                        class="btn"
                        onClick={() => {
                          if (inputMsg && !/^\s+$/.test(inputMsg)) {
                            sendMessage({ text: inputMsg })
                            setInputMsg("")
                          }
                        }}>
                        <i class="fas fa-paper-plane"></i>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {blockDialog.open && <ReportBlockDialog dialog={blockDialog} />}
      <div class="modal" role="dialog" style={{ display: imgDialog ? "block" : "none" }}>
        <div style={{ position: "absolute", height: "100%", width: "100%", backgroundColor: "#000", opacity: "0.5" }}></div>
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header pb-5">
              <h4 class="modal-title text-dark" id="exampleModalLongTitle">
                Image View
              </h4>
              <button type="button" style={{ border: "0" }} onClick={() => setImgDialog()}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <a href={imgDialog} target="_blank">
                <img src={imgDialog} class="object-fit-none p-5" width="100%" />
              </a>
              {/* <Carousal images={imgDialog} /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ChatDealDialog
