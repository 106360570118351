import React, { forwardRef } from 'react'

// const Select = forwardRef(({ ...props }, ref) => {

const Select = ({ name, register, options, placeholder, errors, ...rest }) => {
  // let { name, placeholder, options, errors } = props
  let err = errors ? errors[name]?.message : ''

  // console.log('NN ', props)
  return (
    <>
      <select
        class='form-select'
        // {...props}
        // ref={ref}
        {...(register ? { ...register(name) } : null)}
        style={{ border: err ? '2px solid red' : '' }}
        {...rest}
      >
        {placeholder && (
          <option selected value='' style={{ fontWeight: 'bold', color: 'lightgray' }}>
            <em>{placeholder} </em>
          </option>
        )}
        {options.map(item => (
          <option value={item.value}>{item.label}</option>
        ))}
      </select>
      <p className='text-danger mx-3 mt-1'>{err}</p>
    </>
  )
}

export default Select
