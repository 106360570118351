import React, { useEffect } from 'react'
import useSpeechToText from 'react-hook-speech-to-text'

export default function AnyComponent ({ setValue }) {
  const {
    error,
    interimResult,
    isRecording,
    results,
    startSpeechToText,
    stopSpeechToText
  } = useSpeechToText({
    continuous: true,
    useLegacyResults: false
  })

  const recordingButton = () => {
    if (isRecording) return 'btn btn-danger'
    else return 'btn btn-outline-danger'
  }

  const saveResult = () => {
    let res = ''
    results.forEach(result => {
      res += result.transcript + ' '
    })
    setValue('desc', res)
  }

  useEffect(() => {
    let res = ''
    results.forEach(result => {
      res += result.transcript + ' '
    })
    setValue('desc', res)
    // const intervalId = setInterval(() => {
    //   let res = ''
    //   results.forEach(result => {
    //     res += result.transcript + ' '
    //   })
    //   setValue('desc', res)
    // }, 2000)

    // // clear interval on re-render to avoid memory leaks
    // return () => clearInterval(intervalId)
  }, [results])

  if (error) return <p>Web Speech API is not available in this browser 🤷‍</p>

  return (
    <div className='mb-2'>
      {/* <h1>Recording: {isRecording.toString()}</h1> */}

      <button
        class={recordingButton()}
        onClick={isRecording ? stopSpeechToText : startSpeechToText}
      >
        {isRecording ? (
          <>
            <i class='fa fa-microphone'></i>
            <span className='mx-2'> Stop Recording </span>
          </>
        ) : (
          <>
            <i class='fa fa-microphone'></i>
            <span className='mx-2'> Start Recording </span>
          </>
        )}
      </button>
      <button
        className='btn btn-outline-danger mx-3'
        onClick={() => setValue('desc', '')}
      >
        Reset
      </button>
      {/* <div>
        {results.map(result => (
          <>{result.transcript}</>
        ))}
      </div> */}
      <div>{interimResult}</div>
      {/* <ul>
        {results.map((result) => (
          <li key={result.timestamp}>{result.transcript}</li>
        ))}
        {interimResult && <li>{interimResult}</li>}
      </ul> */}
    </div>
  )
}
