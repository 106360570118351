import React, { useState, useEffect, useContext } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { AuthContext } from "../../libs/contexts/AuthContext"
import { SnackbarContext } from "../../libs/contexts/SnackbarContext"
import api from "../../libs/apis/api"
import * as Yup from "yup"
import { handleApiError } from "../../libs/apis/handleApiError"
import { yupResolver } from "@hookform/resolvers/yup"
import bulkImg from "../../assets/images/bulk-images.png"
import { useForm } from "react-hook-form"
import Header from "../../components/Header"
import Textfield from "../../components/FormFields/Textfield"


const CompleteRequestDialog = ({ dialog }) => {
  let navigate = useNavigate()
  let params = useParams()
  const { auth, lang } = useContext(AuthContext)
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      review: "",
      stars: 4,
    },
  })
  const handleStars = (active) => {
    let stars = []
    let arr = [1, 2, 3, 4, 5]
    arr.forEach((item) => {
      if (item <= active) {
        stars.push(
          <i
            role="button"
            class="fas fa-star mx-2"
            style={{ color: "#ffc800" }}
            onClick={() => {
              setValue("stars", item)
            }}></i>
        )
      } else {
        stars.push(
          <i
            role="button"
            class="fas fa-star mx-2"
            style={{ color: "grey" }}
            onClick={() => {
              setValue("stars", item)
            }}></i>
        )
      }
    })
    return stars
  }

  if (!dialog.open) return <></>
  return (
    <>
      <div class="modal" role="dialog" style={{ display: "block" }}>
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            backgroundColor: "#000",
            opacity: "0.5",
          }}></div>
        <div class="modal-dialog modal-md modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title text-dark" id="exampleModalLongTitle">
                {lang.complete_deal}
              </h4>
              <button type="button" class="close" style={{ border: "0" }} onClick={dialog.handleCancel}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form onSubmit={handleSubmit(dialog.handleConfirm)}>
              <div class="modal-body">
                <div class="form-m-b-20">
                  <label class="form-label mt-10">Please give your Review</label>
                  <div>
                    {handleStars(watch("stars"))}
                   
                  </div>
                </div>
                <div class="form-m-b-20">
                  <label class="form-label mt-10">{lang.review}</label>
                  <textarea class="form-control" placeholder={lang.pls_review} {...register("review")} rows="10" cols="10" style={{ height: "5em" }}></textarea>
                </div>
              </div>
              <div class="modal-footer mt-3 pt-4">
                <button
                  type="button"
                  class="btn btn-secondary"
                  // data-dismiss='modal'
                  onClick={dialog.handleCancel}>
                  {lang.cancel}
                </button>
                <button
                  type="submit"
                  class="btn btn-01 py-2"
                  disabled={isSubmitting}>
                  {lang.complete_deal}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default CompleteRequestDialog
