import React, { useState, useEffect, useContext } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { AuthContext } from "../../libs/contexts/AuthContext"
import { SnackbarContext } from "../../libs/contexts/SnackbarContext"
import { handleApiError } from "../../libs/apis/handleApiError"
import api from "../../libs/apis/api"
import { get, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import logoImg from "../../assets/images/white-logo.png"
import Textfield from "../../components/FormFields/Textfield"
import { requestPermission } from "../../libs/services/firebase"

const Step1Schema = Yup.object().shape({
  email: Yup.string().email("Please enter valid email").required("Email is Required!"),
})

const Step2Schema = Yup.object().shape({
  email: Yup.string().email("Please enter valid email").required("Email is Required!"),
  verificationCode: Yup.string().required("Verification is Required"),
  password: Yup.string().min(5, "Minimum 5 characters required!!").required("Password is Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Required!"),
})

const ForgotPassword = () => {
  let navigate = useNavigate()
  let params = useParams()
  const { handleSetAuth, lang, changeLanguage } = useContext(AuthContext)
  const { openSnackbar } = useContext(SnackbarContext)

  const [activeStep, setActiveStep] = useState(1)

  const step1 = useForm({
    resolver: yupResolver(Step1Schema),
    defaultValues: {
      email: "",
    },
  })

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Step2Schema),
    defaultValues: {
      email: "",
      verificationCode: "",
      password: "",
      confirmPassword: "",
      showPass1: false,
      showPass2: false,
    },
  })

  const handleStep1 = async (values) => {
    try {
      const { data } = await api.post(`/v1/auth/signup/forgot-password`, values)
      openSnackbar({ color: "success", message: "Sent Forgot Password Verification Code to your email" })
      setActiveStep(2)
      reset({ ...getValues, email: values.email })
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const handleStep2 = async (values) => {
    try {
      const { data } = await api.post(`/v1/auth/signup/set-forgot-password`, values)
      openSnackbar({ color: "success", message: "Password Changed Successfully" })
      navigate("/login")
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  return (
    <>
      <section class="full-screen-body">
        <div class="container">
          <div class="logo-main">
            <img src={logoImg} class="img-fluid" />
          </div>
          <div class="row justify-content-center">
            <div class="col-md-9 col-lg-5">
              {activeStep == 1 ? (
                <form onSubmit={step1.handleSubmit(handleStep1)}>
                  <div class="login-box">
                    <p className="fs-3 fw-bold text-dark text-center mb-5">{lang.forgotPassword}</p>
                    {/* <p class='login-sub-title'>
                    <span class='red-font'>*</span> This service is for business
                    consumers ONLY
                  </p> */}
                    <div className="form-m-b mb-1">
                      <input type="text" class="form-control form-control-icon" placeholder={lang.email} {...step1.register("email")} />
                      <i class="fas fa-user"></i>
                    </div>
                    <p className="text-danger mx-3 mt-5">{step1.formState.errors["email"]?.message}</p>
                    <div class="d-grid">
                      <button
                        type="submit"
                        class="btn btn-01"
                      >
                        {lang.submit}
                      </button>
                    </div>
                  </div>
                </form>
              ) : (
                <form onSubmit={handleSubmit(handleStep2)}>
                  <div class="login-box">
                    <p className="fs-3 fw-bold text-dark text-center ">{lang.forgotPassword}</p>
                    <p class="login-sub-title mb-5">
                      <span class="red-font">*</span> {lang.enter_otp}
                    </p>
                    <div class="form-m-b mb-1">
                      <input
                        type="text"
                        class="form-control"
                        placeholder={lang.email}
                        {...register("email")}
                        readOnly
                        value={step1.getValues("email")}
                      />
                    </div>
                    <p className="text-danger mx-3 ">{errors["email"]?.message}</p>
                    <div class="form-m-b mb-1">
                      <input type="text" class="form-control" placeholder={lang.verification_code} {...register("verificationCode")} />
                    </div>
                    <p className="text-danger mx-3 ">{errors["email"]?.message}</p>
                    <div class="form-m-b mb-1">
                      <input
                        type={watch("showPass1") ? "text" : "password"}
                        class="form-control"
                        placeholder={lang.password}
                        {...register("password")}
                        name="password"
                      />
                      <i class="fas fa-loc"></i>
                      {watch("showPass1") ? (
                        <i class="fas fa-eye" role="button" onClick={() => setValue("showPass1", false)}></i>
                      ) : (
                        <i class="fas fa-eye-slash" role="button" onClick={() => setValue("showPass1", true)}></i>
                      )}
                    </div>
                    <p className="text-danger mx-3 ">{errors["password"]?.message}</p>
                    <div class="form-m-b mb-1">
                      <input
                        type={watch("showPass2") ? "text" : "password"}
                        class="form-control"
                        placeholder={lang.confirm_password}
                        {...register("confirmPassword")}
                      />
                      <i class="fas fa-loc"></i>
                      {watch("showPass2") ? (
                        <i class="fas fa-eye" role="button" onClick={() => setValue("showPass2", false)}></i>
                      ) : (
                        <i class="fas fa-eye-slash" role="button" onClick={() => setValue("showPass2", true)}></i>
                      )}
                    </div>
                    <p className="text-danger mx-3 ">{errors["confirmPassword"]?.message}</p>

                    <div class="d-grid mt-5">
                      <button
                        type="submit"
                        class="btn btn-01"
                      >
                        {lang.submit}
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </div>
            <div class="mb-4 text-center">
              <a role="button" style={{ color: "#f9ad4a",fontSize: "1.4em", fontWeight:'bold'  }} onClick={() => navigate("/login")}>
                {lang.back_to_login}
              </a>
            </div>
          </div>
        </div>
        <div class="login-footer">
          <p>
            {lang.have_questions}{" "}
            <a style={{ color: "white", textDecoration: "none" }} href="https://www.liveyards.com">
              {" "}
              {lang.contact_us}
            </a>
          </p>
          <p>
            © 2022 Liveyards. {lang.all_rights_reserved}. {lang.powered_by}.
          </p>
        </div>
      </section>
    </>
  )
}

export default ForgotPassword
