import React, { useState, useEffect, useContext } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { AuthContext } from "../libs/contexts/AuthContext"
import { SnackbarContext } from "../libs/contexts/SnackbarContext"
import { handleApiError } from "../libs/apis/handleApiError"
import api from "../libs/apis/api"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import bulkImg from "../assets/images/bulk-images.png"

const Notification = ({ setOpen }) => {
  let navigate = useNavigate()
  let params = useParams()
  const { handleSetAuth, lang } = useContext(AuthContext)
  const { openSnackbar } = useContext(SnackbarContext)
  const [muteVal, setMuteVal] = useState()
  const muteOptions = [
    { label: lang.thirty_min, value: "30 min", mute: "30" },
    { label: lang.one_hour, value: "1 hour", mute: "60" },
    { label: lang.three_hour, value: "3 hours", mute: "180" },
    { label: lang.next_day, value: "Rest of day", mute: "1 Day" },
  ]

  const fetchNoti = async (values) => {
    try {
      const { data } = await api.get(`v1/salvage`)
      setMuteVal(data.notificationPreference)
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const handleSubmit = async (values) => {
    try {
      const { data } = await api.put(`v1/salvage/notification-update`, {
        keyword: muteVal,
        muteOption: muteOptions.find((item) => item.value == muteVal)?.mute,
      })
      openSnackbar({
        open: true,
        color: "success",
        message: "Notification Successfully Saved",
      })
      setOpen(false)
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }
  useEffect(() => {
    fetchNoti()
  }, [])

  return (
    <>
      {/* <!-- Notification Modal --> */}
      <div
        class="modal"
        // id='myModal'
        // tabindex='-1'
        role="dialog"
        // aria-labelledby='myModalTitle'
        // aria-hidden='true'
        style={{ display: "block" }}>
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            backgroundColor: "#000",
            opacity: "0.5",
          }}></div>
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title text-dark" id="exampleModalLongTitle">
                {lang.message_notifications}
              </h5>
              <button
                type="button"
                class="close"
                // data-dismiss='modal'
                // aria-label='Close'
                style={{ border: "0" }}
                onClick={() => setOpen(false)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <h5>{lang.mute_message_nofitication}</h5>
              <h5>
                {muteOptions.map((item) => (
                  <>
                    <div className="text-black mb-2 mx-3">
                      <input
                        type="radio"
                        name="noti"
                        value={item.value}
                        checked={muteVal == item.value}
                        onChange={(e) => setMuteVal(e.target.value)}
                      />{" "}
                      <span className="font-weight-bold mx-2"> {item.label}</span>
                    </div>
                  </>
                ))}
              </h5>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                // data-dismiss='modal'
                onClick={() => setOpen(false)}>
                {lang.close_button}
              </button>
              <button type="button" class="btn btn-primary" onClick={handleSubmit}>
                {lang.mute}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Notification
