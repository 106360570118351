import React, { useState, useEffect, useContext } from "react"
import { useParams, useNavigate, useLocation } from "react-router-dom"
import { AuthContext } from "../libs/contexts/AuthContext"
import { SnackbarContext } from "../libs/contexts/SnackbarContext"
import { handleApiError } from "../libs/apis/handleApiError"
import api from "../libs/apis/api"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import bulkImg from "../assets/images/bulk-images.png"
import Notification from "./Notification"

const Header = ({ title }) => {
  let navigate = useNavigate()
  let params = useParams()
  let location = useLocation()
  const { auth, lang } = useContext(AuthContext)
  const { openSnackbar } = useContext(SnackbarContext)
  const [notifModal, setNotifModal] = useState(false)

  return (
    <>
      <div class="top-bar-header">
        <div class="left-side">
          <h1>{title}</h1>
        </div>
        <div class="right-side">
          <ul>
            {!["/create-request", "/settings"].find((url) => location.pathname.includes(url)) && (
              <li class="">
                <a onClick={() => navigate("/create-request")} class="btn btn-01">
                  {lang.create_request}
                </a>
              </li>
            )}
            {auth.data?.profiletype.includes("consumer") && (
              <li class="notification-link">
                <i class="fas fa-bell cursor-pointer" onClick={() => setNotifModal(true)}></i>
              </li>
            )}
            <li class="user-name-img-li">
              <div
                className="user-img me-3"
                style={{
                  overflow: "hidden",
                }}>
                <img
                  src={auth?.data?.profileImage?.url}
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                    display: !auth?.data?.profileImage?.url && "none",
                    backgroundColor: "lightgrey",
                  }}
                />
              </div>

              <label>{auth?.data?.firstname + " " + auth?.data?.lastname}</label>
              {/* <i class='fas fa-plus-circle'></i> */}
            </li>
          </ul>
        </div>
      </div>
      {/* <!-- Notification Modal --> */}
      {notifModal && <Notification setOpen={setNotifModal} />}
    </>
  )
}

export default Header
