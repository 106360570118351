import React, { useState, useEffect, useContext } from "react"
import { useParams, useNavigate, useOutletContext } from "react-router-dom"
import { AuthContext } from "../../libs/contexts/AuthContext"
import { SnackbarContext } from "../../libs/contexts/SnackbarContext"
import { handleApiError } from "../../libs/apis/handleApiError"
import api from "../../libs/apis/api"
import * as Yup from "yup"
import bulkImg from "../../assets/images/bulk-images.png"
import Header from "../../components/Header"
import moment from "moment"
import InfoAction from "../../components/InfoAction"
import InfoDialog from "../../components/InfoDialog"
import Tooltip from "../../components/Tooltip"
import fromNowLang from "../../utils/fromNowLang"

const InfoRequested = () => {
  let navigate = useNavigate()
  let { id } = useParams()
  const { handleSetAuth, lang } = useContext(AuthContext)
  const { openSnackbar } = useContext(SnackbarContext)
  const { requests, fetchMyRequests } = useOutletContext()
  const [infoP, setInfoP] = useState()
  const [infoReq, setInfoReq] = useState([])
  const [infoDialog, setInfoDialog] = useState({ open: false })

  const fetchData = async (values) => {
    try {
      const { data } = await api.get(`requests/getInfoNeeded/${id}`)
      setInfoReq(data)
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }
  const fetchRequest = async (values) => {
    try {
      const { data } = await api.get(`requests`)
      setInfoP({ ...data.find((item) => item.id == id), translate: false })
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const handleInfoNeeded = async (values) => {
    try {
      const { data } = await api.post(`requests/setInfoNeededAnswer`, {
        liveFeedId: infoP.id,
        // infoNeededList: [{
        question: values.question,
        questionPostId: values.questionPostId,
        infoAnswer: values.message,
        answerPostId: infoP.business.id,
        //     // questionDate: null
        // }]
      })
      fetchData()
      fetchMyRequests("refresh")
      setInfoDialog((prev) => ({ ...prev, open: false }))
      openSnackbar({ color: "success", message: "Sent Info Answer Successfully" })
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const handleMarkAllRead = async (values) => {
    try {
      const { data } = await api.get(`/requests/readAllInfoneeded?feedId=${id}`)
      fetchMyRequests("refresh")
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  useEffect(() => {
    fetchData()
    if (requests) setInfoP({ ...requests?.find((item) => item.id == id), translate: false })
    // fetchRequest()
  }, [requests])

  if (!infoP || !infoReq) {
    return (
      <>
        <Header title={lang.info_requested} />
        <div class="wrapper" style={{ height: "100vh" }}>
          Loading...
        </div>
      </>
    )
  }
  return (
    <>
      <Header title={lang.info_requested} />
      <InfoDialog dialog={infoDialog} />
      <div class="wrapper" style={{ height: "90vh" }}>
        <div class="container-fluid rounded-4" style={{ border: "solid 2px #770b0250" }}>
          <div class="row">
            <div class="col-md-2 d-flex justify-content-between">
              <div class="d-flex align-items-center">
                {" "}
                <i
                  class="fas fa-arrow-left mx-2"
                  style={{ cursor: "pointer", marginRight: "1.2em", fontSize: "2em" }}
                  onClick={() => navigate(-1)}></i>
              </div>
              <div class="user-profile-img-main d-flex justify-content-center my-3">
                <div
                  class="left-img m-0"
                  style={{
                    backgroundImage: `url('${infoP.business?.profileImage?.url}')`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}></div>
              </div>
              <div></div>
            </div>
            <div class="col-md-9">
              <div class="h5 mt-3 mb-0 text-capitalize">{infoP.business?.firstname}</div>
              <div class="fs-4 text-black fw-bold">
                {infoP.year} {infoP.make} {infoP.model}
              </div>
              <div class="fs-5 text-black ">{infoP.translate ? infoP.spanishDesc : infoP.desc}</div>
              <div class="mb-2">
                <span class="fs-6 pe-5">{fromNowLang(moment(infoP.createdAt).fromNow(), lang)}</span>
                <span
                  class="badge text-bg-warning text-white ml-5 text-wrap"
                  role="button"
                  onClick={() => setInfoP((prev) => ({ ...prev, translate: !prev.translate }))}>
                  {lang.translate}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="table-main-div">
          <ul class="custome-table-head mt-3">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            {/* <li></li> */}
            <li>
              <div className="mark-all-read text-end" role="button" onClick={handleMarkAllRead}>
                {lang.mark_all_read}
              </div>
            </li>
          </ul>
          {infoReq?.map((item) => (
            <div key={item.questionPostId} class="custome-tr-main">
              <div class="icon-img-main">
                <div
                  style={{
                    backgroundImage: `url('${item.business.profileImage?.url}')`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}></div>
              </div>
              <div class="td-1" style={{ height: "3em" }}>
                <div class="fs-5 d-flex align-items-center h-100 text-capitalize">{item.business.firstname}</div>
              </div>
              <div class="td-2">
                <div class="fs-6">Questions: {item.question}</div>
                <div class="fs-6">
                  <div style={{ height: "auto", width: "100%", wordWrap: "break-word" }}>
                    Answer:
                    {item.infoAnswer}
                  </div>
                </div>
                {/* <Tooltip style={{width:'100%'}}  text= {item.infoAnswer} data= 'view Answer'/> */}
              </div>
              <div class="td-3" stle={{ marginLeft: "15px" }}>
                {fromNowLang(moment(item.createdTime).fromNow(), lang)}
              </div>
              <div class="td-5"></div>
              <div class="td-6">
                <div class="dot-link d-flex h6 me-3">
                  <InfoAction
                    type={item.infoAnswer ? "" : "info-requested"}
                    handleClick={() =>
                      setInfoDialog({
                        open: true,
                        data: {
                          infoP: infoP,
                          year: infoP.year,
                          make: infoP.make,
                          model: infoP.model,
                          desc: infoP.desc,
                          ...item,
                        },
                        type: item.infoAnswer ? "" : "info-requested",
                        handleConfirm: handleInfoNeeded,
                        handleCancel: () => setInfoDialog({ ...infoDialog, open: false }),
                      })
                    }
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default InfoRequested
