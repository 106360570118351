import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { AuthContext } from "../../libs/contexts/AuthContext";
import { SnackbarContext } from "../../libs/contexts/SnackbarContext";
import { handleApiError } from "../../libs/apis/handleApiError";
import api from "../../libs/apis/api";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import bulkImg from "../../assets/images/bulk-images.png";
import Header from "../../components/Header";
import Switch from "../../components/FormFields/Switch";
import Textfield from "../../components/FormFields/Textfield";
const Subscription = () => {
  let navigate = useNavigate();
  let params = useParams();
  const { handleSetAuth, lang } = useContext(AuthContext);
  const { openSnackbar } = useContext(SnackbarContext);
  const urlParams = new URLSearchParams(window.location.search);
  const subscriptionSuccess = urlParams.get("success");
  console.log("Subscription Success", subscriptionSuccess);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      checkApi: null,
    },
  });
  const [loader, setLoader] = useState(true);
  const fetchCheckApi = async (values) => {
    try {
      setLoader(true);
      const { data } = await api.get(`v1/subscription/check`);
      if (data.data) setValue("checkApi", data);
      else navigate("/settings");
      // else handleUpgrade();
    } catch (error) {
      handleApiError(error, openSnackbar);
    } finally {
      setLoader(false);
    }
  };
  const handleUpgrade = async (values) => {
    try {
      const { data } = await api.get(`v1/subscription/checkout`);
      window.location.href = data.redirectUrl;
    } catch (error) {
      handleApiError(error, openSnackbar);
    }
  };
  useEffect(() => {
    if (subscriptionSuccess == "false") navigate("/settings");
    else fetchCheckApi();
  }, []);
  return (
    <>
      <Header title={lang.subscription} />
      <>
        {watch("checkApi.data") === null ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{ height: "2.5em", width: "2.5em", margin: "3em 0 3em 0" }}
            >
              <div className="loader"></div>
            </div>
          </div>
        ) : (
          <>
            {/* {watch("checkApi.data") === null ? (
              <>
                <div class="container mt-5">
                  <div class="row justify-content-center">
                    <div class="col-md-6 ">
                      <div class="card">
                        <div
                          class="card-header"
                          style={{ backgroundColor: "#fff" }}
                        >
                          <h3
                            style={{ marginBottom: "10px", textAlign: "left" }}
                          >
                            Basic
                          </h3>
                          <div
                            style={{
                              fontSize: 24,
                              fontWeight: "bold",
                              textAlign: "left",
                              marginBottom: "10px",
                              color: "black",
                            }}
                          >
                            $9 <span style={{ fontSize: 14 }}> /month</span>
                          </div>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Vivamus varius est ut ante consectetur, et
                            facilisis lectus egestas.
                          </p>
                        </div>
                        <div class="card-body">
                          <ul style={{ listStyle: "none", paddingLeft: 0 }}>
                            <li
                              style={{ marginBottom: "20px", fontSize: "1rem" }}
                            >
                              <i
                                class="fas fa-check-circle text-success"
                                style={{ marginRight: "15px" }}
                              ></i>
                              s Lorem Impsum domlor sev 1
                            </li>
                            <li
                              style={{ marginBottom: "20px", fontSize: "1rem" }}
                            >
                              <i
                                class="fas fa-check-circle text-success"
                                style={{ marginRight: "15px" }}
                              ></i>{" "}
                              Lorem Impsum domlor sev 2
                            </li>
                            <li
                              style={{ marginBottom: "20px", fontSize: "1rem" }}
                            >
                              <i
                                class="fas fa-check-circle text-success"
                                style={{ marginRight: "15px" }}
                              ></i>{" "}
                              Lorem Impsum domlor sev 3
                            </li>
                            <li
                              style={{ marginBottom: "20px", fontSize: "1rem" }}
                            >
                              <i
                                class="fas fa-check-circle text-success"
                                style={{ marginRight: "15px" }}
                              ></i>{" "}
                              Lorem Impsum domlor sev 4
                            </li>
                            <li
                              style={{ marginBottom: "20px", fontSize: "1rem" }}
                            >
                              <i
                                class="fas fa-check-circle text-success"
                                style={{ marginRight: "15px" }}
                              ></i>{" "}
                              Lorem Impsum domlor sev 4
                            </li>
                            <li
                              style={{ marginBottom: "20px", fontSize: "1rem" }}
                            >
                              <i
                                class="fas fa-check-circle text-success"
                                style={{ marginRight: "15px" }}
                              ></i>{" "}
                              Lorem Impsum domlor sev 4
                            </li>
                            <li
                              style={{ marginBottom: "40px", fontSize: "1rem" }}
                            >
                              <i
                                class="fas fa-check-circle text-success"
                                style={{ marginRight: "15px" }}
                              ></i>{" "}
                              Lorem Impsum domlor sev 5
                            </li>
                          </ul>
                          <div class="text-center">
                            <button
                              class="btn btn-primary w-100"
                              onClick={() => {
                                handleUpgrade();
                              }}
                              style={{
                                backgroundColor: "red",
                                borderColor: "red",
                              }}
                            >
                              UPGRADE
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) 
            : ( */}
            <div class="wrapper">
              <div class="row">
                <div class="col-sm-12 col-md-8 col-lg-5 ">
                  <div class="card">
                    <div
                      class="card-header  text-white h2"
                      style={{ backgroundColor: "#d41404" }}
                    >
                      Current Subscription Plan
                    </div>
                    <div class="card-body">
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item py-2 h5">
                          <strong>Type : </strong> {watch("checkApi.data.type")}
                        </li>
                        <li class="list-group-item py-2 h5">
                          <strong>Current Status : </strong>{" "}
                          {watch("checkApi.data.currentStatus")}
                        </li>
                        <li class="list-group-item py-2 h5">
                          <strong>Email : </strong>{" "}
                          {watch("checkApi.data.email")}{" "}
                        </li>
                        <li class="list-group-item py-2 h5">
                          <strong>Start Date : </strong>{" "}
                          {watch("checkApi.data.startDate")}
                        </li>
                        <li class="list-group-item py-2 h5">
                          <strong>End Date : </strong>{" "}
                          {watch("checkApi.data.endDate")}
                        </li>
                      </ul>
                    </div>
                  </div>
                  {watch("checkApi.data.manageUrl") && (
                    <button
                      class="btn btn-01 w-100 text-capitalize mt-4"
                      onClick={() => {
                        window.location.href = watch("checkApi.data.manageUrl");
                      }}
                    >
                      {lang.manage_url}
                    </button>
                  )}
                </div>
              </div>
            </div>
            {/* )} */}
          </>
        )}
      </>
    </>
  );
};
export default Subscription;
