import React, { useState, useEffect, useContext } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { AuthContext } from "../libs/contexts/AuthContext"

const ReportBlockDialog = ({ dialog }) => {
  let navigate = useNavigate()
  let params = useParams()
  const { handleSetAuth, lang } = useContext(AuthContext)
  const [disabledConfirm, setDisabledConfirm] = useState(false)
  const [reportBtn, setReportBtn] = useState("Spam")
  const [activeStep, setActiveStep] = useState(dialog.step ?? 1)
  const [message, setMessage] = useState("")

  useEffect(() => {
    if (!dialog.open) {
      setDisabledConfirm(false)
      setActiveStep(1)
      setReportBtn("Spam")
    }
  }, [dialog])

  if (!dialog.open) return <></>
  else if (activeStep == 1)
    return (
      <>
        <div class={`modal`} role="dialog" style={{ display: "block", zIndex: "1100" }}>
          <div
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              backgroundColor: "#000",
              opacity: "0.5",
            }}></div>
          <div class="modal-dialog modal-md modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title text-dark my-3 mt-1" id="exampleModalLongTitle">
                  {/* <div class="fs-5 mb-4">Report</div> */}
                  <div class="text-color-ly my-2 ms-2 mb-4" role="button" onClick={() => setActiveStep("report")}>
                    <i class="fas fa-flag fs-5 mx-2"></i> <span class="ms-3">{lang.report_this}</span>
                  </div>
                  <div class="text-color-ly my-2" role="button" onClick={() => setActiveStep("block")}>
                    <i class="fas fa-ban fs-5 mx-2 ms-3"></i>
                    <span class="ms-4">{lang.block_this}</span>
                  </div>
                </h5>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary mx-4 ms-3" onClick={dialog.handleCancel}>
                  {lang.cancel}
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  else if (activeStep == "report")
    return (
      <>
        <div class={`modal`} role="dialog" style={{ display: "block", zIndex: "1100" }}>
          <div
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              backgroundColor: "#000",
              opacity: "0.5",
            }}></div>
          <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title text-dark my-3 mt-1" id="exampleModalLongTitle">
                  <div class="fs-5 mb-4">{lang.report_this_post}</div>
                  {[
                    { value: "Spam", label: lang.spam },
                    { value: "Harassment", label: lang.harassment },
                    { value: "Inappropriate Content", label: lang.inappropriate_content },
                    { value: "Other", label: lang.other },
                  ].map((item) => (
                    <button
                      key={item}
                      type="button"
                      class={reportBtn == item.value ? `btn btn-primary  me-2 mb-2` : `btn btn-outline-secondary me-2 mb-2`}
                      onClick={() => setReportBtn(item.value)}>
                      {item.label}
                    </button>
                  ))}
                  {reportBtn == "Other" && (
                    <textarea
                      class="form-control mt-3"
                      rows="10"
                      placeholder="Other"
                      style={{ height: "7em" }}
                      onChange={(e) => setMessage(e.target.value)}></textarea>
                  )}
                </h5>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger px-3 fs-6"
                  onClick={() => {
                    setDisabledConfirm(true)
                    dialog.handleReport({ data: dialog.data, reportNotes: message, reportType: reportBtn })
                  }}
                  disabled={disabledConfirm}>
                  {lang.report}
                </button>
                <button type="button" class="btn btn-secondary mx-4 ms-3" onClick={dialog.handleCancel}>
                  {lang.cancel}
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  else if (activeStep == "block")
    return (
      <>
        <div class={`modal`} role="dialog" style={{ display: "block", zIndex: "1100" }}>
          <div
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              backgroundColor: "#000",
              opacity: "0.5",
            }}></div>
          <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title text-dark my-3 mt-1" id="exampleModalLongTitle">
                  <div class="fs-5 mb-4">{lang.block_this_user}</div>
                  {[
                    { value: "Spam", label: lang.spam },
                    { value: "Harassment", label: lang.harassment },
                    { value: "Inappropriate Content", label: lang.inappropriate_content },
                    { value: "Other", label: lang.other },
                  ].map((item) => (
                    <button
                      key={item.value}
                      type="button"
                      class={reportBtn == item.value ? `btn btn-primary  me-2 mb-2` : `btn btn-outline-secondary me-2 mb-2`}
                      onClick={() => setReportBtn(item.value)}>
                      {item.label}
                    </button>
                  ))}
                  {reportBtn == "Other" && (
                    <textarea
                      class="form-control mt-3"
                      rows="10"
                      placeholder="Other"
                      style={{ height: "7em" }}
                      onChange={(e) => setMessage(e.target.value)}></textarea>
                  )}
                </h5>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger px-3 fs-6"
                  onClick={() => {
                    setDisabledConfirm(true)
                    dialog.handleBlock({ data: dialog.data, reportNotes: message, reportType: reportBtn })
                  }}
                  disabled={disabledConfirm}>
                  {lang.block}
                </button>
                <button type="button" class="btn btn-secondary mx-4 ms-3" onClick={dialog.handleCancel}>
                  {lang.cancel}
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    )
}

export default ReportBlockDialog
