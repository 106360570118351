import React, { useState, createContext } from "react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

export const SnackbarContext = createContext()

const SnackbarContextProvider = (props) => {
  const openSnackbar = ({ open, color, message, ...rest }) => {
    toast(message, {
      type: color == "success" ? toast.TYPE.SUCCESS : color == "error" ? toast.TYPE.ERROR : toast.TYPE.INFO,
      ...rest,
    })
  }

  return (
    <>
      <SnackbarContext.Provider value={{ openSnackbar: openSnackbar }}>
        {props.children}
        <ToastContainer
          position="bottom-left"
          limit={1}
          //   autoClose={5000}
          //   hideProgressBar={false}
          //   newestOnTop={false}
          //   closeOnClick
          //   rtl={false}
          //   pauseOnFocusLoss
          //   draggable
          //   pauseOnHover
        />
      </SnackbarContext.Provider>
    </>
  )
}

export default SnackbarContextProvider
