import React from 'react'
import '../assets/css/tooltip.css'

const Tooltip = (props) => {
    const { text, data, style, ...rest } = props
    return (
        <>
            <div class="tooltip-data">{data}
                <span class={`tooltip-text px-2 ${rest.class}`} style={style}>{props.text}</span>
            </div>
        </>
    )
}

export default Tooltip
