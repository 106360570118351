import React, { useState, useEffect, createContext } from "react"
import es_lang from "../../utils/es_lang.json"
import en_lang from "../../utils/en_lang.json"
import { handleLogoutNotification } from "../services/firebase"

export const AuthContext = createContext()

const AuthContextProvider = (props) => {
  let authUpd = {
    authToken: localStorage.getItem("authToken") ?? null,
    data: JSON.parse(localStorage.getItem("data")) ?? null,
  }
  const [auth, setAuth] = useState(authUpd)
  const [logoutDialog, setLogoutDialog] = useState({ open: false })
  const [loading, setLoading] = useState(false)
  const [makeModel, setMakeModel] = useState()

  let langUpd = localStorage.getItem("language") ?? null
  if (!langUpd) localStorage.setItem("language", "English")
  const [language, setLanguage] = useState(langUpd ?? "English")

  const changeLanguage = () => {
    let l = language == "English" ? "Español" : "English"
    setLanguage(l)
    localStorage.setItem("language", l)
  }

  const isAuth = () => {
    return localStorage.getItem("authToken") ? true : false
  }

  const openLogoutDialog = () => {
    setLogoutDialog({ open: true })
  }
  const handleSetAuth = (data) => {
    localStorage.setItem("authToken", `Bearer ${data.authToken}`)
    localStorage.setItem("data", JSON.stringify(data.businessProfileData))
    setAuth({ authToken: `Bearer ${data.authToken}`, data: data.businessProfileData, isLoginEntry: data.isLoginEntry })
  }

  const handleProfileUpdate = (data) => {
    localStorage.setItem("data", JSON.stringify(data))
    setAuth({ ...auth, data: data })
  }

  const handleCancel = () => {
    setLogoutDialog({ open: false })
  }

  const handleLogout = async () => {
    setAuth()
    // Deleting Device Token from Firebase Push Notification while LogOut
    await handleLogoutNotification()
    localStorage.clear()
    localStorage.setItem("language", language)
    // handleCancel()
    // window.location.href = "/login"
  }

  const isLoading = (value) => {
    setLoading(value)
  }

  return (
    <>
      <AuthContext.Provider
        value={{
          auth: auth,
          isAuth: isAuth,
          handleSetAuth: handleSetAuth,
          openLogoutDialog: openLogoutDialog,
          loading: loading,
          isLoading: isLoading,
          handleLogout: handleLogout,
          lang: language == "English" ? en_lang : es_lang,
          changeLanguage: changeLanguage,
          makeModel: makeModel,
          setMakeModel: setMakeModel,
          handleProfileUpdate: handleProfileUpdate,
        }}>
        {/* {loading && <LinearLoader />} */}
        {props.children}
      </AuthContext.Provider>
    </>
  )
}

export default AuthContextProvider
