import React, { useState, useEffect, useContext } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { AuthContext } from "../../libs/contexts/AuthContext"
import { SnackbarContext } from "../../libs/contexts/SnackbarContext"
import { handleApiError } from "../../libs/apis/handleApiError"
import api from "../../libs/apis/api"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import bulkImg from "../../assets/images/bulk-images.png"
import Header from "../../components/Header"
import Switch from "../../components/FormFields/Switch"
import Textfield from "../../components/FormFields/Textfield"
import Dialog from "../../components/Dialog"

const pwdValidationSchema = Yup.object().shape({
  password: Yup.string().min(5, "Minimum 5 characters required!!").max(21, "Too Long!").required("Password is Required"),
})

const Settings = () => {
  let navigate = useNavigate()
  let params = useParams()
  const { auth, handleSetAuth, handleLogout, changeLanguage, lang } = useContext(AuthContext)
  const { openSnackbar } = useContext(SnackbarContext)
  const [tab, setTab] = useState(0)
  const [langDialog, setLangDialog] = useState({ open: false, language: localStorage.getItem("language") })
  const [legalDialog, setLegalDialog] = useState({ open: false })
  const [pwdDialog, setPwdDialog] = useState({ open: false })
  const [logoutDialog, setLogoutDialog] = useState({ open: false, message: "", handleConfirm: handleLogout })
  const [subscriptionCheck, setSubscriptionCheck] = useState(false)

  const pwdForm = useForm({
    resolver: yupResolver(pwdValidationSchema),
    defaultValues: {
      password: "",
    },
  })

  const handleLanguageSubmit = async (values) => {
    try {
      if (localStorage.getItem("language") != langDialog.language) {
        changeLanguage()
      }
      const { data } = await api.post(`v1/auth/signup/language-preference`, { language: langDialog.language == "English" ? "en" : "es" })
      setLangDialog({ ...langDialog, open: false })
      openSnackbar({
        open: true,
        color: "success",
        message: "Successfully Language Changed",
      })
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const handleLogoutSubmit = async (values) => {
    try {
      const { data } = await api.get(`v1/auth/signup/logout`)
      // openSnackbar({ message: 'Account Successfully Deleted', color: 'success' })
      handleLogout()
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const handleDeleteAccount = async (values) => {
    try {
      const { data } = await api.post(`v1/auth/signup/deleteUser`, { ...values })
      setPwdDialog((prev) => ({ ...prev, open: false }))
      pwdForm.reset()
      openSnackbar({
        message: "Account Successfully Deleted",
        color: "success",
      })
      handleLogout()
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const fetchCheckApi = async (values) => {
    try {
      const { data } = await api.get(`v1/subscription/check`)
      setSubscriptionCheck(data)
      // if (data.data) navigate("/settings/subscription")
      // else handleUpgrade()
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  const handleUpgrade = async (values) => {
    try {
      const { data } = await api.get(`v1/subscription/checkout`)
      window.location.href = data.redirectUrl
    } catch (error) {
      handleApiError(error, openSnackbar)
    }
  }

  useEffect(() => {
    fetchCheckApi()
  }, [])

  return (
    <>
      <Header title={lang.settings} />
      <Dialog dialog={logoutDialog} />
      <div class="wrapper">
        <div class="row">
          <div class="col-md-8 col-lg-8">
            {auth?.data?.profiletype != "business" && (
              <>
                <div className="h4 text-color-ly ms-3 mt-4 mb-3">{lang.live_feed_settings}</div>
                <div class="list-group">
                  <div role="button" onClick={() => navigate("fine-tune")} class="list-group-item list-group-item-action">
                    <h5 class="m-2">{lang.fine_tune}</h5>
                  </div>
                  <div role="button" onClick={() => navigate("keywords")} class="list-group-item list-group-item-action">
                    <h5 class="m-2">{lang.keywords}</h5>
                  </div>
                  <div role="button" onClick={() => navigate("distance")}
                       className="list-group-item list-group-item-action">
                    <h5 className="m-2">{lang.distance}</h5>
                  </div>
                  {auth?.data?.userType != "sub-user" && (
                    <>
                      <div role="button" onClick={() => navigate("manage-users")} class="list-group-item list-group-item-action">
                        <h5 class="m-2">{lang.manage_users}</h5>
                      </div>
                      <div role="button" onClick={() => navigate("business-hours")} class="list-group-item list-group-item-action">
                        <h5 class="m-2">{lang.business_hours}</h5>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
            <div className="h4 text-color-ly ms-3 mt-4 mb-3">{lang.account_settings}</div>
            <div class="list-group">
              <div role="button" onClick={() => navigate("profile")} class="list-group-item list-group-item-action">
                <h5 class="m-2">{lang.profile}</h5>
              </div>
              <div
                role="button"
                class="list-group-item list-group-item-action cursor-pointer"
                onClick={() => {
                    setLangDialog({ ...langDialog, open: true })
                }}>
                {/* <h5 class='m-2'></h5> */}
                <div class="d-flex justify-content-between h5 m-2">
                  <div>
                    {lang.languageTitle} - {lang.language}{" "}
                  </div>
                  <div class="float-right">
                    {/* <Switch
                      checked={
                        localStorage.getItem('language') == 'English'
                          ? true
                          : false
                      }
                      onChange={changeLanguage}
                    /> */}
                  </div>
                </div>
              </div>{" "}
              {auth?.data?.profiletype != "business" && (
                <div
                  role="button"
                  onClick={() => {
                    if (subscriptionCheck.data) navigate("/settings/subscription")
                    else handleUpgrade()
                  }}
                  class="list-group-item list-group-item-action">
                  <h5 class="m-2">{lang.subscription}</h5>
                </div>
              )}
            </div>
            <div className="h4 text-color-ly ms-3 mt-4 mb-3">{lang.support}</div>
            <div class="list-group">
              {auth?.data?.profiletype != "business" ? (
                subscriptionCheck?.data && (
                  <div role="button" onClick={() => navigate("reports")} class="list-group-item list-group-item-action">
                    <h5 class="m-2">{lang.reports}</h5>
                  </div>
                )
              ) : (
                <div role="button" onClick={() => window.open("https://liveyards.com/faq", "_blank")} class="list-group-item list-group-item-action">
                  <h5 class="m-2">FAQ</h5>
                </div>
              )}
              <div role="button" onClick={() => setLegalDialog({ open: true })} class="list-group-item list-group-item-action">
                <h5 class="m-2">{lang.legal}</h5>
              </div>
              <div role="button" onClick={() => navigate("feedback")} class="list-group-item list-group-item-action">
                <h5 class="m-2">{lang.feedback}</h5>
              </div>
              <div role="button" onClick={() => navigate("about-us")} class="list-group-item list-group-item-action">
                <h5 class="m-2">{lang.about_us}</h5>
              </div>
              {/* <div role="button" onClick={() => navigate("subscription")} class="list-group-item list-group-item-action">
                <h5 class="m-2">Subscription</h5>
              </div> */}
              <div role="button" onClick={() => navigate("blocked-user")} class="list-group-item list-group-item-action">
                <h5 class="m-2">{lang.blocked_user}</h5>
              </div>
            </div>
            <div class="list-group mt-4">
              <div
                role="button"
                class="list-group-item list-group-item-action text-color-ly font-weight-bold"
                onClick={() =>
                  setLogoutDialog({
                    open: lang.logout,
                    message: <b class="mx-5">{lang.logout_message}</b>,
                    handleConfirm: handleLogoutSubmit,
                    handleCancel: () => setLogoutDialog({ ...logoutDialog, open: false }),
                  })
                }>
                <h5 class="m-2">
                  <i class="fas fa-power-off me-3"></i> {lang.logout}
                </h5>
              </div>
            </div>
            <div class="list-group mt-4">
              <div
                role="button"
                class="list-group-item list-group-item-action text-color-ly font-weight-bold"
                onClick={() => setPwdDialog({ open: true })}>
                <h5 class="m-2">{lang.delete_account}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Language Modal --> */}
      {langDialog.open && (
        <div
          class="modal"
          // id='myModal'
          // tabindex='-1'
          role="dialog"
          // aria-labelledby='myModalTitle'
          // aria-hidden='true'
          style={{ display: "block" }}>
          <div
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              backgroundColor: "#000",
              opacity: "0.5",
            }}></div>
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title text-dark" id="exampleModalLongTitle">
                  {lang.languageTitle}
                </h4>
                <button
                  type="button"
                  class="close"
                  // data-dismiss='modal'
                  // aria-label='Close'
                  style={{ border: "0" }}
                  onClick={() => setLangDialog({ ...langDialog, open: false })}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                {/* <h5>Mute message Notification for</h5> */}
                <h5>
                  {[
                    { label: "English", value: "English" },
                    { label: "Español", value: "Español" },
                  ].map((item) => (
                    <>
                      <div className="text-black mb-2 mx-3">
                        <input
                          type="radio"
                          name="noti"
                          value={item.value}
                          checked={langDialog.language == item.value}
                          onChange={(e) =>
                            setLangDialog({
                              ...langDialog,
                              language: e.target.value,
                            })
                          }
                        />{" "}
                        <span className="font-weight-bold mx-2"> {item.label}</span>
                      </div>
                    </>
                  ))}
                </h5>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  // data-dismiss='modal'
                  onClick={() => setLangDialog({ ...langDialog, open: false })}>
                  {lang.cancel}
                </button>
                <button type="button" class="btn btn-primary" onClick={handleLanguageSubmit}>
                  {lang.save}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* <!-- Legal Modal --> */}
      {legalDialog.open && (
        <div class="modal modal-lg" role="dialog" style={{ display: "block" }}>
          <div
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              backgroundColor: "#000",
              opacity: "0.5",
            }}></div>
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title text-dark" id="exampleModalLongTitle">
                  {lang.legal}
                </h4>
                <button
                  type="button"
                  class="close"
                  // data-dismiss='modal'
                  // aria-label='Close'
                  style={{ border: "0" }}
                  onClick={() => setLegalDialog({ ...langDialog, open: false })}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="list-group mx-4 my-4">
                  <div
                    role="button"
                    class="list-group-item list-group-item-action"
                    onClick={() => window.open("https://liveyards.com/terms-conditions", "_blank")}>
                    <h5 class="m-2">{lang.t_c}</h5>
                  </div>
                  <div
                    role="button"
                    class="list-group-item list-group-item-action"
                    onClick={() => window.open("https://liveyards.com/privacy-policy", "_blank")}>
                    <h5 class="m-2">{lang.privacy_policy}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* <!-- Delete Account Modal --> */}
      {pwdDialog.open && (
        <div class="modal" role="dialog" style={{ display: "block" }}>
          <div
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              backgroundColor: "#000",
              opacity: "0.5",
            }}></div>
          <div class="modal-dialog modal-md modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title text-dark" id="exampleModalLongTitle">
                  {lang.delete_account}
                </h4>
                <button type="button" class="close" style={{ border: "0" }} onClick={() => setPwdDialog({ ...pwdDialog, open: false })}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form onSubmit={pwdForm.handleSubmit(handleDeleteAccount)}>
                <div class="modal-body">
                  <div className="text-black fs-6 mb-2">Enter your password to delete the account</div>
                  <div class="form-m-b-20">
                    <Textfield
                      type="text"
                      class="form-control"
                      placeholder="Enter Password"
                      name="password"
                      register={pwdForm.register}
                      errors={pwdForm.formState.errors}
                    />
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    // data-dismiss='modal'
                    onClick={() => setPwdDialog((prev) => ({ ...prev, open: false }))}>
                    {lang.cancel}
                  </button>
                  <button
                    type="submit"
                    class="btn btn-01 my-2 py-2"
                    // onClick={handleLanguageSubmit}
                    disabled={pwdForm.formState.isSubmitting}>
                    {lang.delete}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Settings
